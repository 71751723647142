import "./App.css";
import "./Assests/Fonts/icomoon/style.css";


import FrontPage from "./components/FrontPage/FrontPage";
import Mind from "./components/Mind/Mind";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Chat from "./components/Chat/Chat.js";
import Persona from "./components/Persona/Persona";
import EditFacsimile from "./components/EditFascimile/EditFacsimile";
import { ChakraProvider } from "@chakra-ui/react";
import Moments from "./components/Moments/Moments";
import ClientProfile from "./components/ClientProfile/ClientProfile";
import PrivateRoute from "./routes/PrivateRoute";
import UpdateMoment from "./components/Moments/UpdateMoment";
import { ChatContext } from "./context/ChatContext";
import { MomentsContext } from "./context/MomentsContext";
import Login from "./components/SignIn/Login";
import Register from "./components/signup/Register";
import AnalizeMoment from "./components/analyzemoment/AnalizeMoment";
import ForgotPassword from "./components/forgotpassword/ForgotPassword";
import ResetPassword from "./components/resetpassword/ResetPassword";
import ApproveUser from "./components/approve/ApproveUser";
import GeneralSettings from "./components/adminDashboard/general/GeneralSettings";
import Customization from "./components/adminDashboard/customization/Customization";
import UsersTable from "./components/adminDashboard/users/UsersTable";
import Security from "./components/adminDashboard/security/Security";
import UsageStatistics from "./components/adminDashboard/usageStats/UsageStatistics";
import Billing from "./components/adminDashboard/billing/Billing";
import JoinEmissaryComp from "./components/joinEmissary/JoinEmissaryComp";
import UserProfile from "./components/UserProfile/UserProfile";
import { UserContext } from "./context/UserContext";
import EditPersona from "./components/Persona/EditPersona";
import PersonaTeam from "./components/Persona/PersonaTeam";

function App() {
  return (
    <div className="App">
      <ChatContext>
        <MomentsContext>
          <UserContext>
          <Router>
            <Routes>
              <Route exact path="/" element={<Login />}></Route>
              <Route exact path="/signup" element={<Register />}></Route>
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPassword />}
              ></Route>
              <Route
                exact
                path="/approve-user"
                element={<ApproveUser />}
              ></Route>
                <Route
                exact
                path="/join-emissary"
                element={<JoinEmissaryComp />}
              ></Route>
              <Route
                exact
                path="/reset-password/:token"
                element={<ResetPassword />}
              ></Route>
              <Route
                exact
                path="/frontpage"
                element={
                  <PrivateRoute>
                    <FrontPage />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/mind"
                element={
                  <PrivateRoute>
                    <Mind />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/editFacsimile"
                element={
                  <PrivateRoute>
                    <EditFacsimile />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/chat"
                element={
                  <PrivateRoute>
                    <Chat />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/chat/:id"
                element={
                  <PrivateRoute>
                    <Chat />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/moments"
                element={
                  <PrivateRoute>
                    <Moments />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/updatemoment"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <UpdateMoment />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/persona"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <Persona />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/persona/:id"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <Persona />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/mypersonas"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <Persona />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
               <Route
                exact
                path="/edit-persona"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <EditPersona />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
               <Route
                exact
                path="/team"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <PersonaTeam />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/admins"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <Persona />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/client-profile"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <ClientProfile />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/user-profile"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <UserProfile />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/analyze-moment"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <AnalizeMoment />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
              {/* admin routes */}
              <Route
                exact
                path="/admin/general"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <UsageStatistics />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/admin/customization"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <Customization />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/admin/users"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <UsersTable />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/admin/security"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <Security />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                exact
                path="/admin/billing"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <Billing />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route>
              {/* <Route
                exact
                path="/admin/usage-stats"
                element={
                  <PrivateRoute>
                    <ChakraProvider>
                      <UsageStatistics />
                    </ChakraProvider>
                  </PrivateRoute>
                }
              ></Route> */}
              
            </Routes>
          </Router>
          </UserContext>
        </MomentsContext>
      </ChatContext>
    </div>
  );
}

export default App;
