import React, { useEffect, useState } from "react";
import NavBar from "../../NavBar/NavBar";
import SideNav from "../../sideNav/sideNav";
import {
  Box,
  ChakraProvider,
  Flex,
  Img,
  Spacer,
  Square,
  Stack,
  Text,
} from "@chakra-ui/react";
import arrowrightcircle from "../../../Images/arrow-right-circle.svg";
import { Link, useNavigate } from "react-router-dom";
import "./Usage.css";
import { getUsageStatsService } from "../../../Service/service";
import LoadingSpinner from "../../../Assests/loaderspinner.gif";
import AdminNav from "../../sideNav/AdminNav";
import { RotatingLines } from "react-loader-spinner";
import Feedback from "../../feedback/Feedback";

const UsageStatistics = () => {
    const nav = useNavigate();
    const [usageStats, setUsageStats] = useState()
    const [loading, setloading] = useState(false)
  useEffect(() => {
    const getData = async () => {
      try {
        setloading(true)
        const res = await getUsageStatsService();
        if(res.success){
          setUsageStats(res.data)
          setloading(false)
        }
      } catch (error) {
        setloading(false)

      console.log("🚀 ~ file: UsageStatistics.js:30 ~ getData ~ error:", error)
      }
    };
    getData()
  }, []);

  return (
    <ChakraProvider>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
              <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width='50'
              visible={true}
            />
          </div>
        </div>
      )}
      <Feedback/>
      <NavBar />
      <div className={"persona-container-light"}>
        <div className="mainContainer">
          <div className="mainContainer-left">
            {" "}
            <AdminNav />
          </div>
          <div className="usageContainer">
          <div className="d-flex justify-content-between">
            <Text fontWeight={"bold"} fontSize={"1.4rem"}>
              Usage Stats
            </Text>
            <div class="dropdown hide-desktop">
              <button
                className="togglebtn1 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="icon-more-vertical"></i>
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  {" "}
                  <Link
                    className="w-100 dropdown-item"
                    to={'/admin/general'}
                  >
                    General
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to={"/admin/customization"}>
                  Customization
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to="/admin/users">
                    Users
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to="/admin/security">
                    Security
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to="/admin/billing">
                    Billing
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to="/admin/usage-stats">
                    Usage Stats
                  </Link>
                </li>
              </ul>
            </div>
            </div>
            <Stack w={{ base: "100%", lg: "90%" }}>
              <Stack
                spacing={6}
                w="full"
                direction={{ base: "column", md: "row" }}
                color="white"
              >
                <Box
                  w={{ base: "100%", md: "66%" }}
                  h="185px"
                  border={"1.5px solid #111"}
                  bg="#111"
                  p="5"
                >
                  <Stack h="full" justifyContent={"space-between"}>
                    <Text fontSize={"2.5rem"}>{usageStats?.users}</Text>
                    <Stack alignItems={"center"} direction="row">
                      <Text fontWeight={"light"} mb="0" fontSize={"sm"}>
                        Total users
                      </Text>
                      <Spacer />
                      <Img
                        onClick={() => nav("/admin/users",{state:'users'})}
                        cursor={"pointer"}
                        w={6}
                        h={6}
                        src={arrowrightcircle}
                      />
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  w={{ base: "100%", md: "33%" }}
                  p="5"
                  //   ms={6}
                  color="black"
                  border={"1.5px solid #000"}
                  size="150px"
                >
                  <Stack h="full" justifyContent={"space-between"}>
                    <Text fontSize={"2.5rem"}>{usageStats?.moments}</Text>
                    <Stack alignItems={"center"} direction="row">
                      <Text fontWeight={"light"} mb="0" fontSize={"sm"}>
                        Total Moments
                      </Text>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
              <Stack
                spacing={6}
                w="full"
                direction={{ base: "column", md: "row" }}
                paddingTop="6"
                color="white"
              >
                <Box
                  p="5"
                  w={{ base: "100%", md: "33%" }}
                  h="185px"
                  color="black"
                  border={"1.5px solid #000"}
                  size="150px"
                >
                  <Stack h="full" justifyContent={"space-between"}>
                    <Text fontSize={"2.5rem"}>{usageStats?.personas}</Text>
                    <Stack alignItems={"center"} direction="row">
                      <Text fontWeight={"light"} mb="0" fontSize={"sm"}>
                        Total Personas
                      </Text>
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  p="5"
                  w={{ base: "100%", md: "33%" }}
                  color="black"
                  border={"1.5px solid #000"}
                  ms="6"
                  size="150px"
                >
                  <Stack h="full" justifyContent={"space-between"}>
                    <Text fontSize={"2.5rem"}>{usageStats?.queries}</Text>
                    <Stack alignItems={"center"} direction="row">
                      <Text fontWeight={"light"} mb="0" fontSize={"sm"}>
                        Total Queries
                      </Text>
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  p="5"
                  w={{ base: "100%", md: "33%" }}
                  h="185px"
                  border={"1.5px solid #111"}
                  bg="#111"
                  ms="6"
                >
                  <Stack h="full" justifyContent={"space-between"}>
                    <Text fontSize={"2.5rem"}>{usageStats?.admins}</Text>
                    <Stack alignItems={"center"} direction="row">
                      <Text fontWeight={"light"} mb="0" fontSize={"sm"}>
                        Total admins
                      </Text>
                      <Spacer />
                      <Img
                        onClick={() => nav("/admin/users",{state:'admins'})}
                        cursor={"pointer"}
                        w={6}
                        h={6}
                        src={arrowrightcircle}
                      />
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </div>
        </div>{" "}
      </div>
    </ChakraProvider>
  );
};

export default UsageStatistics;
