import React, { useState, useEffect, useRef } from "react";
import "./Moments.css";
import $ from "jquery";
import NavBar from "../NavBar/NavBar";
import documents from "../../Images/file.svg";
import citation from "../../Images/citation.svg";
import voice from "../../Images/voice-prefernce.svg";
import human_note from "../../Images/human-note.svg";
import external_link from "../../Images/external-link.svg";
import upload from "../../Images/upload.svg";
import Brook from "../../Images/brook.png";
import SideNav from "../sideNav/sideNav";
import UserHeader from "../UserProfile/userHeader";
import cross from "../../Images/x.svg";
import Footer from "../Footer/Footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BaseURL, PY_API, sasToken } from "../../enviornment";
import Swal from "sweetalert2";
import { Button, Modal, Spinner } from "react-bootstrap";
import { format } from "date-fns";
import { useContext } from "react";
import PersonaMomentContext from "../../context/MomentsContext";
import {
  ChakraProvider,
  Checkbox,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button as ChakraButton,
} from "@chakra-ui/react";
import validator from "validator";
import LoadingSpinner from "../../Assests/loaderspinner.gif";
import Unknown from "../../Images/Unknown.jpg";
import { CiFileOn } from "react-icons/ci";
import { GoLinkExternal } from "react-icons/go";
import { RotatingLines } from "react-loader-spinner";
import Feedback from "../feedback/Feedback";
import moment from "moment";
const UpdateMoment = () => {
  const { isDarkMode } = useContext(PersonaMomentContext);
  const location = useLocation();
  const buttonRef = useRef(null);
  const documentModalCloseRef = useRef(null);
  const [docId, setdocId] = useState();
  const [momentData, setMomentData] = useState();
  console.log(
    "🚀 ~ file: UpdateMoment.js:50 ~ UpdateMoment ~ momentData:",
    momentData
  );
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [date, setDate] = useState("");
  const [file, setFile] = useState(null);
  const [link, setLink] = useState("");
  const [linkObj, setLinkObj] = useState([]);
  const [objects, setObjects] = useState([]);
  const [docObj, setDocObj] = useState([]);
  const [momentName, setMomentName] = useState();
  const [docUrl, setDocUrl] = useState("");
  const [targetDocIndex, setTargetDocIndex] = useState();
  const [targetLinkIndex, setTargetLinkIndex] = useState();
  const [showDocTable, setShowDocTable] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateNameBtnDisable, setIsUpdateNameBtnDisable] = useState(true);
  const [showModal2, setShowModal2] = useState(false);
  const modalRef = useRef(null);
  const [files, setFiles] = useState([]);
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  // const personas = JSON.parse(localStorage.getItem("persona_list"));
  const handleCloseModal = () => {
    if (modalRef.current) {
      window.jQuery(modalRef.current).modal("hide");
    }
  };
  useEffect(() => {
    if (location.state) {
      // console.log(location.state.momentDetail);
      setMomentData(location.state.momentDetail);
      setMomentName(location.state.momentDetail.momentName);
      setObjects(location.state.momentDetail.documents);
      setLinkObj(location.state.momentDetail.urls);
    }
  }, [location.state]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal2 = () => {
    setShowModal2(true);
  };

  const closeModal2 = () => {
    setShowModal2(false);
  };
  const hanldeMomentName = (event) => {
    setMomentName(event.target.value);
    setIsUpdateNameBtnDisable(false);
  };

  const handleLinkChange = (e) => {
    if (e.target.name === "link") {
      setLink(e.target.value);
    }
  };

  const handleInputChange = (e) => {
    if (e.target.name === "documentName") {
      setDocumentName(e.target.value);
    } else if (e.target.name === "date") {
      setDate(e.target.value);
    } else if (e.target.name === "file") {
      const selectedFile = e.target.files[0];

      const selectedFiles = Array.from(e.target.files);
      if (objects?.length > 49) {
        Swal.fire(
          "Document limit exceeded!",
          "You cannot upload more than 50 documents inside a single moment.",
          "error"
        );
        return;
      }

      // Check if each file's format is valid
      for (const selectedFile of selectedFiles) {
        if (!isFileFormatValid(selectedFile)) {
          Swal.fire(
            "Invalid file format!",
            "Please upload text, json, pdf, or word files only.",
            "error"
          );
          return;
        }
      }

      // Update the files state with the selected files
      setFiles([...files, ...selectedFiles]);

      // setFile(selectedFile);
      let duplicateFileFound = false;
      if (objects.length !== 0) {
        duplicateFileFound = objects.some((oneFile) => {
          if (selectedFile.name === oneFile.documentName) {
            Swal.fire({
              icon: "error",
              title: "File already uploaded",
            });
            return true;
          }
          return false;
        });
      }
      if (!duplicateFileFound) {
        setFile(selectedFile);
        // uploadFile(selectedFile);
      }
    }
  };
  const isFileFormatValid = (file) => {
    const validFormats = [".json", ".txt", ".pdf", ".doc", ".docx"];
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    return validFormats.includes(fileExtension.toLowerCase());
  };
  const isFormValid = () => {
    return file !== null;
  };
  const handleRemove = async (index) => {
    const updatedObjects = [...objects];
    updatedObjects.splice(index, 1);
    setObjects(updatedObjects);
  };
  const handleLinkRemove = (index) => {
    const updatedObjects = [...linkObj];
    updatedObjects.splice(index, 1);
    setLinkObj(updatedObjects);
  };
  const deleteDocument = async () => {
    setIsLoading(true);
    try {
      const myHeaders = new Headers();
      const token = localStorage.getItem("auth_token");
      const parsedToken = await JSON.parse(token);
      myHeaders.append("auth_token", parsedToken?.auth_token);
      myHeaders.append("Content-Type", "application/json");
      const response = await fetch(
        `${BaseURL}/api/moments/deleteDocument/${docId}`,
        {
          method: "DELETE",
          headers: myHeaders,
        }
      );
      const json = await response.json();
      if (json.success) {
        handleRemove(targetDocIndex);
        setIsLoading(false);
        closeModal();
        Swal.fire({
          icon: "success",
          title: json.message,
        });
      } else {
        setIsLoading(false);
        closeModal();
        Swal.fire({
          icon: "error",
          title: json.message,
        });
      }
    } catch (error) {
      // Handle the error
      setIsLoading(false);
    }
  };

  const deleteLink = async () => {
    setIsLoading(true);
    try {
      const myHeaders = new Headers();
      const token = localStorage.getItem("auth_token");
      const parsedToken = await JSON.parse(token);
      let body = JSON.stringify({
        momentId: momentData?._id,
        url: docUrl,
      });
      myHeaders.append("auth_token", parsedToken?.auth_token);
      myHeaders.append("Content-Type", "application/json");
      const response = await fetch(`${BaseURL}/api/moments/deleteUrl`, {
        method: "DELETE",
        headers: myHeaders,
        body,
      });
      const json = await response.json();
      if (json.success) {
        handleLinkRemove(targetLinkIndex);
        setIsLoading(false);
        closeModal2();
        Swal.fire({
          icon: "success",
          title: json.message,
        });
      } else {
        setIsLoading(false);
        closeModal2();
        Swal.fire({
          icon: "error",
          title: json.message,
        });
      }
    } catch (error) {
      // Handle the error
      setIsLoading(false);
    }
  };
  const createDocument = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const myHeaders = new Headers();
      const token = localStorage.getItem("auth_token");
      const currentDate = new Date();
      const formattedDate = format(currentDate, "dd/MM/yyyy");
      const parsedToken = await JSON.parse(token);
      myHeaders.append("auth_token", parsedToken?.auth_token);
      // myHeaders.append("Content-Type", "application/json");
      const formData = new FormData();
      formData.append("momentId", momentData?._id);
      formData.append("date", formattedDate);
      formData.append("documentName", "Document_Name");
      formData.append("file", file);
      const response = await fetch(`${BaseURL}/api/moments/createDocument`, {
        method: "POST",
        headers: myHeaders,
        body: formData,
      });
      const json = await response.json();

      if (json.success) {
        Swal.fire({
          icon: "success",
          title: json.message,
        });
        setObjects(json.moment.documents);
        setDocObj(json.moment.documents);
        setDocumentName("");
        setDate("");
        setFile(null);
        document.getElementById("file-upload").value = null;
        setIsLoading(false);
        closeModal();
        documentModalCloseRef.current.click();
        // handleCloseModal();
      } else {
        setIsLoading(false);
        setDocumentName("");
        setDate("");
        setFile(null);
        document.getElementById("file-upload").value = null;
        Swal.fire({
          icon: "error",
          title: json.message,
        });
      }
    } catch (error) {
      // Handle the error
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: error,
      });
    }
  };
  const createLink = async (e) => {
    e.preventDefault();

    const check = validator.isURL(link);

    if (!check) {
      Swal.fire({
        icon: "error",
        title: "Invalid Url",
      });
    } else {
      let duplicateFound = false;

      if (linkObj.length !== 0) {
        duplicateFound = linkObj.some((oneLink) => {
          if (link === oneLink.url) {
            Swal.fire({
              icon: "error",
              title: "Url already uploaded",
            });
            return true;
          }
          return false;
        });
      }

      if (!duplicateFound) {
        setIsLoading(true);
        try {
          const myHeaders = new Headers();
          const token = localStorage.getItem("auth_token");
          const parsedToken = await JSON.parse(token);

          myHeaders.append("auth_token", parsedToken?.auth_token);
          myHeaders.append("Content-Type", "application/json");

          const response = await fetch(`${BaseURL}/api/moments/createUrl`, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
              momentId: momentData?._id,
              url:
                link.startsWith("http://") || link.startsWith("https://")
                  ? link
                  : "http://" + link,
            }),
          });
          const json = await response.json();

          if (json.success) {
            Swal.fire({
              icon: "success",
              title: json.message,
            });

            setLinkObj(json.data.urls);
            // Reset form fields
            setLink("");
            buttonRef.current.click();
            setIsLoading(false);
            closeModal();
          } else {
            setIsLoading(false);
            setDocumentName("");
            setDate("");
            setFile(null);
            document.getElementById("file-upload").value = null;
            Swal.fire({
              icon: "error",
              title: json.message,
            });
          }
        } catch (error) {
          // Handle the error
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: error,
          });
        }
      }
    }
  };
  const updateName = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const myHeaders = new Headers();
      const token = localStorage.getItem("auth_token");
      const parsedToken = await JSON.parse(token);
      const name = momentName.trim();
      myHeaders.append("auth_token", parsedToken?.auth_token);
      myHeaders.append("Content-Type", "application/json");

      const response = await fetch(`${BaseURL}/api/moments/updateMomentName`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          momentId: momentData?._id,
          momentName: name,
        }),
      });
      const json = await response.json();
      console.log(json);

      if (json.success) {
        Swal.fire({
          icon: "success",
          title: json.message,
        });
        setMomentData(json.data);
        // Reset form fields
        // setMomentName("");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: json.message,
        });
      }
    } catch (error) {
      // Handle the error
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: error,
      });
    }
  };
  function extractDocumentName(docUrl) {
    // Split the URL by '/'
    const parts = docUrl.split("/");

    // Get the last part (the filename with extension)
    const fileNameWithExtension = parts[parts.length - 1];

    // Remove query string (if any) and then split by '.'
    const fileNameParts = fileNameWithExtension.split("?")[0].split(".");

    // Replace special characters in the filename
    const sanitizedFileName = fileNameParts[0]
      .replace(/%20/g, " ") // Replace %20 with space
      .replace(/%40/g, "@") // Replace %40 with @
      .replace(/%26/g, "&"); // Replace %26 with &

    return sanitizedFileName;
  }

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <Feedback />

      <NavBar />
      <div
        className={
          isDarkMode ? "persona-container-dark" : "persona-container-light"
        }
      >
        <div className="userHeader hide-desktop">
          <SideNav />
        </div>
        <div className="mainContainer">
          <div className="mainContainer-left show-on-desktop">
            {" "}
            <SideNav />
          </div>
          <div className="mainContainer-right">
            <div className="momentContainer">
              <h1>Update a moment</h1>

              <div class="momentUploads">
                <div className="momentForm">
                  <div className="form-field">
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={momentName}
                      onChange={hanldeMomentName}
                    />
                  </div>
                  <button
                    className="btn btn-primary create-moment-btn"
                    onClick={updateName}
                    disabled={isUpdateNameBtnDisable}
                  >
                    Update Name
                  </button>
                </div>
                <div className="resourceContainer">
                  <h2>
                    Add resources that are directly related to this moment
                  </h2>
                  <div className="momentResource-container">
                    <div
                      data-bs-toggle="modal"
                      data-bs-target="#momentModal"
                      role="button"
                      onClick={() => setShowDocTable(true)}
                    >
                      <div className="active-resource-div user-input-div">
                        <CiFileOn fontSize={"27px"} />
                        <span>Documents</span>
                      </div>
                    </div>

                    <div>
                      <div className="disable-resource-div user-input-div">
                        <img src={citation} alt=""></img>
                        <span>Citation</span>
                      </div>
                    </div>
                    <div>
                      <div className="disable-resource-div user-input-div">
                        <img src={voice} alt=""></img>
                        <span>Voice Preference</span>
                      </div>
                    </div>
                    <div>
                      <div className="disable-resource-div user-input-div">
                        <img src={human_note} alt=""></img>
                        <span>Human Note</span>
                      </div>
                    </div>
                    <div>
                      <div
                        className="active-resource-div user-input-div"
                        data-bs-toggle="modal"
                        data-bs-target="#momentLinkModal"
                        role="button"
                      >
                        <GoLinkExternal fontSize={"27px"} />
                        <span>External Link</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {objects.length > 0 && showDocTable && (
                <ChakraProvider>
                  <TableContainer
                    maxH={"50vh"}
                    paddingRight={"10px"}
                    overflowY={"auto"}
                    mt={0}
                    mx={0}
                  >
                    <Table variant="simple">
                      <Thead
                        fontWeight={"600"}
                        bgColor={
                          isDarkMode ? "black !important" : "#f8f9fa !important"
                        }
                      >
                        <Tr>
                          <Th
                            color={
                              isDarkMode
                                ? "white !important"
                                : "black !important"
                            }
                          >
                            NAME
                          </Th>
                          <Th
                            color={
                              isDarkMode
                                ? "white !important"
                                : "black !important"
                            }
                          >
                            ADDED BY
                          </Th>
                          <Th
                            color={
                              isDarkMode
                                ? "white !important"
                                : "black !important"
                            }
                          >
                            ADDED ON
                          </Th>
                          <Th minW={"70px"}></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {objects.map((obj, index) => (
                          <Tr>
                            <Td maxW={"fit-content"}>
                              <div className="align-items-center d-flex gap-3">
                                {/* <span>
                                  <Checkbox colorScheme="green" />
                                </span> */}
                                <span className="moments-name mb-1">
                                  {extractDocumentName(obj.docUrl)}
                                </span>
                              </div>
                            </Td>
                            <Td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    momentData?.momentOwner?.image === null
                                      ? Unknown
                                      : `${BaseURL}/api/uploads/getUser/${
                                          momentData?.momentOwner?._id
                                        }?random=${Math.random()}`
                                  }
                                  style={{
                                    width: "2rem",
                                    height: "2rem",
                                  }}
                                  className="brendan me-2"
                                  alt="person"
                                />
                                <div>{momentData?.momentOwner?.name}</div>
                              </div>
                            </Td>
                            <Td>
                              <span>
                                {moment(
                                  momentData?.momentDate,
                                  "MMMM DD, YYYY"
                                ).format("DD/MM/YYYY")}
                              </span>
                            </Td>
                            <Td>
                              <div
                                role="button"
                                className="d-flex justify-content-end"
                                onClick={() => {
                                  setdocId(obj._id);
                                  setTargetDocIndex(index);
                                  openModal();
                                }}
                              >
                                <img
                                  src={cross}
                                  alt="more-option"
                                  style={{
                                    width: "15px",
                                    height: "auto",
                                  }}
                                />
                              </div>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </ChakraProvider>
              )}

              {linkObj.length > 0 && (
                <ChakraProvider>
                  <TableContainer
                    maxH={"50vh"}
                    paddingRight={"10px"}
                    overflowY={"auto"}
                    mt={8}
                    mx={0}
                  >
                    <Table variant="simple">
                      <Thead
                        fontWeight={"600"}
                        bgColor={
                          isDarkMode ? "black !important" : "#f8f9fa !important"
                        }
                      >
                        <Tr>
                          <Th
                            color={
                              isDarkMode
                                ? "white !important"
                                : "black !important"
                            }
                          >
                            NAME
                          </Th>
                          <Th
                            color={
                              isDarkMode
                                ? "white !important"
                                : "black !important"
                            }
                          >
                            ADDED BY
                          </Th>
                          <Th
                            color={
                              isDarkMode
                                ? "white !important"
                                : "black !important"
                            }
                          >
                            ADDED ON
                          </Th>
                          <Th minW={"70px"}></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {linkObj.map((url, index) => (
                          <Tr>
                            <Td maxW={"fit-content"}>
                              <div className="align-items-center d-flex gap-3">
                                {/* <span>
                                  <Checkbox colorScheme="green" />
                                </span> */}
                                <span className="moments-name mb-1">{url}</span>
                              </div>
                            </Td>
                            <Td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    momentData?.momentOwner?.image === null
                                      ? Unknown
                                      : `${BaseURL}/api/uploads/getUser/${
                                          momentData?.momentOwner?._id
                                        }?random=${Math.random()}`
                                  }
                                  style={{
                                    width: "2rem",
                                    height: "2rem",
                                  }}
                                  className="brendan me-2"
                                  alt="person"
                                />
                                <div>{momentData?.momentOwner?.name}</div>
                              </div>
                            </Td>
                            <Td>
                              <span>
                                {moment(
                                  momentData?.momentDate,
                                  "MMMM DD, YYYY"
                                ).format("DD/MM/YYYY")}
                              </span>
                            </Td>
                            <Td>
                              <div
                                role="button"
                                className="d-flex justify-content-end"
                                onClick={() => {
                                  setDocUrl(url);
                                  setTargetLinkIndex(index);
                                  openModal2();
                                }}
                              >
                                <img
                                  src={cross}
                                  alt="more-option"
                                  style={{
                                    width: "15px",
                                    height: "auto",
                                  }}
                                />
                              </div>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </ChakraProvider>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
      {/* <------Moment modal-----> */}
      <div
        className="modal fade"
        id="momentModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="momentModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="momentModalLabel">
                Upload a Document
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={documentModalCloseRef}
                onClick={() => {
                  setFile(null);
                }}
              ></button>
            </div>
            <form onSubmit={createDocument}>
              <div ref={modalRef} className="modal-body">
                {/* <div className="form-field">
                  <label className="form-label">Document Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="documentName"
                    value={documentName}
                    onChange={handleInputChange}
                  />
                </div> */}
                {/* <div className="body-div">
                  <span>Initial Moment's Date</span>
                  <input
                    type="date"
                    className="mt-2 mb-2 px-2"
                    name="date"
                    value={date}
                    onChange={handleInputChange}
                  />
                </div> */}
                <div className="form-field">
                  <label>Upload Document</label>
                  <div className="modal-upload-div mt-2 mb-2">
                    <label
                      htmlFor="file-upload"
                      className="w-100 h-100 d-flex cursor-pointer flex-column justify-content-center align-items-center"
                    >
                      <img src={upload} alt="" />
                      <input
                        id="file-upload"
                        type="file"
                        // multiple
                        name="file"
                        accept=".json,.txt,.pdf,.doc,.docx"
                        onChange={handleInputChange}
                      />
                      <div className="modal-bottom-text">
                        {/* Drop Files here or{" "} */}
                        <u>{file ? file.name : "Upload File"}</u>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="d-flex justify-content-center py-3">
                  <ChakraProvider>
                    <ChakraButton
                      size="lg"
                      type="submit"
                      className={
                        !isFormValid()
                          ? "create-moment-btn w-100 disabled-btn"
                          : "create-moment-btn w-100"
                      }
                      isDisabled={!isFormValid()}
                    >
                      Save Documents
                    </ChakraButton>
                  </ChakraProvider>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="momentLinkModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="momentLinkModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="momentLinkModalLabel">
                Upload an External link
              </h5>
              <button
                type="button"
                ref={buttonRef}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={createLink}>
              <div className="modal-body">
                <div className="form-field">
                  <label>External Link</label>
                  <input
                    type="text"
                    className="form-control"
                    name="link"
                    value={link}
                    onChange={handleLinkChange}
                  />
                </div>
                <div className="d-flex justify-content-center py-3">
                  <ChakraProvider>
                    <ChakraButton
                      size="lg"
                      type="submit"
                      className={
                        link === ""
                          ? "create-moment-btn w-30 disabled-btn"
                          : "create-moment-btn w-30"
                      }
                      isDisabled={link === ""}
                    >
                      Save Links
                    </ChakraButton>
                  </ChakraProvider>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* confirm delete popup */}
      <div>
        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Header style={{ border: "none" }} closeButton>
            {/* <Modal.Title>Are you sure you want to delete?</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <h6 style={{ paddingLeft: "20px", fontSize: "1.3rem" }}>
              Are you sure you want to delete?
            </h6>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button variant="secondary" onClick={closeModal}>
              No
            </Button>
            <Button variant="danger" onClick={deleteDocument}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div>
        <Modal show={showModal2} onHide={closeModal2} centered>
          <Modal.Header style={{ border: "none" }} closeButton>
            {/* <Modal.Title>Are you sure you want to delete?</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <h6 style={{ paddingLeft: "20px", fontSize: "1.3rem" }}>
              Are you sure you want to delete?
            </h6>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button variant="secondary" onClick={closeModal2}>
              No
            </Button>
            <Button variant="danger" onClick={deleteLink}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default UpdateMoment;
