export const sasToken =
  "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-06-29T01:35:11Z&st=2023-05-17T17:35:11Z&spr=https,http&sig=bTnx3MTMQiufnZWGQub2Hrbvyyy387IaPYek%2Fj3ITaA%3D";
// export const BaseURL = "http://172.174.130.237:3000"; //Node live API
export const BaseURL = "https://massivealliance.azure-api.net"; //Node live API
// export const BaseURL = "http://localhost:3000"; //Node local API
// export const BaseURL = "http://20.55.122.108:3000"; // Test Node API
// export const PY_API = "http://4.227.145.215:5000"; //Python API
// export const PY_API = "https://massiveallianceflask.azure-api.net"; //Python API
export const PY_API = "https://api.emissary.massivealliance.com/"; //Python API
// export const PY_API = "http://172.190.83.58:5000"; //Python API

export const SAS_TOKEN =
  "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-12-31T18:34:14Z&st=2023-07-03T10:34:14Z&spr=https,http&sig=J0w9eEMkL8wykyHrj54wcvV%2FWwrszSprQX%2FD1NUl5Lk%3D";
export const CONTAINER_NAME = "files";
export const STORAGE_ACCOUNT_NAME = "massiveallience";
