import React, { useRef, useState } from "react";
import "../Persona/Persona.css";
import uploadPhoto from "../../Images/uploadPhoto.svg";
import LoadingSpinner from "../../Assests/loaderspinner.gif";
import Swal from "sweetalert2";
import { BaseURL } from "../../enviornment";
import {
  Button as ChakraButton,
  ChakraProvider,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import {
  UpdatePersonaProfileService,
} from "../../Service/service";
import { useEffect } from "react";
import { useContext } from "react";
import UserProfileContext from "../../context/UserContext";
import moment from "moment/moment";
import SideNav from "../sideNav/sideNav";
import NavBar from "../NavBar/NavBar";
import PersonaTab from "../ClientProfile/ClientProfileTabs/PersonaTab";
import CompanyDetails from "../ClientProfile/ClientProfileTabs/CompanyDetails";
import PersonaChatContext from "../../context/ChatContext";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
const EditPersona = () => {
  const { getUserDetailsFunction } = useContext(UserProfileContext);
  const { emptyChatArray, updateChatArray } = useContext(PersonaChatContext);
  const nav = useNavigate();
  const [activePersona, setActivePersona] = useState();
  const [profilePic, setProfilePic] = useState(null);
  const [f_Name, setF_Name] = useState("");
  const [l_Name, setL_Name] = useState("");
  const [comapnyName, setCompanyName] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      const activePersonaData = await JSON.parse(
        localStorage.getItem("active_persona_data")
      );
      setActivePersona(activePersonaData);
      setF_Name(activePersonaData?.firstName);
      setL_Name(activePersonaData?.lastName);
      setCompanyName(activePersonaData?.epClientCompany);
    };
    fetchData();
  }, [updateChatArray]);

  const handleInputChange = (e) => {
    if (e.target.name === "profilePic") {
      const selectedFile = e.target.files[0];
      if (selectedFile && !isFileFormatValid(selectedFile)) {
        Swal.fire(
          "Invalid file format!",
          "Please upload png, jpg and jpeg files only",
          "error"
        );
        return;
      }

      setProfilePic(selectedFile);
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        setPreviewImage(null);
      }
    } else if (e.target.name === "f_name") {
      const newValue = e.target.value;
      // Use a regular expression to check for spaces and remove them
      const sanitizedValue = newValue.replace(/\s/g, "");
      setF_Name(sanitizedValue);
    } else if (e.target.name === "l_name") {
      const newValue = e.target.value;
      // Use a regular expression to check for spaces and remove them
      const sanitizedValue = newValue.replace(/\s/g, "");
      setL_Name(sanitizedValue);
    } else if (e.target.name === "comapnyName") {
      setCompanyName(e.target.value);
    }
  };

  const isFileFormatValid = (file) => {
    const validFormats = [".png", ".jpg", ".jpeg", ".svg"];
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    return validFormats.includes(fileExtension.toLowerCase());
  };

  const updatePersona = () => {
    setLoading(true);
    let errors = {};

    if (f_Name === "") {
      errors.firstName = true;
    }
    if (l_Name === "") {
      errors.lastName = true;
    }
    if (comapnyName === "") {
      errors.companyName = true;
    }
    if (Object.keys(errors).length !== 0) {
      setLoading(false);
      setError(errors);
      console.log(errors);
    } else {
      editPersonaProfile();
    }
  };
  const editPersonaProfile = async () => {
    setError({});
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    const formData = new FormData();
    formData.append("firstName", f_Name);
    formData.append("lastName", l_Name);
    formData.append("epClientCompany", comapnyName);
    formData.append("personaId", activePersona?._id);
    formData.append("image", profilePic);

    try {
      const response = await UpdatePersonaProfileService(formData);

      if (response.success) {
        emptyChatArray();
        localStorage.setItem(
          "active_persona_data",
          JSON.stringify(response.data)
        );
        setError({});
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: response.message,
        });
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: response.message,
        });
      }
    } catch (error) {
      setLoading(false);
      // Handle fetch error
      Swal.fire({
        icon: "error",
        title: error,
      });
    }
  };
  async function deletePersonaHandler() {
    const activePersonaData = await JSON.parse(
      localStorage.getItem("active_persona_data")
    );
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    try {
      var requestOptions = {
        method: "DELETE",
        headers: parsedToken,
        redirect: "follow",
      };
      setLoading(true);
      const response = await fetch(
        `${BaseURL}/api/personas/deletePersona/${activePersonaData?._id}`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        setLoading(false);
        nav("/persona");
        Swal.fire({
          icon: "success",
          title: result.message,
        });
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: result.message,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  const parsedToken = JSON.parse(localStorage.getItem("auth_token"));
  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    const parsedToken = JSON.parse(token);
    async function getUserData() {
      setLoading(true);
      try {
        const res = await getUserDetailsFunction(parsedToken?.data?._id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(
          "🚀 ~ file: MyProfile.js:157 ~ getUserData ~ error:",
          error
        );
      }
    }
    getUserData();
  }, []);
  return (
    <ChakraProvider>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <NavBar />
      <div className="persona-container-light ">
        <div className="userHeader hide-desktop">
          <SideNav />
        </div>
        <div className="mainContainer">
          <div className="mainContainer-left show-on-desktop">
            {" "}
            <SideNav />
          </div>
          <div className="mainContainer-right ms-3">
            <div className="d-flex persona-profile-tab justify-content-between align-items-center mb-3">
              <Stack spacing={2}>
                <Heading marginBottom={0} fontSize={"2xl"}>
                  Edit Persona
                </Heading>
                <Text fontWeight={"400"} fontSize={"md"}>
                  Created by {activePersona?.ownerName} on{" "}
                  {moment(activePersona?.personaDate).format("ll")}
                </Text>
              </Stack>

              <ChakraButton
                rounded={"md"}
                border={"2px solid black"}
                _hover={{ bg: "black", color: "white" }}
                variant={"outline"}
                fontSize={"sm"}
                onClick={openModal}
              >
                DELETE
              </ChakraButton>
            </div>
            <Tabs
              orientation="horizontal"
              defaultIndex={0}
              className="tab-names"
            >
              <TabList borderBottom={"none"}>
                <Tab
                  color={"black !important"}
                  fontWeight={"500"}
                  fontSize={"md"}
                  _selected={{ borderBottom: "2px solid black" }}
                >
                  Profile
                </Tab>
                <Tab fontWeight={"500"} color={"black !important"}>
                  Persona Details
                </Tab>
                <Tab fontWeight={"500"} color={"black !important"}>
                  Company Details
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel className="px-0  mx-0">
                  {/* <PersonaTab/> */}
                  <div className="persona-profile-tab d-flex py-3 flex-column ">
                    <div className="d-flex align-items-center mb-2">
                      <div>
                        <label
                          htmlFor="img-upload"
                          className="w-100 h-100 d-flex cursor-pointer"
                        >
                          <input
                            id="img-upload"
                            type="file"
                            name="profilePic"
                            className="fileUpload-Custom"
                            accept=".png,.jpg,.jpeg"
                            // value={profilePic}
                            onChange={handleInputChange}
                          />
                          {previewImage ? (
                            <img
                              src={previewImage}
                              alt="preview"
                              style={{
                                width: "65px",
                                height: "65px",
                                borderRadius: "100%",
                              }}
                            />
                          ) : activePersona?.image != null ? (
                            <img
                              style={{
                                width: "65px",
                                height: "65px",
                                borderRadius: "100%",
                              }}
                              src={`${BaseURL}/api/uploads/${
                                activePersona?._id
                              }?random=${Math.random()}`}
                              alt=""
                            />
                          ) : (
                            <img
                              style={{
                                width: "65px",
                                height: "65px",
                                borderRadius: "100%",
                              }}
                              src={uploadPhoto}
                              alt=""
                            />
                          )}
                        </label>
                      </div>
                      <div className="ms-3">
                        <h1 className="upload-heading">
                          Upload a photo or logo
                        </h1>
                        <div className="upload-desc">
                          Photos help your teammates recognize personas in
                          emissary
                        </div>
                      </div>
                    </div>
                    <div className="form-container d-flex flex-column mt-3">
                      <div className="form-field">
                        <label
                          className="form-label"
                          style={{ color: "black", fontWeight: "500" }}
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          className="create-persona-input"
                          name="f_name"
                          value={f_Name}
                          onChange={handleInputChange}
                        />
                        {error.firstName === true && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            Please enter first name
                          </div>
                        )}
                      </div>
                      <div className="form-field">
                        <label
                          className="form-label"
                          style={{ color: "black", fontWeight: "500" }}
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="create-persona-input"
                          name="l_name"
                          value={l_Name}
                          onChange={handleInputChange}
                        />
                        {error.lastName === true && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            Please enter last name
                          </div>
                        )}
                      </div>
                      <div className="form-field">
                        <label
                          className="form-label"
                          style={{ color: "black", fontWeight: "500" }}
                        >
                          Company Name
                        </label>
                        <input
                          type="text"
                          className="create-persona-input"
                          name="comapnyName"
                          value={comapnyName}
                          onChange={handleInputChange}
                        />
                        {error.companyName === true && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            Please enter company name
                          </div>
                        )}
                      </div>
                    </div>
                    <ChakraButton
                      className="close-btn align-self-end mt-2"
                      size="md"
                      fontWeight={"500"}
                      fontSize={"13px"}
                      type="submit"
                      borderRadius={"5px"}
                      bg="#21DC82"
                      w="fit-content"
                      color="white"
                      _hover={{ bg: "black" }}
                      onClick={updatePersona}
                    >
                      SAVE CHANGES
                    </ChakraButton>
                  </div>
                </TabPanel>

                <TabPanel className="px-0">
                  <PersonaTab />
                </TabPanel>
                <TabPanel className="px-0">{/* <CompanyDetails /> */}</TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
      </div>
      <div>
        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Header style={{ border: "none" }} closeButton>
            {/* <Modal.Title>Are you sure you want to delete?</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <h6 style={{ paddingLeft: "20px", fontSize: "1.3rem" }}>
              Are you sure you want to delete persona?
            </h6>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button variant="secondary" onClick={closeModal}>
              No
            </Button>
            <Button variant="danger" onClick={deletePersonaHandler}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </ChakraProvider>
  );
};

export default EditPersona;
