import React, { useState } from "react";
import {
  Flex,
  Text,
  Input,
  Button,
  Link,
  Stack,
  Img,
  useToast,
  ChakraProvider,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../Images/logo-full.svg";
import emissaryHaeding from "../../Images/emissaryLogo.svg";
import "../SignIn/Login.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { resetPasswordService } from "../../Service/service";
import Swal from "sweetalert2";
const ResetPassword = () => {
  const toast = useToast();
  const nav = useNavigate();
  const {token} = useParams()
  // console.log("🚀 ~ file: ResetPassword.js:21 ~ ResetPassword ~ token:", token)
  const [loading, setloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  // console.log("🚀 ~ file: ResetPassword.js:27 ~ ResetPassword ~ passwordError:", passwordError)
  const [password, setPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const handleConfirmPasswordChange = event => {
    const newPassword = event.target.value;
    setConfirmPassword(newPassword);
  };
  const handlePasswordChange = event => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    const isValidPassword = passwordRegex.test(password);
    let arr = [0,0]
    if (!confirmPassword) {
      setConfirmPasswordError('Confirm Password is required.');
      arr=[1,0]
    } else if (confirmPassword !== password) {
      setConfirmPasswordError('Passwords do not match.');
      arr=[1,0]
    } else {
      setConfirmPasswordError('');
      arr=[0,0]
    }
  
    if (!isValidPassword) {
      setPasswordError(
        'Password must contain at least 8 characters, one uppercase, one lowercase, one digit, and one special character.'
      );
      arr=[arr[0],1];
    } else {
      setPasswordError('');
      arr=[arr[0],0];
    }
  
    if (arr[0]===0 && arr[1]===0) {
      // Only set loading to true if validations pass
      setloading(true);
      // Handle successful form submission
      // console.log('Form submitted successfully.');
  
      // Additional check before API call

        const res = await resetPasswordService(password, confirmPassword, token);
        if (res.success) {
          setloading(false);
          Swal.fire({
            title: res.message,
            icon: 'success',
          });
          nav('/');
          setPassword('');
          setConfirmPassword('');
        } else {
          setloading(false);
          Swal.fire({
            title: res.message,
            icon: 'error',
          });
        }
      
    } else {
      console.log('Form has errors. Please correct them.');
    }
  };
  
  
  
  
  const isSubmitDisabled = !confirmPassword || !password;
  return (
    <>
      <div className="signupContainer">
        <div className="signupContainer-inner">
        <div className="signupContainer-header">
            <img src={emissaryHaeding} alt="Emissary" />
          </div>
          <Stack spacing={1} pt={16} textAlign={'center'}>
              <Text fontWeight={'600'} fontSize={'xl'} color={'#0D1821'}>
                Reset Password
              </Text>
            </Stack>
          <div>
            <div className="signupContainer-form">
              <form className="loginForm">
                <div>
                  {/* password */}
                  <div className="form-field">
                    <label className="form-label" htmlFor="form2Example22">
                      Password
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="form2Example22"
                        className="form-control"
                        name="password"
                        placeholder="Enter password"
                        onChange={handlePasswordChange}
                        value={password}
                        required
                      ></input>
                      <span
                        style={{
                          position: "absolute  ",
                          cursor: "pointer",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={()=>setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    {passwordError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {passwordError}
                      </div>
                    )}
                  </div>
                  {/* confirm password */}
                  <div className="form-field">
                    <label className="form-label" htmlFor="form2Example22">
                     Confirm Password
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        id="form2Example22"
                        className="form-control"
                        name="password"
                        placeholder="Enter confirm password"
                        onChange={handleConfirmPasswordChange}
                        value={confirmPassword}
                        required
                      ></input>
                      <span
                        style={{
                          position: "absolute  ",
                          cursor: "pointer",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={()=>setshowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    {confirmPasswordError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {confirmPasswordError}
                      </div>
                    )}
                  </div>
                  {/*login button  */}
                  <div className="form-field">
                    <ChakraProvider>
                      <Button
                        onClick={handleSubmit}
                        isLoading={loading}
                        size="md"
                        fontWeight={'500'}
                        fontSize={'13px'}
                        type="submit"
                        borderRadius={"5px"}
                        bg="#21DC82"
                        w='full'
                        color="white"
                        _hover={{ bg: "black" }}
                        isDisabled={isSubmitDisabled || loading}
                      >
                        SUBMIT
                      </Button>
                    </ChakraProvider>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
