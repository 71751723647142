import React, { useState } from "react";
import "./Feedback.css";
import {
  Button,
  ChakraProvider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { submitFeedbackService } from "../../Service/service";
import Swal from "sweetalert2";
const Feedback = ({ position }) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setloading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const submitFeedbackHandler = async () => {
    try {
      setloading(true);
      const res = await submitFeedbackService(inputValue);
      if (res.success) {
        setloading(false);
        Swal.fire({
          icon: "success",
          title: res.message,
        });
        setInputValue("");
        onClose();
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: Feedback.js:36 ~ submitFeedbackHandler ~ error:",
        error
      );
    }
  };
  return (
    <ChakraProvider>
      <button
        onClick={onOpen}
        className={
          position == "not-fixed" ? "not-fixed-button" : "bottom-right-button"
        }
      >
        FEEDBACK
      </button>
      <Modal
        scrollBehavior="inside"
        size={"2xl"}
        isCentered
        isOpen={isOpen}
        onClose={() => {
          setInputValue("");
          setloading(false);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Your feedback is important to us.</ModalHeader>
          <ModalCloseButton />
          <ModalBody py={4}>
            <Text>
              Please give us a detailed description of the issue your are
              facing.
            </Text>
            <Textarea
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              rows={5}
            ></Textarea>

            <Stack mt={4} w="100%" alignItems={"end"}>
              <Button
                isDisabled={
                  !inputValue || (inputValue && inputValue.trim().length == 0)
                }
                size="md"
                fontWeight={"500"}
                fontSize={"13px"}
                type="submit"
                borderRadius={"5px"}
                bg="#21DC82"
                w="fit-content"
                alignSelf={"end"}
                color="white"
                isLoading={loading}
                onClick={submitFeedbackHandler}
              >
                SUBMIT
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default Feedback;
