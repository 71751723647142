import React, { createContext, useEffect, useState } from "react";
import {
  UpdateChatNameService,
  deleteChatSessionsService,
  getAllChatSessionsService,
} from "../Service/service";

const PersonaChatContext = createContext();
export function ChatContext({ children }) {
  const [sessions, setSessions] = useState();
  const [updateChatArray, setChatArray] = useState(false);
  
// get all chat sessions function
  const getChatSessions = async (personaId) => {
    const response = await getAllChatSessionsService(personaId);
    if (response.success) {
      setSessions(response.data);
    }

    return response;
  };
// delete single chat session from chat list
  const deleteSession = async (chatId) => {
    const response = await deleteChatSessionsService(chatId);
    if (response.success) {
      const updatedItems = sessions.filter((chat) => chat._id !== chatId);
      setSessions(updatedItems);
    }

    return response;
  };

  const updateChatTitle =  async (chatId,title) => {
    const res = await UpdateChatNameService(chatId, title);
    if (res.success) {
      const updatedItems = sessions.map((item) => {
        if (item._id === chatId) {
          return { ...item, title: title };
        }
        return item;
      });
  
      setSessions(updatedItems);
    }

    return res;
  }
  const emptyChatArray = () => {
    setChatArray(!updateChatArray)
  }
  return (
    <PersonaChatContext.Provider
      value={{
        sessions,
        getChatSessions,
        deleteSession,
        updateChatTitle,
        emptyChatArray,
        updateChatArray
      }}
    >
      {children}
    </PersonaChatContext.Provider>
  );
}

export default PersonaChatContext;
