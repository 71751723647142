import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "../../Images/moment.svg";
import "./sideNav.css";
import PersonaMomentContext from "../../context/MomentsContext";
import Swal from "sweetalert2";
import {
  Button,
  ChakraProvider,
  Divider,
  Heading,
  Img,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import setting from "../../Images/settings.svg";
import customize from "../../Images/sliders.svg";
import billing from "../../Images/credit-card.svg";
import security from "../../Images/shield.svg";
import usage from "../../Images/usage.png";
import users from "../../Images/user.svg";
import { BsChatDots, BsPlusCircleFill } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
const SideNav = () => {
  const nav = useNavigate();
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const [momentsList, setmomentsList] = useState([]);
  const [myPersonaData, setMyPersonaData] = useState([]);
  // const [activePersona, setActivePersona] = useState({});
  const { getMoments, moments } = useContext(PersonaMomentContext);
  useEffect(() => {
    if (moment) {
      setmomentsList(moments);
    }
  }, [moments]);

  useEffect(() => {
    const personaList = localStorage.getItem("my_persona_list");
    if (personaList) {
      const myPersonas = JSON.parse(personaList);
      setMyPersonaData(myPersonas);
      // setActivePersona(activePersonaData);
    }
    getMoments().then(() => {});
  }, []);
  const adminItems = [
    {
      icon: setting,
      title: "General",
      path: "/admin/general",
    },
    {
      icon: customize,
      title: "Customization",
      path: "/admin/customization",
    },
    {
      icon: users,
      title: "Users",
      path: "/admin/users",
    },
    {
      icon: security,
      title: "Security",
      path: "/admin/security",
    },
    {
      icon: billing,
      title: "Billing",
      path: "/admin/billing",
    },
    {
      icon: usage,
      title: "Usage Stats",
      path: "/admin/usage-stats",
    },
  ];
  const currentPath = window.location.pathname;
  return (
    <div className="sideBarContainer">
      <div class="dropdown hide-desktop">
        <button
          className="togglebtn1 dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="icon-more-vertical"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li>
            {" "}
            <Link
              className="w-100 dropdown-item"
              to={momentsList?.length > 0 ? "/mind" : "#"}
              onClick={() =>
                momentsList?.length > 0
                  ? () => {}
                  : Swal.fire({
                      icon: "error",
                      title: "Please first create a moment",
                    })
              }
            >
              Mind
            </Link>
          </li>
          <li>
            <Link className="w-100 dropdown-item" to={"/CHAT"}>
              Chat
            </Link>
          </li>
          <li>
            <Link className="w-100 dropdown-item" to="/moments">
              Create a moment
            </Link>
          </li>
        </ul>
      </div>
      <div className="personaNav show-desktop">
        {currentPath.startsWith("/admin") ? null : (
          <>
            <ChakraProvider>
              <Button
                justifyContent={"start"}
                mb={6}
                w="full"
                leftIcon={
                  <BsPlusCircleFill
                    style={{ paddingBottom: "2px" }}
                    fontSize="20px"
                  />
                }
                onClick={() => nav("/moments")}
                size="md"
                fontWeight={"500"}
                fontSize={"13px"}
                borderRadius={"5px"}
                bg="#21DC82"
                color="white"
                _hover={{ bg: "black" }}
              >
                NEW MOMENT
              </Button>
            </ChakraProvider>
            <Link
              className="personaNav-item"
              to={"/mind"}
              // onClick={() =>
              //   momentsList?.length > 0
              //     ? () => {}
              //     : Swal.fire({
              //         icon: "error",
              //         title: "Please first create a moment",
              //       })
              // }
            >
              {currentPath === "/mind" ? (
                <span className="item current d-flex">
                  <i class="icon-mind"></i> MIND
                </span>
              ) : (
                <span className="item d-flex">
                  <i class="icon-mind"></i> MIND
                </span>
              )}
            </Link>

            <Link className="personaNav-item" to={"/chat"}>
              {currentPath === "/chat" ? (
                <span className="item current d-flex">
                  <BsChatDots
                    style={{ marginRight: "0.45rem", fontSize: "16px" }}
                  />{" "}
                  CHAT
                </span>
              ) : (
                <span className="item d-flex">
                  <BsChatDots
                    style={{ marginRight: "0.45rem", fontSize: "16px" }}
                  />{" "}
                  CHAT
                </span>
              )}
            </Link>
            {parsedToken?.data?.role !== "Collaborator" &&
            parsedToken?.data?.role !== "User" ? (
              <Link className="personaNav-item" to={"/edit-persona"}>
                {currentPath === "/edit-persona" ? (
                  <span className="item current d-flex">
                    <AiOutlineEdit
                      style={{ marginRight: "0.45rem", fontSize: "16px" }}
                    />{" "}
                    PERSONA
                  </span>
                ) : (
                  <span className="item d-flex">
                    <AiOutlineEdit
                      style={{ marginRight: "0.45rem", fontSize: "16px" }}
                    />{" "}
                    PERSONA
                  </span>
                )}
              </Link>
            ) : myPersonaData?.some(
                (persona) =>
                  JSON.stringify(persona) === JSON.stringify(activePersona)
              ) ? (
              <Link className="personaNav-item" to={"/edit-persona"}>
                {currentPath === "/edit-persona" ? (
                  <span className="item current d-flex">
                    <AiOutlineEdit
                      style={{ marginRight: "0.45rem", fontSize: "16px" }}
                    />{" "}
                    PERSONA
                  </span>
                ) : (
                  <span className="item d-flex">
                    <AiOutlineEdit
                      style={{ marginRight: "0.45rem", fontSize: "16px" }}
                    />{" "}
                    PERSONA
                  </span>
                )}
              </Link>
            ) : (
              <ChakraProvider>
                <Tooltip hasArrow label="Not Allowed" fontSize="sm">
                  <Link className="notallowed-link">
                    <span className="notallowed-item d-flex">
                      <AiOutlineEdit
                        style={{ marginRight: "0.45rem", fontSize: "16px" }}
                      />{" "}
                      PERSONA
                    </span>
                  </Link>
                </Tooltip>
              </ChakraProvider>
            )}

            <Divider />
            <Link to="/team" className="personaNav-item">
              {currentPath === "/team" ? (
                <span className="item current d-flex">
                  <FiUsers
                    style={{ marginRight: "0.45rem", fontSize: "16px" }}
                  />{" "}
                  PERSONA TEAM
                </span>
              ) : (
                <span className="item d-flex">
                  <FiUsers
                    style={{ marginRight: "0.45rem", fontSize: "16px" }}
                  />{" "}
                  PERSONA TEAM
                </span>
              )}
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default SideNav;
