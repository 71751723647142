import {
  BaseURL,
  CONTAINER_NAME,
  PY_API,
  SAS_TOKEN,
  STORAGE_ACCOUNT_NAME,
} from "../enviornment";
// auth module API's
export function signUpUserService(formData) {
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/user/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// login service
export function loginService(formData) {
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/user/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// forgot password
export function forgotPasswordService(email) {
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/user/forgotPassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// reset password
export function resetPasswordService(password, confirmPassword, token) {
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/user/resetPassword", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        auth_token: token,
      },
      body: JSON.stringify({
        password,
        confirmPassword,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// request access
export function requestAccessService(formData) {
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/user/requestAccess", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// approve user
export function approveUserService(token, email, name) {
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/user/approve", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        auth_token: token,
      },
      body: JSON.stringify({ email, name }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// chat model API
export function chatModalService(
  momentName,
  userEmail,
  type,
  text,
  personaEmail,
  loadEmbeddings,
  chatTitle
) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const activeUser = JSON.parse(localStorage.getItem("active_user"));
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "session=eyJzZXNzaW9uX2lkIjoiN2JmM2U0NDktYzE1Yi00NThlLTk5NDktZTYyZDZhMDIxNzBmIn0.ZIxJ9g.I1fXAV4cZWRw8XmX4Y2P2NDEhm0"
  );

  var raw = JSON.stringify({
    personaEmail,
    personaOwnerEmail:userEmail,
    firstName:activePersona?.firstName,
    lastName:activePersona?.lastName,
    companyName:activePersona?.epClientCompany,
    userEmail:activeUser?.email,
    userName:activeUser?.name,
    type,
    text,
    momentName: type == "single" ? momentName : "no name",
    loadEmbeddings,
    chatTitle,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return new Promise((resolve, reject) => {
    fetch(`${PY_API}/query`, requestOptions)
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function personaStyleService(personaEmail,userEmail) {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    personaEmail,
    userEmail,
    personaName:`${activePersona?.firstName} ${activePersona?.lastName}`
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return new Promise((resolve, reject) => {
    fetch(`${PY_API}/personaStyle`, requestOptions)
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function CreatePersonaChatService(text, reply, title) {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/chats/createChat", {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: parsedToken?.data?._id,
        personaId: activePersona?._id,
        messages: [{ text, reply }],
        title,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Update chat API
export function UpdateChatService(chatId, message, reply) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/chats/updateChat", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chatId,
        message,
        reply,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function UpdateChatNameService(chatId, title) {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/chats/updateTitle", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        personaId: activePersona?._id,
        chatId,
        title,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Get Single Chat API
export function getSingleChatService(chatId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/chats/getChat/${chatId}`, {
      method: "GET",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get single persona API
export function getSinglePersonaService() {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/personas/singlePersona/${activePersona?._id}`, {
      method: "GET",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// get single user API
export function getSingleUserService(userId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/singleUser/${userId}`, {
      method: "GET",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// get all chat sessions API
export function getAllChatSessionsService() {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/chats/getAllChats`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: parsedToken?.data?._id,
        personaId: activePersona?._id,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// delete single chat session API
export function deleteChatSessionsService(chatId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/chats/deleteChat/${chatId}`, {
      method: "DELETE",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdatePersonalDetailsService(text, socialLinks) {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/personalDetails/updateDetails", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        personaId: activePersona?._id,
        text,
        socialLinks,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPersonalDetailsService() {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/personalDetails/getDetails/${activePersona?._id}`, {
      method: "GET",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCompanyDetailsService() {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/companyDetails/getDetails/${activePersona?._id}`, {
      method: "GET",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateCompanyDetailsService(text, companyWebsite) {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/companyDetails/updateDetails", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        personaId: activePersona?._id,
        text,
        companyWebsite,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getClientPersonaService() {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/clientPersona/getDetails/${activePersona?._id}`, {
      method: "GET",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateClientPersonaService(clientPersona) {
  const personaId = localStorage.getItem("active_persona");
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));

  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/clientPersona/updateDetails", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        personaId: activePersona?._id,
        clientPersona,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdatePersonaProfilePicService(formData) {
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/uploads/updatePersonaImage", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function searchPersonasService(userId, query, allPersona) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/personas/search?userId=${userId}&q=${query}`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        allPersona: allPersona,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function searchMomentsService(personaId, query) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/moments/search?personaId=${personaId}&q=${query}`, {
      method: "GET",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// get all chat sessions API
export function getAllUsersService(role) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const activeUser = JSON.parse(localStorage.getItem("active_user"));
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/getAllUsers`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        role,
        userId: activeUser?._id,
      }),
    })
      .then((response) => {
        // console.log("🚀 ~ file: service.js:501 ~ .then ~ response:", response);
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function deleteUserService(userId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/deleteUser/${userId}`, {
      method: "DELETE",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function inviteUserService(email, role) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/inviteUser`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        role,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function addCollaboratorService(
  selectedEmail,
  selectedPersonas,
  newUser,
  role
) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/addCollaborator`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: parsedToken?.data?._id,
        selectedEmail,
        selectedPersonas: selectedPersonas ? selectedPersonas : [],
        newUser,
        role,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function joinEmissaryService(formData, token) {
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/joinEmissary`, {
      method: "POST",
      headers: {
        auth_token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function makeAdminService(userId) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/addAdmin/${userId}`, {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// analyze momnet apis

export function getKeywordsService(id,analyze) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/moments/getKeywords", {
      method: "POST",
      body:JSON.stringify({
        momentId:id,
        userId:parsedToken?.data?._id,
        analyze:analyze
      }),
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSummaryService(id) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/moments/getSummary/${id}`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getGraphDataService(momentName, keyword,loadData) {
  const storedDataString = localStorage.getItem("active_persona_data");

  // Convert the string back to an object using JSON.parse
  const personaData = JSON.parse(storedDataString);
  const storedDataString2 = localStorage.getItem("active_user");

  // Convert the string back to an object using JSON.parse
  const userData = JSON.parse(storedDataString2);
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(PY_API + `/knowledgeGraphData`, {
      method: "POST",
      headers: {
        // auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loadData,
        momentName,
        keyword,
        personaEmail: personaData?.epClientEmail,
        userEmail: userData?.email,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDocumentsTextService(urlsArray) {
  // console.log(
  //   "🚀 ~ file: service.js:553 ~ getKeywordsService ~ urlsArray:",
  //   urlsArray
  // );
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(PY_API + `/getText`, {
      method: "POST",
      headers: {
        // auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fileUrls: urlsArray,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// admin API's
export function getUsageStatsService() {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/getStats`, {
      method: "GET",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changeUserRoleService(userId, role) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/changeRole`, {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        role,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function changeUserStatusService(userId, activate) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/changeStatus`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        activate,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// edit user profile
export function UpdateUserProfileService(formData) {
  // Log the content of the FormData
  // formData.forEach((value, key) => {
  //   console.log(key, value);
  // });
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/user/editProfile", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdatePersonaProfileService(formData) {
  // Log the content of the FormData
  // formData.forEach((value, key) => {
  //   console.log(key, value);
  // });
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + "/api/personas/edit", {
      method: "PUT",
      headers: {
        auth_token: parsedToken?.auth_token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getUsageProfilePicService() {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/uploads/getUser/${parsedToken?.data?._id}`, {
      method: "GET",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPersonaEmailsService(email) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/getEmails`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body:JSON.stringify({
        email
      })
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPersonalCollaboratorsService() {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/getCollaborators/${activePersona?._id}`, {
      method: "GET",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function makePersonaOwnerService(newPersonaOwner,changeOwner) {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/makePersonaOwner`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        personaId:activePersona?._id,
        newPersonaOwner,
        changeOwner
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deletePersonaCollaboratorService(userEmail) {
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/removeCollaborator`, {
      method: "DELETE",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        personaId:activePersona?._id,
        userEmail,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function submitFeedbackService(feedbackText) {
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return new Promise((resolve, reject) => {
    fetch(BaseURL + `/api/user/sendFeedback`, {
      method: "POST",
      headers: {
        auth_token: parsedToken?.auth_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId:parsedToken?.data?._id,
        feedbackText,
      }),
    })
      .then((response) => {
        if (response.status == 401) {
          window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}