import { Button, ChakraProvider, Heading, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../Images/logo-full.svg";
import "../SignIn/Login.css";
import { approveUserService } from "../../Service/service";
import Swal from "sweetalert2";
const ApproveUser = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [loading, setloading] = useState(false);
  const [isShowApproveButton, setIsShowApproveButton] = useState(false);
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const name = searchParams.get("name");
  const approveRequestHandler = async () => {
    try {
      setloading(true);
      const res = await approveUserService(token, email, name);
      if (res.success) {
        setloading(false);
        setIsShowApproveButton(true);
        Swal.fire({
          icon: "success",
          title: res.message,
        });
      } else {
        setloading(false);

        Swal.fire({
          icon: "error",
          title: res.message,
        });
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: ApproveUser.js:17 ~ approveRequestHandler ~ error:",
        error
      );
    }
  };
  return (
    <ChakraProvider>
      <div className="signupContainer">
        <div className="signupContainer-inner">
          {!isShowApproveButton ? (
            <>
              <div className="signupContainer-header">
                <img src={logo} alt="Logo" className="logo" />
              </div>
              <Stack spacing={1} pt={4} textAlign={"center"}>
                <Text fontWeight={"600"} fontSize={"xl"} color={"#0D1821"}>
                  Approve User
                </Text>
                {/* <Text>
             Enter the Email address associated with your account and we’ll
             send you a link to reset your password
           </Text> */}
              </Stack>
              <Stack spacing={6}>
                <Stack direction={"column"} spacing={0}>
                  <Heading fontSize={"lg"} fontWeight={"semibold"}>
                    Username
                  </Heading>
                  <input
                    type="text"
                    id="form2Example11"
                    className="form-control"
                    name="email"
                    defaultValue={name}
                    placeholder="Enter email"
                    required
                    disabled
                  />
                </Stack>
                <Stack direction={"column"} spacing={0}>
                  <Heading fontSize={"lg"} fontWeight={"semibold"}>
                    Email
                  </Heading>
                  <input
                    type="text"
                    id="form2Example11"
                    className="form-control"
                    name="email"
                    defaultValue={email}
                    placeholder="Enter email"
                    required
                    disabled
                  />
                </Stack>
                <Button
                  mt={12}
                  onClick={approveRequestHandler}
                  isLoading={loading}
                  size="lg"
                  type="submit"
                  className={"create-account-btn w-100"}
                  isDisabled={!email || !name}
                >
                  Approve
                </Button>
              </Stack>
            </>
          ) : (
            <Text marginBottom={"0 !important"} textAlign={'center'} fontWeight={'semibold'}>You can now close this tab</Text>
          )}
        </div>
      </div>
    </ChakraProvider>
  );
};

export default ApproveUser;
