import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Mind.css";
import NavBar from "../NavBar/NavBar";
import ChatEdit from "../../Images/edit-icon.svg";
import PieChart from "../../Images/pie-chart.svg";
import UserHeader from "../UserProfile/userHeader";
import SideNav from "../sideNav/sideNav";
import cross from "../../Images/cross-icon.svg";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import LoadingSpinner from "../../Assests/loaderspinner.gif";
import { BaseURL, PY_API, } from "../../enviornment";
import brook from "../../Images/brook.png";
import { Button, Modal, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import PersonaMomentContext from "../../context/MomentsContext";
import {
  ChakraBaseProvider,
  ChakraProvider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Button as ChakraButton,
  Tr,
  useStepContext,
} from "@chakra-ui/react";
import Unknown from "../../Images/Unknown.jpg";
import search from "../../Images/search.svg";
import { searchMomentsService } from "../../Service/service";
import { RotatingLines } from "react-loader-spinner";
import Feedback from "../feedback/Feedback";
import moment from "moment/moment";
const Mind = () => {
  const { isDarkMode, getMoments, moments, deleteMoment } =
    useContext(PersonaMomentContext);
  const navigate = useNavigate();
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const myVariable = true;
  const [momentsList, setMomentsList] = useState([]);
  const [tempmomentsList, settempMomentsList] = useState([]);
  const [searchMomentText, setSearchMomentText] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [isRenderer, setisRenderer] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [noItemFound, setnoItemFound] = useState(false);
  const [targetMoment, setTargetMoment] = useState();
  const [targetMomentIndex, setTargetMomentIndex] = useState();
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const ref = useRef(null);
  const refClose = useRef(null);
  const editMoment = (momentdetails) => {
    navigate("/updatemoment", {
      state: {
        momentDetail: momentdetails,
      },
    });
  };
  const onChange = (e) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    // setEditMoments({ [e.target.name]: e.target.value });
  };
  const handleClick = async (editMoments) => {
    // e.preventDefault();
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    var requestOptions = {
      method: "PATCH",
      headers: parsedToken,
      body: JSON.stringify({
        momentName: editMoments.momentName,
      }),
    };
    // setIsLoading(true);
    // console.log(editMoments._id);
    const response = await fetch(
      `${BaseURL}/api/moments/updateMoment/${editMoments._id}`,
      requestOptions
    );
    // console.log(response.json());
  };
  useEffect(() => {
    const fetchMoments = async () => {
      setIsLoading(true);
      const res = await getMoments();
      if (res.success) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    fetchMoments();
  }, [isRenderer]);
  useEffect(() => {
    if (moments) {
      setMomentsList(moments);
      settempMomentsList(moments);
    }
  }, [moments]);

  async function DeleteMomnet() {
    setIsLoading(true);
    const res = await deleteMoment(targetMoment?._id);
    if (res.success) {
      setIsLoading(false);
      closeModal();
      Swal.fire({
        icon: "success",
        title: res.message,
      });
    } else {
      setIsLoading(false);
    }
  }
  // search persona handler
  const searchMomentHandler = async (text) => {
    // const activePersona = JSON.parse(
    //   localStorage.getItem("active_persona_data")
    // );
    const updatedArray = momentsList.filter((moment) => {
      const username = moment.momentName;
      if (username.toLowerCase().includes(text.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    });
    if (updatedArray?.length == 0) {
      setnoItemFound(true);
    } else {
      setnoItemFound(false);
    }
    settempMomentsList(updatedArray);
    // if (searchMomentText.length > 0) {
    //   try {
    //     setIsLoading(true);
    //     const res = await searchMomentsService(
    //       activePersona?._id,
    //       searchMomentText
    //     );
    //     console.log(
    //       "🚀 ~ file: AllPersonas.js:403 ~ searchPersonaHandler ~ res:",
    //       res
    //     );
    //     if (res.success) {
    //       setIsLoading(false);
    //       if (res.data.length == 0) {
    //         setnoItemFound(true);
    //       } else {
    //         setnoItemFound(false);
    //       }
    //       setMomentsList(res.data);
    //       settempMomentsList(res.data)
    //     } else {
    //       setIsLoading(false);
    //       Swal.fire({
    //         icon: "error",
    //         title: res.message,
    //       });
    //     }
    //   } catch (error) {
    //     setIsLoading(false);
    //     console.log("error", error);
    //   }
    // } else {
    //   setisRenderer(!isRenderer);
    // }
  };
  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <Feedback />

      <NavBar />
      <div
        style={{ maxHeight: "100vh", overflow: "hidden" }}
        className={
          isDarkMode ? "persona-container-dark" : "persona-container-light"
        }
      >
        <div className="userHeader hide-desktop">
          <SideNav />
        </div>
        <div className="mainContainer">
          <div className="mainContainer-left show-on-desktop">
            {" "}
            <SideNav />
          </div>
          <div className="mainContainer-right">
            <div className="mindContainer">
              <h1 class="text-start">Mind</h1>
              <div className="d-flex justify-content-between">
                <div className="align-items-center d-flex gap-3 search-input px-2">
                  <span>
                    <img src={search} className="search-img" alt="" />
                  </span>
                  <input
                    type="text"
                    className="border-0"
                    placeholder="Search by title"
                    aria-label="search"
                    value={searchMomentText}
                    // onKeyUp={searchMomentHandler}
                    onChange={(e) => {
                      setSearchMomentText(e.target.value);
                      if (e.target.value.length > 0) {
                        searchMomentHandler(e.target.value);
                      } else {
                        settempMomentsList(momentsList);
                      }
                    }}
                  />
                </div>
                <ChakraProvider>
                  <ChakraButton
                    onClick={() => navigate("/moments")}
                    size="md"
                    fontWeight={"500"}
                    fontSize={"13px"}
                    type="submit"
                    borderRadius={"5px"}
                    bg="#21DC82"
                    w="fit-content"
                    color="white"
                    _hover={{ bg: "black" }}
                  >
                    CREATE A MOMENT
                  </ChakraButton>
                </ChakraProvider>
              </div>
              {/* moments list */}
              {tempmomentsList?.length !== 0 ? (
                // <div key={item._id} className="mind-row">
                //   <div className="w-50">
                //     <span>{item.momentName}</span>
                //   </div>
                //   <div className="d-flex justify-content-between align-items-center">
                //     <div>
                //       <img src={brook} className="brook-img" alt="" />
                //     </div>
                //     <div>
                //       <span className="date mx-2 mt-2">
                //         {item.momentDate}
                //       </span>
                //     </div>
                //   </div>
                //   <div className="d-flex gap-3">
                //     <div
                //       className="cursor-pointer"
                //       onClick={() => {
                //         item?.documents && item?.documents.length > 0
                //           ? navigate("/analyze-moment", {
                //               state: { momentData: item },
                //             })
                //           : Swal.fire({
                //               icon: "error",
                //               title:
                //                 "There are no documents in this moment.",
                //             });
                //       }}
                //     >
                //       <TbBrandGoogleAnalytics />
                //     </div>
                //     <div
                //       className="cursor-pointer"
                //       onClick={() => editMoment(item)}
                //     >
                //       <img src={ChatEdit} className="edit-img" alt="" />
                //     </div>
                //     <div
                //       className="cursor-pointer"
                //       // data-target="#deletMoment"
                //       onClick={
                //         () => {
                //           setTargetMoment(item);
                //           setTargetMomentIndex(index);
                //           openModal();
                //         }
                //         // DeleteMomnet(item.momentName, item._id, item, index)
                //       }
                //     >
                //       <img
                //         src={cross}
                //         className="edit-img"
                //         alt="more-option"
                //       />
                //     </div>
                //   </div>
                // </div>
                <ChakraProvider>
                  <TableContainer
                    maxH={"60vh"}
                    overflowY="auto"
                    w="100%"
                    maxW="100%"
                    mt={6}
                    marginRight={8}
                  >
                    <Table variant="simple">
                      <Thead fontWeight={"bold"}>
                        <Tr>
                          <Th
                            className="moments-table"
                            color={
                              isDarkMode
                                ? "white !important"
                                : "black !important"
                            }
                          >
                            TITLE
                          </Th>
                          <Th
                            className="moments-table"
                            color={
                              isDarkMode
                                ? "white !important"
                                : "black !important"
                            }
                          >
                            DOCUMENTS
                          </Th>
                          <Th
                            color={"black !important"}
                            className="moments-table"
                          >
                            CREATED BY
                          </Th>
                          <Th
                            color={"black !important"}
                            className="moments-table"
                          >
                            CREATED ON
                          </Th>
                          <Th className="moments-table"></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {/* {momentsList.map((item, index) => ( */}
                        {tempmomentsList.map((item, index) => (
                          <Tr>
                            <Td className="moments-table">
                              <div className="align-items-center d-flex gap-3">
                                <span
                                  style={{ maxWidth: "250px" }}
                                  className="moments-name"
                                >
                                  {item?.momentName}
                                </span>
                              </div>
                            </Td>
                            <Td
                              fontWeight={"600"}
                              className="moments-table"
                              maxW={"100px"}
                            >
                              <div className="d-flex mx-3">
                                {item?.documents?.length}
                              </div>
                            </Td>
                            <Td className="moments-table">
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    item?.momentOwner?.image === null
                                      ? Unknown
                                      : `${BaseURL}/api/uploads/getUser/${
                                          item?.momentOwner?._id
                                        }?random=${Math.random()}`
                                  }
                                  style={{
                                    width: "1.75rem",
                                    height: "1.75rem",
                                  }}
                                  className="brendan me-2"
                                  alt="person"
                                />
                                <span>{item?.momentOwner?.name}</span>
                              </div>
                            </Td>
                            <Td className="moments-table">
                              <div className="d-flex">
                                {moment(
                                  item?.momentDate,
                                  "MMMM DD, YYYY"
                                ).format("DD/MM/YYYY")}
                              </div>
                            </Td>
                            <Td className="moments-table">
                              <div className="d-flex gap-3">
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    item?.documents &&
                                    item?.documents.length > 0
                                      ? navigate("/analyze-moment", {
                                          state: { momentData: item },
                                        })
                                      : Swal.fire({
                                          icon: "error",
                                          title:
                                            "There are no documents in this moment.",
                                        });
                                  }}
                                >
                                  <img
                                    src={PieChart}
                                    className="edit-img"
                                    alt=""
                                  />
                                </div>
                                <div
                                  className="cursor-pointer"
                                  onClick={() => editMoment(item)}
                                >
                                  <img
                                    src={ChatEdit}
                                    className="edit-img"
                                    alt=""
                                  />
                                </div>
                                <div
                                  className="cursor-pointer"
                                  // data-target="#deletMoment"
                                  onClick={
                                    () => {
                                      setTargetMoment(item);
                                      setTargetMomentIndex(index);
                                      openModal();
                                    }
                                    // DeleteMomnet(item.momentName, item._id, item, index)
                                  }
                                >
                                  <img
                                    src={cross}
                                    className="edit-img"
                                    alt="more-option"
                                  />
                                </div>
                              </div>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </ChakraProvider>
              ) : (
                <div className="no-moments">
                  {noItemFound ? null : (
                    <p style={{ textAlign: "center", marginTop: "200px" }}>
                      No Moments exist yet. Please Create a Moment to view it
                      here
                    </p>
                  )}
                </div>
              )}
            </div>
            <div>
              {/* <AzureBlobComponent
               userEmail={'sufyan@aidevlab.com'}
               personaEmail={'sufyan@aidevlab.com'}
               momentName={'Moment'}
               /> */}
            </div>
          </div>
        </div>{" "}
      </div>
      {/* <!-- Modal --> */}
      {/* confirm delete popup */}
      <div>
        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Header style={{ border: "none" }} closeButton>
            {/* <Modal.Title>Are you sure you want to delete?</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <h6 style={{ paddingLeft: "20px", fontSize: "1.3rem" }}>
              Are you sure you want to delete?
            </h6>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button variant="secondary" onClick={closeModal}>
              No
            </Button>
            <Button variant="danger" onClick={DeleteMomnet}>
              Yes
              {/* {loader ? (
                <Spinner animation="border" size="sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Yes"
              )} */}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Mind;
