import React, { useRef, useState } from "react";
import "../Persona/Persona.css";
import uploadPhoto from "../../Images/uploadPhoto.svg";
import LoadingSpinner from "../../Assests/loaderspinner.gif";
import Swal from "sweetalert2";
import { BaseURL } from "../../enviornment";
import { Button } from "@chakra-ui/react";
import { UpdateUserProfileService } from "../../Service/service";
import { useEffect } from "react";
import { useContext } from "react";
import UserProfileContext from "../../context/UserContext";
import moment from "moment/moment";
import { RotatingLines } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
const MyProfile = () => {
  const location = useLocation();

  const { getUserDetailsFunction, activeUserData } =
    useContext(UserProfileContext);

  const [profilePic, setProfilePic] = useState(null);
  //   const [userData, setuserData] = useState();
  const [f_Name, setF_Name] = useState("");
  const [l_Name, setL_Name] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [team, setTeam] = useState("");
  const [invitedBy, setinvitedBy] = useState("");
  const [email, setEmail] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [userPic, setuserPic] = useState(null);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    if (e.target.name === "profilePic") {
      const selectedFile = e.target.files[0];
      if (selectedFile && !isFileFormatValid(selectedFile)) {
        Swal.fire(
          "Invalid file format!",
          "Please upload png, jpg and jpeg files only",
          "error"
        );
        return;
      }

      setProfilePic(selectedFile);
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        setPreviewImage(null);
      }
    } else if (e.target.name === "f_name") {
      //   const newValue = e.target.value;
      // Use a regular expression to check for spaces and remove them
      //   const sanitizedValue = newValue.replace(/\s/g, "");
      setF_Name(e.target.value);
    } else if (e.target.name === "team") {
      setTeam(e.target.value);
    } else if (e.target.name === "jobtitle") {
      setJobTitle(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    }
  };

  const isFileFormatValid = (file) => {
    const validFormats = [".png", ".jpg", ".jpeg", ".svg"];
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    return validFormats.includes(fileExtension.toLowerCase());
  };
  const editProfileHandler = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    setLoading(true);
    let errors = {};

    if (f_Name === "" || f_Name === undefined) {
      errors.firstName = true;
    }
    if (jobTitle === "" || jobTitle === undefined) {
      errors.jobTitle = true;
    }
    if (team === "" || team === undefined) {
      errors.team = true;
    }
    if (email === "" || email === undefined) {
      errors.email = true;
    }
    if (!emailRegex.test(email)) {
      errors.personaEmail = true;
    }
    if (Object.keys(errors).length !== 0) {
      setLoading(false);
      setError(errors);
      console.log(errors);
    } else {
      editUserProfile();
    }
  };
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const editUserProfile = async () => {
    setError({});
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    const formData = new FormData();
    formData.append("name", f_Name || activeUserData?.name);
    formData.append("email", email || activeUserData?.email);
    formData.append("jobTitle", jobTitle);
    formData.append("team", team);
    formData.append(
      "userId",
      location?.state?.userId ? location?.state?.userId : parsedToken?.data?._id
    );
    formData.append("image", profilePic);

    try {
      const response = await UpdateUserProfileService(formData);

      if (response.success) {
        setError({});

        setLoading(false);
        Swal.fire({
          icon: "success",
          title: response.message,
        });
        localStorage.setItem("active_user", JSON.stringify(response.data));
        if (!location?.state?.userId) {
          const res = await getUserDetailsFunction(parsedToken?.data?._id);
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: response.message,
        });
      }
    } catch (error) {
      setLoading(false);
      // Handle fetch error
      Swal.fire({
        icon: "error",
        title: error,
      });
    }
  };
  useEffect(() => {
    // console.log(
    //   "🚀 ~ file: MyProfile.js:17 ~ MyProfile ~ location:",
    //   location?.state?.userId
    // );
    const token = localStorage.getItem("auth_token");
    const parsedToken = JSON.parse(token);
    async function getUserData() {
      setLoading(true);
      try {
        const res = await getUserDetailsFunction(
          location?.state && location?.state?.userId
            ? location?.state?.userId
            : parsedToken?.data?._id,
          location?.state && location?.state?.userId ? true : false
        );
        if (res.success) {
          setF_Name(
            res.data.name == undefined || !res.data.name ? "" : res.data.name
          );
          setEmail(
            res.data.email == undefined || !res.data.email ? "" : res.data.email
          );
          setJobTitle(
            res.data.jobTitle == undefined || !res.data.jobTitle
              ? ""
              : res.data.jobTitle
          );
          setTeam(
            res.data.team == undefined || !res.data.team ? "" : res.data.team
          );
          setinvitedBy(res.data.invitedBy?res.data.invitedBy:"Brook Zimmatore")
          setuserPic(res.data.image);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(
          "🚀 ~ file: MyProfile.js:157 ~ getUserData ~ error:",
          error
        );
      }
    }
    getUserData();
  }, []);

  return (
    <div>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="py-3 d-flex flex-column ">
        <div className="d-flex align-items-center">
          <div>
            <label
              htmlFor="img-upload"
              className="w-100 h-100 d-flex cursor-pointer"
            >
              <input
                id="img-upload"
                type="file"
                name="profilePic"
                className="fileUpload-Custom"
                accept=".png,.jpg,.jpeg"
                // value={profilePic}
                onChange={handleInputChange}
              />
              {previewImage ? (
                <img
                  src={previewImage}
                  alt="preview"
                  style={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "100%",
                  }}
                />
              ) : location?.state?.userId ? (
                userPic != null ? (
                  <img
                    style={{
                      width: "55px",
                      height: "55px",
                      borderRadius: "100%",
                    }}
                    src={
                      BaseURL +
                      `/api/uploads/getUser/${location?.state?.userId}`
                    }
                    alt=""
                  />
                ) : (
                  <img
                    style={{
                      width: "55px",
                      height: "55px",
                      borderRadius: "100%",
                    }}
                    src={uploadPhoto}
                    alt=""
                  />
                )
              ) : activeUserData?.image != null ? (
                <img
                  style={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "100%",
                  }}
                  src={
                    BaseURL + `/api/uploads/getUser/${parsedToken?.data?._id}`
                  }
                  alt=""
                />
              ) : (
                <img
                  style={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "100%",
                  }}
                  src={uploadPhoto}
                  alt=""
                />
              )}
            </label>
          </div>
          <div className="ms-3">
            <h1 className="upload-heading">Upload your photo</h1>
            <div className="upload-desc">
              Photos help your teammates recognize you in emissary
            </div>
          </div>
        </div>
        <div className="form-container d-flex flex-column mt-3">
          <div className="d-flex">
            <div className="form-field  w-100">
              <label className="form-label" style={{ color: "black" }}>
                Name
              </label>
              <input
                type="text"
                className="form-control"
                name="f_name"
                placeholder="Your Name"
                defaultValue={
                  location?.state?.userId ? f_Name : activeUserData?.name
                }
                onChange={handleInputChange}
              />
              {error.firstName === true && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Please enter name
                </div>
              )}
            </div>
            <div className="form-field ms-3 w-100">
              <label className="form-label" style={{ color: "black" }}>
                Email Address
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Your email address"
                defaultValue={
                  location?.state?.userId ? email : activeUserData?.email
                }
                onChange={handleInputChange}
              />
              {error.personaEmail === true && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Please enter vaild email
                </div>
              )}
            </div>
          </div>
          <div className="d-flex">
            <div className="form-field w-100">
              <label className="form-label" style={{ color: "black" }}>
                Job Title
              </label>
              <input
                type="text"
                className="form-control"
                name="jobtitle"
                defaultValue={
                  location?.state?.userId ? jobTitle : activeUserData?.jobTitle
                }
                placeholder="Your Job Title"
                onChange={handleInputChange}
              />
              {error.jobTitle === true && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Please enter your job title
                </div>
              )}
            </div>
            <div className="form-field ms-3 w-100">
              <label className="form-label" style={{ color: "black" }}>
                Department or team
              </label>
              <input
                type="text"
                className="form-control"
                name="team"
                defaultValue={
                  location?.state?.userId ? team : activeUserData?.team
                }
                placeholder="Your Department or Team Name"
                onChange={handleInputChange}
              />
              {error.team === true && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Please enter your department or team name
                </div>
              )}
            </div>
          </div>
          <div className=" py-4 align-self-start">
            <div className="upload-desc">Invite type</div>
            <h1 className="upload-heading">
              Invited by {invitedBy} on{" "}
              {moment(activeUserData?.timestamp).format("ll")}
            </h1>
          </div>
          <Button
            alignSelf={"end"}
            w="fit-content"
            size="md"
            fontWeight={"500"}
            fontSize={"13px"}
            borderRadius={"5px"}
            bg="#21DC82"
            color="white"
            _hover={{ bg: "black" }}
            onClick={editProfileHandler}
            // isLoading={loading}
          >
            SAVE CHANGES
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
