import React, { useState, useEffect, useContext, useRef } from "react";
import "./chat.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import chatBot from "../../Images/emissary-icon.png";
import sendBtn from "../../Images/send.svg";
import trash from "../../Images/trash-2.svg";
import edit from "../../Images/edit-3.svg";
import NavBar from "../NavBar/NavBar";
import UserHeader from "../UserProfile/userHeader";
import emissaryHaeding from "../../Images/emissaryLogo.svg";
import emissaryLogo from "../../Images/chatLogo.png";

import LoadingSpinner from "../../Assests/loaderspinner.gif";
import brook from "../../Images/brook.png";
import { FiCheck } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import {
  CreatePersonaChatService,
  UpdateChatService,
  chatModalService,
  getSingleChatService,
  personaStyleService,
} from "../../Service/service";
import {
  Button,
  ChakraProvider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Image,
  Input,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PersonaChatContext from "../../context/ChatContext";
import PersonaMomentContext from "../../context/MomentsContext";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from "sweetalert2";
import { BsPlusCircleFill, BsChatLeft, BsChatDots } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import UserProfileContext from "../../context/UserContext";
import { FaUserCircle } from "react-icons/fa";
import { BaseURL } from "../../enviornment";
import { RotatingLines } from "react-loader-spinner";
import Feedback from "../feedback/Feedback";
const Chat = () => {
  const {
    getChatSessions,
    deleteSession,
    sessions,
    updateChatTitle,
    updateChatArray,
  } = useContext(PersonaChatContext);
  const [momentsList, setmomentsList] = useState([]);
  const { activeUserData } = useContext(UserProfileContext);

  const { getMoments, moments, isDarkMode } = useContext(PersonaMomentContext);
  const [activeButton, setActiveButton] = useState("allData"); // default active button
  const [chatInputText, setChatInputText] = useState("");
  const [chatTitle, setChatTitle] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [sessionId, setSessionId] = useState();
  const [momentType, setMomentType] = useState("all");
  const [previousMomentType, setPreviousMomentType] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [allSessions, setAllSessions] = useState([]);
  const [momentDropdown, setMomentDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [chatLoader, setchatLoader] = useState(false);
  const [isTypeWriterEffect, setisTypeWriterEffect] = useState(true);
  const [editableItem, setEditableItem] = useState(null);
  const [isShowDelete, setisShowDelete] = useState(false);
  const toast = useToast();
  const textareaRef = useRef(null);
  const contentRef = useRef(null);
  const bottomRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  useEffect(() => {
   const callPersonaStyle = async () => {
    const activePersona = JSON.parse(
      localStorage.getItem("active_persona_data")
    );
    const active_user = JSON.parse(localStorage.getItem("active_user"));
    const res = await personaStyleService(activePersona?.epClientEmail,active_user?.email || active_user?.epClientEmail)
   }
   callPersonaStyle()
  }, []);

  const handleEdit = (itemId) => {
    setEditableItem(itemId);
    setisShowDelete(false);
  };
  const handleDelete = (itemId) => {
    setEditableItem(itemId);
    setisShowDelete(true);
  };
  const handleCancelDelete = (event) => {
    event.stopPropagation();
    setEditableItem(null);
    setisShowDelete(false);
  };
  const handleCancelEdit = (event) => {
    event.stopPropagation();
    setEditableItem(null);
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setChatInputText(value);
  };

  const nav = useNavigate();
  const location = useLocation();
  useEffect(() => {
    resetTextareaSize();
    const activePersona = JSON.parse(
      localStorage.getItem("active_persona_data")
    );
    setCurrentEmail(activePersona?.epClientEmail);
    setMessages([]);
    setSessionId();
    setMomentType("all");
    setSelectedValue();
    nav("/chat");
  }, [updateChatArray]);

  const handleCopyClick = () => {
    toast({
      description: "Copied to clipboard",
      duration: 1500,
      isClosable: true,
      position: "top",
    });
  };

  const handleAllDataClick = async () => {
    setActiveButton("allData");
    setMomentDropdown(false);
    setSelectedValue("");
    setMomentType("all");
  };

  const handleSingleMomentClick = () => {
    setActiveButton("singleMoment");
    setMomentType("single");
    setMomentDropdown(true);
    const select = document.getElementById("select-moment");
    const defaultOptionIndex = select.selectedIndex;
    select.selectedIndex = defaultOptionIndex;
  };

  useEffect(() => {
    if (moments) {
      setmomentsList(moments);
    }
  }, [moments]);

  useEffect(() => {
    getMoments().then((res) => {});
  }, []);
  // getting chat sessions
  useEffect(() => {
    setIsLoading(true);
    const activePersona = JSON.parse(
      localStorage.getItem("active_persona_data")
    );
    getChatSessions(activePersona?._id).then((res) => {
      setAllSessions(res.data);
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    if (sessions) {
      setAllSessions(sessions);
    }
  }, [sessions]);

  useEffect(() => {
    // const personas = JSON.parse(localStorage.getItem("persona_list"));
    const activePersona = JSON.parse(
      localStorage.getItem("active_persona_data")
    );
    // const persona = personas.find((item) => item._id === activePersona?._id);
    setCurrentEmail(activePersona?.epClientEmail);
  }, []);
  useEffect(() => {
    if (location) {
      if (location?.state?.from == "newchat") {
        setMessages([]);
        setChatInputText("");
        setSessionId();
        setMomentType("all");
        setActiveButton("allData");
        setMomentDropdown(false);
        setSelectedValue("");
      }
      if (location?.state?.from == "chatsession") {
        getSingleChatService(location?.state?.id).then((res) => {
          if (res.success) {
            setMessages(res.data.messages);
            setSessionId(res.data._id);
            setChatInputText("");
          }
        });
      }
    }
  }, [location]);
  const currentPath = window.location.pathname;

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (messages && messages.length > 0) {
        updateChat();
      } else {
        createNewChat();
      }
    }
  };
  const resetTextareaSize = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "3rem";
    textarea.style.overflow = "hidden";
    setChatInputText("");
  };
  const TextAreaAutoResize = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "3rem";
    const scrollHeight = textarea.scrollHeight;

    if (scrollHeight > 220) {
      textarea.style.height = "220px"; // Set a max height of 212px
      textarea.style.overflow = "auto"; // Add scrollbar
    } else {
      textarea.style.height = `${scrollHeight}px`; // Adjust height based on content
      textarea.style.overflow = "hidden"; // Hide scrollbar if not needed
    }
  };

  // create new chat handler
  const createNewChat = async () => {
    resetTextareaSize();
    const active_user = JSON.parse(localStorage.getItem("active_user"));
    setisTypeWriterEffect(true);
    setMessages([...messages, { text: chatInputText, reply: null }]);
    try {
      setchatLoader(true);
      const minValue = selectedValue === "" ? null : selectedValue;
      const response = await chatModalService(
        minValue,
        active_user?.email || active_user?.epClientEmail,
        momentType,
        chatInputText,
        currentEmail,
        momentType !== previousMomentType ? true : false,
        true
      );
      // save chat api
      setPreviousMomentType(momentType);
      try {
        const saveRes = await CreatePersonaChatService(
          chatInputText,
          response?.response == null
            ? `${response?.title} is not mentioned in the context information.`
            : response?.response,
          response?.title
        );
        if (saveRes?.success) {
          setchatLoader(false);
          setMessages([...messages, saveRes?.data.messages[0]]);
          setSessionId(saveRes?.data._id);

          const res = await getChatSessions();
          // scrollToBottom();
        } else {
          setchatLoader(false);
          // setisSendReqMsg(true);
        }
      } catch (error) {
        setchatLoader(false);
      }
      // }
    } catch (error) {
      console.log("error", error);
      setchatLoader(false);
    }
  };
  // update chat handler
  const updateChat = async () => {
    resetTextareaSize();
    const active_user = JSON.parse(localStorage.getItem("active_user"));
    setisTypeWriterEffect(true);
    setMessages([...messages, { text: chatInputText, reply: null }]);
    try {
      setchatLoader(true);
      const minValue = selectedValue === "" ? null : selectedValue;
      const response = await chatModalService(
        minValue,
        active_user?.email || active_user?.epClientEmail,
        momentType,
        chatInputText,
        currentEmail,
        momentType !== previousMomentType ? true : false,
        false
      );
      setPreviousMomentType(momentType);
      // save chat api
      try {
        const saveRes = await UpdateChatService(
          sessionId,
          chatInputText,
          response?.response == null
            ? `It is not mentioned in the context information.`
            : response?.response
        );
        if (saveRes?.success) {
          setchatLoader(false);
          setSessionId(saveRes?.data._id);
          setMessages(saveRes?.data.messages);
          // scrollToBottom();
        } else {
          setchatLoader(false);
        }
      } catch (error) {
        setchatLoader(false);
      }
      // }
    } catch (error) {
      console.log("error", error);
      setchatLoader(false);
    }
  };

  const handleSelectChange = (event) => {
    const selectedOptionValue = event.target.value;
    setSelectedValue(selectedOptionValue);
    setMomentType("single");
    // initializeMoment(selectedOptionValue, "single");
  };
  const renderTextWithLineBreaks = (text) => {
    const lines = text.split("\n");
    return lines.map((line, index) => (
      <Text marginTop={"5px"} marginBottom={0} key={index}>
        {line}
        <br />
      </Text>
    ));
  };
  const UpdateChatNameHandler = async (chatId, title) => {
    const chattitle = chatTitle == "" ? title : chatTitle;
    const res = await updateChatTitle(chatId, chattitle);
    if (res.success) {
      setEditableItem(null);
    }
  };
  const activePersona = JSON.parse(localStorage.getItem("active_persona_data"));
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return (
    <ChakraProvider>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}

      <NavBar />
      <div
        ref={contentRef}
        className={
          isDarkMode ? "persona-container-dark" : "persona-container-light"
        }
      >
        <div className="chat-tab">
          <div className="userHeader justify-content-end hide-desktop">
            {/* user header */}
            {/* <UserHeader /> */}
            <div>
              <div class="dropdown ">
                <button
                  className="togglebtn1 dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="icon-more-vertical"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    {" "}
                    <Link
                      className="w-100 dropdown-item"
                      to={momentsList?.length > 0 ? "/mind" : "#"}
                      onClick={() =>
                        momentsList?.length > 0
                          ? () => {}
                          : Swal.fire({
                              icon: "error",
                              title: "Please first create a moment",
                            })
                      }
                    >
                      Mind
                    </Link>
                  </li>
                  <li>
                    <Link className="w-100 dropdown-item" to={"/chat"}>
                      Chat
                    </Link>
                  </li>
                  <li>
                    <Link className="w-100 dropdown-item" to="/moments">
                      Create a moment
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mainContainer">
            <div className="mainContainer-left show-on-desktop">
              <div>
                <Link
                  onClick={resetTextareaSize}
                  to={"/chat"}
                  state={{ from: "newchat" }}
                  className="btn btn-primary newChatBtn"
                >
                  <span>
                    <BsPlusCircleFill />
                  </span>{" "}
                  New Chat
                </Link>
                {/* Chat sessions */}
                <div className="chatHistory">
                  {allSessions?.map((chat, index) => (
                    <div
                      onClick={() => {
                        resetTextareaSize();
                        setPreviousMomentType("");
                        nav(`/chat/${chat._id}`, {
                          state: { from: "chatsession", id: chat?._id },
                        });
                      }}
                      className="chatHistory_item"
                    >
                      {editableItem === chat._id ? (
                        <div style={{ width: "100%" }}>
                          {isShowDelete ? (
                            <div className="chatItem-primary">
                              {/* <span>
                              <img
                                src={trash}
                                className="trash-img cursor-pointer"
                                alt=""
                              />
                            </span> */}
                              <div className="chat-title">
                                {chat?.title === ""
                                  ? chat?.messages[0].text
                                  : chat?.title}
                              </div>
                              <div className="chatHistory_buttons">
                                <span
                                  className="px-1"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    deleteSession(chat?._id).then((res) => {
                                      if (chat?._id === sessionId) {
                                        nav("/chat");
                                        setMessages([]);
                                        setChatInputText("");
                                        setSessionId();
                                      }
                                    });
                                  }}
                                >
                                  <FiCheck
                                    className="cursor-pointer tick"
                                    style={{ fontSize: "22px" }}
                                  />
                                </span>
                                <span
                                  onClick={handleCancelDelete}
                                  className="pe-1"
                                >
                                  <RxCross2
                                    className="cursor-pointer cross"
                                    style={{ fontSize: "22px" }}
                                  />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="chatItem-primary">
                              {/* <span>
                              <BsChatLeft />
                            </span> */}
                              <Input
                                className="edit-title"
                                onChange={(e) => setChatTitle(e.target.value)}
                                defaultValue={
                                  chat.title === ""
                                    ? chat?.messages[0].text.slice(0, 13)
                                    : chat?.title
                                }
                                placeholder="extra small size"
                                size="xs"
                              />
                              <div className="chatHistory_buttons">
                                <span
                                  className="px-1"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    UpdateChatNameHandler(
                                      chat?._id,
                                      chat.title === ""
                                        ? chat?.messages[0].text.slice(0, 13)
                                        : chat?.title
                                    );
                                  }}
                                >
                                  <FiCheck
                                    className="cursor-pointer tick"
                                    style={{ fontSize: "22px" }}
                                  />
                                </span>
                                <span
                                  onClick={handleCancelEdit}
                                  className="pe-1"
                                >
                                  <RxCross2
                                    className="cursor-pointer cross"
                                    style={{ fontSize: "22px" }}
                                  />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="chatItem-primary">
                          {/* <span>
                          <BsChatLeft />
                        </span> */}
                          <div className="chat-title">
                            {chat?.title === ""
                              ? chat?.messages[0].text
                              : chat?.title}
                          </div>
                          <div className="chatHistory_buttons">
                            {/* edit session button */}
                            <span
                              onClick={(event) => {
                                event.stopPropagation();
                                handleEdit(chat._id);
                              }}
                              className="px-1"
                            >
                              <img
                                src={edit}
                                alt=""
                                className="edit-img cursor-pointer"
                              />
                            </span>
                            {/* delete session button */}
                            <span
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDelete(chat?._id);
                              }}
                              className="px-1"
                            >
                              <img
                                src={trash}
                                className="trash-img cursor-pointer"
                                alt=""
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <Link
                className="personaNav-item"
                to={"/mind"}
                // onClick={() =>
                //   momentsList?.length > 0
                //     ? () => {}
                //     : Swal.fire({
                //         icon: "error",
                //         title: "Please first create a moment",
                //       })
                // }
              >
                {currentPath === "/mind" ? (
                  <span className="item mind-link  d-flex">
                    <i class="icon-mind me-2"></i> BACK TO MIND
                  </span>
                ) : (
                  <span className="item mind-link d-flex">
                    <i class="icon-mind me-2"></i> BACK TO MIND
                  </span>
                )}
              </Link>
            </div>
            <div className="mainContainer-right p-0">
              {/* chat section */}
              <div className="emissaryChat">
                {/* chat box */}
                <div className="emissaryChat-chatbox">
                  <div className="chatHeader">
                    <div className="chatHeader-inner">
                      <div className="d-flex justify-content-between w-100">
                        {/* toggle buttons and dropdown */}
                        <div className="test">
                          <div className="btn-group">
                            <div
                              className={`btn btn-outline-primary all-btn ${
                                activeButton === "allData" ? "active" : ""
                              }`}
                              onClick={handleAllDataClick}
                            >
                              All data
                            </div>
                            <div
                              className={`btn btn-outline-primary single-btn  ${
                                activeButton === "singleMoment" ? "active" : ""
                              }`}
                              onClick={handleSingleMomentClick}
                            >
                              Single Moment
                            </div>
                          </div>
                          <div className="mx-4 select-moment-dropdown">
                            <select
                              className="form-select form-select-lg chat-dropdown"
                              aria-label=".form-select-lg example"
                              id="select-moment"
                              value={selectedValue}
                              onChange={handleSelectChange}
                              disabled={!momentDropdown}
                            >
                              <option value="">Select Moment</option>

                              {moments?.map((item) => (
                                <option key={item._id} value={item.momentName}>
                                  {item.momentName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {/* drawer */}
                        {/* <div>
                          <IconButton
                            className="chat-drawer"
                            display={{ base: "flex", lg: "none" }}
                            onClick={onOpen}
                            ref={btnRef}
                            bg="black"
                            color="white"
                            _hover={{}}
                            aria-label="Call Segun"
                            size="md"
                            icon={<BsChatDots fontSize="20px" />}
                          />
                          <Drawer
                            isOpen={isOpen}
                            placement="right"
                            onClose={onClose}
                            size={'sm'}
                            finalFocusRef={btnRef}
                          >
                            <DrawerOverlay />
                            <DrawerContent>
                              <DrawerCloseButton />

                              <DrawerBody pt={12}>
                                <div className="">
                                  <Link
                                    to={"/chat"}
                                    state={{ from: "newchat" }}
                                    className="btn btn-primary newChatBtn"
                                  >
                                    <span>
                                      <BsPlusCircleFill />
                                    </span>{" "}
                                    New Chat
                                  </Link>
                                  <div className="chatHistory">
                                    {allSessions?.map((chat, index) => (
                                      <div
                                        onClick={() => {
                                          setPreviousMomentType("");
                                          nav(`/chat/${chat._id}`, {
                                            state: {
                                              from: "chatsession",
                                              id: chat?._id,
                                            },
                                          });
                                        }}
                                        className="chatHistory_item"
                                      >
                                        {editableItem === chat._id ? (
                                          <div>
                                            {isShowDelete ? (
                                              <div className="chatItem-primary">
                                                <span>
                                                  <img
                                                    src={trash}
                                                    className="trash-img cursor-pointer"
                                                    alt=""
                                                  />
                                                </span>
                                                <div className="chat-title-del">
                                                  {chat?.title === ""
                                                    ? chat?.messages[0].text
                                                    : chat?.title}
                                                </div>
                                                <div className="chatHistory_buttons">
                                                  <span
                                                    className="px-1"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      deleteSession(
                                                        chat?._id
                                                      ).then((res) => {
                                                        if (
                                                          chat?._id ===
                                                          sessionId
                                                        ) {
                                                          nav("/chat");
                                                          setMessages([]);
                                                          setChatInputText("");
                                                          setSessionId();
                                                        }
                                                      });
                                                    }}
                                                  >
                                                    <FiCheck
                                                      className="cursor-pointer tick"
                                                      style={{
                                                        fontSize: "22px",
                                                      }}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={handleCancelDelete}
                                                    className="pe-1"
                                                  >
                                                    <RxCross2
                                                      className="cursor-pointer cross"
                                                      style={{
                                                        fontSize: "22px",
                                                      }}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="chatItem-primary">
                                                <span>
                                                  <BsChatLeft />
                                                </span>
                                                <Input
                                                  className="edit-title"
                                                  onChange={(e) =>
                                                    setChatTitle(e.target.value)
                                                  }
                                                  defaultValue={
                                                    chat.title === ""
                                                      ? chat?.messages[0].text.slice(
                                                          0,
                                                          13
                                                        )
                                                      : chat?.title
                                                  }
                                                  placeholder="extra small size"
                                                  size="xs"
                                                />
                                                <div className="chatHistory_buttons">
                                                  <span
                                                    className="px-1"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      UpdateChatNameHandler(
                                                        chat?._id,
                                                        chat.title === ""
                                                          ? chat?.messages[0].text.slice(
                                                              0,
                                                              13
                                                            )
                                                          : chat?.title
                                                      );
                                                    }}
                                                  >
                                                    <FiCheck
                                                      className="cursor-pointer tick"
                                                      style={{
                                                        fontSize: "22px",
                                                      }}
                                                    />
                                                  </span>
                                                  <span
                                                    onClick={handleCancelEdit}
                                                    className="pe-1"
                                                  >
                                                    <RxCross2
                                                      className="cursor-pointer cross"
                                                      style={{
                                                        fontSize: "22px",
                                                      }}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div className="chatItem-primary">
                                            <span>
                                              <BsChatLeft />
                                            </span>
                                            <div className="chat-title">
                                              {chat?.title === ""
                                                ? chat?.messages[0].text
                                                : chat?.title}
                                            </div>
                                            <div className="chatHistory_buttons">
                                              <span
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  handleEdit(chat._id);
                                                }}
                                                className="px-1"
                                              >
                                                <img
                                                  src={edit}
                                                  alt=""
                                                  className="edit-img cursor-pointer"
                                                />
                                              </span>
                                              <span
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  handleDelete(chat?._id);
                                                }}
                                                className="px-1"
                                              >
                                                <img
                                                  src={trash}
                                                  className="trash-img cursor-pointer"
                                                  alt=""
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </DrawerBody>
                            </DrawerContent>
                          </Drawer>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* chat  messages section */}
                  <div ref={bottomRef} id="chat-rows" className="chat-rows">
                    {messages?.map((item, index) => (
                      <div key={index}>
                        <div className="chat-row">
                          <div className="chatResponseContainer">
                            {activeUserData?.image != null ? (
                              <ChakraProvider>
                                <Image
                                  marginRight={"1rem"}
                                  borderRadius="full"
                                  boxSize="2rem"
                                  src={`${BaseURL}/api/uploads/getUser/${
                                    parsedToken?.data?._id
                                  }?random=${Math.random()}`}
                                  alt="user-avatar"
                                />
                              </ChakraProvider>
                            ) : (
                              <FaUserCircle
                                style={{
                                  height: "2rem",
                                  width: "2rem",
                                  marginRight: "1rem",
                                }}
                                className="brook-img"
                              />
                            )}
                            {/* <img src={brook} className="avatar" alt="" /> */}
                            <div className="chat-question">
                              {renderTextWithLineBreaks(item.text)}
                            </div>
                          </div>
                        </div>
                        {item.reply === null ? (
                          <div
                            style={{
                              width: "100%",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {/* <img width={50} height={50} src={LoadingSpinner} /> */}
                            <RotatingLines
                              strokeColor="black"
                              strokeWidth="4"
                              animationDuration="0.85"
                              width="35"
                              visible={true}
                            />
                          </div>
                        ) : (
                          <div className="chat-row">
                            <div className="chatResponseContainer">
                              {activePersona?.image != null ? (
                                <img
                                  className="avatar"
                                  style={{ cursor: "default" }}
                                  src={`${BaseURL}/api/uploads/${
                                    activePersona?._id
                                  }?random=${Math.random()}`}
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="avatar"
                                  style={{ cursor: "default" }}
                                  src={emissaryLogo}
                                  alt=""
                                />
                              )}
                              {/* <img
                                src={emissaryLogo}
                                className="avatar"
                                style={{ cursor: "default" }}
                                alt=""
                              /> */}
                              <div className="chatResponse">
                                {renderTextWithLineBreaks(item.reply)}
                                {/* <Typewriter text={item.reply} /> */}
                              </div>{" "}
                            </div>
                            <div className="chat-row-action-buttons">
                              <CopyToClipboard
                                text={item.reply}
                                onCopy={handleCopyClick}
                              >
                                <i class="icon-copy"></i>
                              </CopyToClipboard>
                              <i class="icon-thumbs-up disabled"></i>
                              <i class="icon-thumbs-down disabled"></i>
                            </div>
                            <i class="icon-mind"></i>
                          </div>
                        )}
                      </div>
                    ))}
                    {activePersona?.moments === 0 && messages?.length == 0 && (
                      <div className="moment-section d-flex flex-column">
                        <img src={emissaryHaeding} alt="Emissary" />
                        <p className="py-4 mb-0">
                          A short description about creating a moment to start
                          chatting
                        </p>
                        <ChakraProvider>
                          <Button
                            onClick={() => nav("/moments")}
                            size="md"
                            fontWeight={"500"}
                            fontSize={"13px"}
                            type="submit"
                            borderRadius={"5px"}
                            bg="#21DC82"
                            w="fit-content"
                            color="white"
                            _hover={{ bg: "black" }}
                          >
                            CREATE A MOMENT
                          </Button>
                        </ChakraProvider>
                      </div>
                    )}
                  </div>
                  {/* input section */}
                  <div className="chat-input">
                    <div className="chatInput-inner">
                      <textarea
                        ref={textareaRef}
                        // className="custom-textarea"
                        className={`custom-textarea`}
                        value={chatInputText}
                        // style={{ overflowY: "auto" }}
                        // rows={rowsCount}
                        onChange={handleChange}
                        onKeyDown={handleKeyPress}
                        onInput={TextAreaAutoResize}
                        disabled={
                          isLoading ||
                          chatLoader ||
                          (momentType == "single" && selectedValue == "")
                        }
                        placeholder="Send a message"
                      />
                      {/* {chatLoader ? ( */}

                      {/* // ) : ( */}
                      <span
                        className="send-btn"
                        onClick={() => {
                          !chatInputText
                            ? console.log("")
                            : messages && messages.length > 0
                            ? updateChat()
                            : createNewChat();
                        }}
                      >
                        <div
                          className={
                            isLoading ||
                            chatLoader ||
                            (momentType == "single" && selectedValue == "") ||
                            !chatInputText
                              ? "disable-chat-button"
                              : "chat-send"
                          }
                        >
                          {/* {chatLoader ? (
                            <img
                              width={80}
                              height={80}
                              src={LoadingSpinner}
                              className="chat-input-loader"
                              alt=""
                            />
                          ) : ( */}
                          <img src={sendBtn} class="sendBtnImg" alt="" />
                          {/* )} */}
                        </div>
                      </span>
                      {/* // )} */}
                    </div>
                    <div className="d-flex flex-column align-items-end me-1">
                      <Feedback position={"not-fixed"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ChakraProvider>
  );
};

export default Chat;
