import { React, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import emissaryHaeding from "../../Images/emissaryLogo.svg";
import "./Login.css";
import Swal from "sweetalert2";
import { BaseURL } from "../../enviornment";
import { Spinner } from "react-bootstrap";
import {
  ChakraProvider,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Button as ChakraButton,
} from "@chakra-ui/react";
import { loginService } from "../../Service/service";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const Login = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [error, seterror] = useState({});
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [adminFormData, setAdminFormData] = useState({
    email: "",
    password: "",
    role: "Admin",
  });
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const signInUser = async () => {
    const errors = {};
    // console.log(formData);
    if (formData.name === "") {
      errors.name = true;
    }
    if (formData.password === "") {
      errors.pass = true;
    }
    if (Object.keys(errors).length !== 0) {
      seterror(errors);
      console.log(errors);
    } else {
      try {
        setLoader(true);
        const res = await loginService(formData);
        if (res.success) {
          // signup successful
          localStorage.setItem("auth_token", JSON.stringify(res));
          localStorage.setItem("active_user", JSON.stringify(res.data));
          seterror({});
          setFormData({
            // Reset the form fields
            email: "",
            password: "",
          });
          setLoader(false);
          // Swal.fire({
          //   icon: "success",
          //   title: res.message,
          // });
          // if(res.data.Personas === 1){
          // navigate("/mind");
          // }
          navigate("/persona");
        } else {
          setLoader(false);
          Swal.fire({
            icon: "error",
            title: res.message,
          });
        }
      } catch (error) {
        setLoader(false);
        console.error(error);
      }
    }
  };
  const handleKeyPressUser = (event) => {
    if (event.key === "Enter") {
      signInUser();
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <>
      <div className="signupContainer">
        <div className="signupContainer-inner">
          <div className="signupContainer-header">
            <img src={emissaryHaeding} alt="Emissary" />
          </div>
          <div>
            <div className="signupContainer-form">
              <form className="loginForm" id="SignInFrom">
                {/* <ChakraProvider>
                  <Stack alignItems={"center"}> */}
                <div>
                  {/* email */}
                  <div className="form-field">
                    <label className="form-label" htmlFor="form2Example11">
                      Email Address
                    </label>
                    <input
                      type="text"
                      id="form2Example11"
                      className="form-control"
                      name="email"
                      onChange={handleInputChange}
                      placeholder="Enter email"
                      required
                    />
                    {error.name === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please use valid email
                      </div>
                    )}
                  </div>
                  {/* password */}
                  <div className="form-field">
                    <label className="form-label" htmlFor="form2Example22">
                      Password
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="form2Example22"
                        className="form-control"
                        name="password"
                        placeholder="Enter password"
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPressUser}
                        required
                      ></input>
                      <span
                        style={{
                          position: "absolute  ",
                          cursor: "pointer",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    {error.pass === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please use valid password
                      </div>
                    )}
                  </div>
                  {/*login button  */}
                  <div className="form-field">
                    <ChakraProvider>
                      <ChakraButton
                        onClick={signInUser}
                        isLoading={loader}
                        size="md"
                        fontWeight={'500'}
                        fontSize={'13px'}
                        type="submit"
                        borderRadius={"5px"}
                        bg="#21DC82"
                        w='full'
                        color="white"
                        _hover={{ bg: "black" }}
                      >
                        LOGIN
                      </ChakraButton>
                    </ChakraProvider>
                  </div>
                  <p>
                    <span
                      onClick={() => navigate("/forgot-password")}
                      className="signup-link"
                    >
                      Forgot your password?
                    </span>
                  </p>
                  <p>
                    Don't have an account?{" "}
                    <span
                      onClick={() => navigate("/signup")}
                      className="signup-link"
                    >
                      Request Access
                    </span>
                  </p>
                </div>
                {/* <Tabs w={"100%"} isFitted>
                      <TabList>
                        <Tab
                          fontWeight={"500"}
                        >
                          User
                        </Tab>
                        <Tab
                          // _selected={{ bg: "black", color: "white" }}
                          fontWeight={"500"}
                        >
                          Admin
                        </Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel h={"50vh"}>
                          <div>
                            {/* email */}
                {/* <div className="form-field">
                              <label
                                className="form-label"
                                htmlFor="form2Example11"
                              >
                                Email
                              </label>
                              <input
                                type="text"
                                id="form2Example11"
                                className="form-control"
                                name="email"
                                onChange={handleInputChange}
                                placeholder="Enter email"
                                required
                              />
                              {error.name === true && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  Please use valid email
                                </div>
                              )}
                            </div> */}
                {/* password */}
                {/* <div className="form-field">
                              <label
                                className="form-label"
                                htmlFor="form2Example22"
                              >
                                Password
                              </label>
                              <input
                                type="password"
                                id="form2Example22"
                                className="form-control"
                                name="password"
                                placeholder="Enter password"
                                onChange={handleInputChange}
                                onKeyDown={handleKeyPressUser}
                                required
                              />
                              {error.pass === true && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  Please use valid password
                                </div>
                              )}
                            </div> */}
                {/*login button  */}
                {/* <div className="form-field">
                              <ChakraProvider>
                                <ChakraButton
                                  onClick={signInUser}
                                  isLoading={loader}
                                  size="lg"
                                  type="submit"
                                  className={"create-account-btn w-100"}
                                >
                                  Login
                                </ChakraButton>
                              </ChakraProvider>
                            </div> */}
                {/* <p>
                              Don't have an account?{" "}
                              <span
                                onClick={() => navigate("/signup")}
                                className="signup-link"
                              >
                                Sign up
                              </span>
                            </p> */}
                {/* </div> */}
                {/* </TabPanel> */}
                {/* <TabPanel h={"50vh"}>
                          <div>
                            <div className="form-field">
                              <label
                                className="form-label"
                                htmlFor="form2Example11"
                              >
                                Email
                              </label>
                              <input
                                type="text"
                                id="form2Example11"
                                className="form-control"
                                name="email"
                                onChange={handleInputChangeAdmin}
                                placeholder="Enter email"
                                required
                              />
                              {adminFieldsError.name === true && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  Please use valid email
                                </div>
                              )}
                            </div>
                            <div className="form-field">
                              <label
                                className="form-label"
                                htmlFor="form2Example22"
                              >
                                Password
                              </label>
                              <input
                                type="password"
                                id="form2Example22"
                                className="form-control"
                                name="password"
                                placeholder="Enter password"
                                onChange={handleInputChangeAdmin}
                                onKeyDown={handleKeyPressAdmin}
                                required
                              />
                              {adminFieldsError.pass === true && (
                                <div
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  Please use valid password
                                </div>
                              )}
                            </div>
                            <div className="form-field">
                              <ChakraProvider>
                                <ChakraButton
                                  onClick={signInAdmin}
                                  isLoading={loader}
                                  size="lg"
                                  type="submit"
                                  className={"create-account-btn w-100"}
                                >
                                  Login
                                </ChakraButton>
                              </ChakraProvider>
                            </div>
                          </div>
                        </TabPanel>
                      </TabPanels> */}
                {/* </Tabs>  */}
                {/* </Stack> */}
                {/* </ChakraProvider> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
