import { React, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import emissaryHaeding from "../../Images/emissaryLogo.svg";
import logo from "../../Images/logo-full.svg";
import "../signup/Register.css";
import Swal from "sweetalert2";
import { ChakraProvider, Button as ChakraButton } from "@chakra-ui/react";
import { joinEmissaryService, requestAccessService, signUpUserService } from "../../Service/service";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const JoinEmissaryComp = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const ParamsEmail = searchParams.get("email");
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: ParamsEmail,
    name: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    // name validation
    if (formData.name.length < 3) {
      validationErrors.name = "Username must be at least 3 characters long.";
    }

    // Email validation
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!formData.email || !formData.email.match(emailPattern)) {
    //   validationErrors.email = "Please enter a valid email address.";
    // }

    // Password validation
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,}$/;
    if (!formData.password || !formData.password.match(passwordPattern)) {
      validationErrors.password =
        "Password should be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, and 1 number. It should not include spaces.";
    }

    // Confirm password validation
    if (formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      // Handle successful form submission here
      try {
        setLoader(true);
        const res = await joinEmissaryService(formData,token);
        if (res.success) {
          // signup successful
          setFormData({
            // Reset the form fields
            email: "",
            password: "",
            confirmPassword: "",
            name: "",
          });
          setLoader(false);
          Swal.fire({
            icon: "success",
            title: res.message,
          });
          navigate("/");
        } else {
          setLoader(false);
          Swal.fire({
            icon: "error",
            title: res.message,
          });
        }
      } catch (error) {
        setLoader(false);
        console.error(error);
      }
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <>
      <div className="signupContainer">
        <div className="signupContainer-inner">
        <div className="signupContainer-header">
            <img src={emissaryHaeding} alt="Emissary" />
          </div>
          <div>
            <div className="signupContainer-form">
              <form
                className="loginForm"
                id="SignUpForm"
                onSubmit={handleSubmit}
              >
                <div>
                  {/* name */}
                  <div className="form-field">
                    <label className="form-label" htmlFor="form2Example11">
                      Username
                    </label>
                    <input
                      type="text"
                      id="form2Example11"
                      className="form-control"
                      name="name"
                      placeholder="Enter name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                    {errors.name && (
                      <span className="error">{errors.name}</span>
                    )}
                  </div>
                  {/* email */}
                  <div className="form-field">
                    <label className="form-label" htmlFor="form2Example11">
                      Email
                    </label>
                    <input
                      type="text"
                      id="form2Example11"
                      className="form-control"
                      name="email"
                      placeholder="Enter email"
                      defaultValue={ParamsEmail}
                      //   onChange={handleChange}
                      required
                      disabled
                    />
                    {/* {errors.email && (
                      <span className="error">{errors.email}</span>
                    )} */}
                  </div>
                  {/* password */}
                  <div className="form-field">
                    <label className="form-label" htmlFor="form2Example22">
                      Password
                    </label>
                    <div style={{ position: "relative" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      id="form2Example22"
                      className="form-control"
                      name="password"
                      placeholder="Enter password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                      <span
                        style={{
                          position: "absolute  ",
                          cursor: "pointer",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    
                    {errors.password && (
                      <span className="error">{errors.password}</span>
                    )}
                  </div>
                  {/* confirm password */}
                  <div className="form-field">
                    <label className="form-label" htmlFor="form2Example33">
                      Confirm Password
                    </label>
                   
                      <div style={{ position: "relative" }}>
                      <input
                      type={showConfirmPassword ? "text" : "password"}
                      id="form2Example33"
                      className="form-control"
                      name="confirmPassword"
                      placeholder="Confirm password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                    />
                      <span
                        style={{
                          position: "absolute  ",
                          cursor: "pointer",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    {errors.confirmPassword && (
                      <span className="error">{errors.confirmPassword}</span>
                    )}
                  </div>
                  {/* login button */}
                  <div className="form-field">
                    <ChakraProvider>
                      <ChakraButton
                        isLoading={loader}
                        size="md"
                        fontWeight={'500'}
                        fontSize={'13px'}
                        type="submit"
                        borderRadius={"5px"}
                        bg="#21DC82"
                        textTransform={'uppercase'}
                        w='full'
                        color="white"
                        _hover={{ bg: "black" }}
                        isDisabled={
                          !ParamsEmail ||
                          !formData.name ||
                          !formData.password ||
                          !formData.confirmPassword
                        }
                      >
                        Join Emissary
                      </ChakraButton>
                    </ChakraProvider>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinEmissaryComp;
