import {
  Button as ChakraButton,
  ChakraProvider,
  Img,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select as ChakraSelect,
  SkeletonCircle,
  Stack,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import React from "react";
import DataTable from "react-data-table-component";
import "./usertable.css";
import {
  addCollaboratorService,
  changeUserRoleService,
  changeUserStatusService,
  deleteUserService,
  getAllUsersService,
  getPersonaEmailsService,
  inviteUserService,
} from "../../../Service/service";
import search from "../../../Images/search.png";
import emailIcon from "../../../Images/email.png";
import { useState } from "react";
import { useEffect } from "react";
import { BiChevronDown } from "react-icons/bi";
import LoadingSpinner from "../../../Assests/loaderspinner.gif";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BaseURL } from "../../../enviornment";
import { FaUserCircle } from "react-icons/fa";
import Select from "react-select";
import { RotatingLines } from "react-loader-spinner";
const tableStyles = {
  headCells: {
    style: {
      fontWeight: "bold", // Example style property
      fontSize: "0.75rem",
      borderBottomColor: "red",
    },
    divider: {
      color: "red", // Change this color to your desired divider color
    },
  },
};

const UsersDataTable = ({ toView }) => {
  const loggedInUser = JSON.parse(localStorage.getItem("auth_token"));
  const [loading, setloading] = useState(false);
  const nav = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenChangeRole,
    onOpen: onOpenChangeRole,
    onClose: onCloseChangeRole,
  } = useDisclosure();
  const [TargetUserId, setTargetUserId] = useState();
  const [email, setemail] = useState();
  const [userRole, setUserRole] = useState();
  const [targetuserStatus, setTargetUserStatus] = useState();
  const [userData, setUserData] = useState([]);
  const [tempuserData, setTempUserData] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [isRenderer, setisRenderer] = useState(false);

  // invite user functionalities
  const [selectedUserEmail, setSelectedUserEmail] = useState();
  const [personaEmailsList, setPersonaEmailsList] = useState({});
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [error, setErrors] = useState({});
  const [value, setValue] = React.useState();
  const [userType, setUserType] = useState("existing");
  const [selectedPersonaEmailIds, setSelectedPersonaEmailIds] = useState([]);

  const handleSelectChange = (selectedOptions) => {
    // Extract the IDs from the selected options
    const selectedIds = selectedOptions.map((option) => option.value);
    setSelectedPersonaEmailIds(selectedIds);
  };

  const handleInputChangePersonas = (inputValue, actionMeta) => {
    // Check if the action type is "remove-value" (when the cross icon is clicked)
    if (actionMeta.action === "remove-value") {
      const removedId = actionMeta.removedValue.value;
      setSelectedPersonaEmailIds((prevSelectedPersonaEmailIds) =>
        prevSelectedPersonaEmailIds.filter((id) => id !== removedId)
      );
    }
  };

  const formatDataForReactSelect = (data) => {
    const options = [];

    for (const userEmail in data) {
      const personas = data[userEmail];
      const userOption = {
        label: userEmail,
        value: userEmail,
        isDisabled: true, // Disable the user email as an option
      };

      const personaOptions = personas.map((persona) => ({
        label: persona.PersonaEmail,
        value: persona.PersonaId,
      }));

      options.push(userOption, ...personaOptions);
    }

    return options;
  };

  const addCollaboratorHandler = async () => {
    let errors = {};
    if (userType === "existing" && email === undefined) {
      errors.userEmailError = true;
    }
    if (userType === "new" && !selectedUserEmail) {
      errors.userEmailError = true;
    }
    if (userType === "new" && value === undefined) {
      errors.roleError = true;
    }
    if (
      userType === "new" &&
      value === "Collaborator" &&
      selectedPersonaEmailIds.length == 0
    ) {
      errors.personaEmailError = true;
    }
    if (userType === "existing" && selectedPersonaEmailIds.length == 0) {
      errors.personaEmailError = true;
    }

    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      console.log(errors);
    } else {
      setErrors({});
      try {
        setloading(true);
        const res = await addCollaboratorService(
          userType == "existing" ? email?.value : selectedUserEmail,
          selectedPersonaEmailIds,
          userType == "new" ? true : false,
          userType == "new" ? value : null
        );
        if (res.success) {
          setemail();
          setSelectedUserEmail("");
          setUserType("existing");
          setValue();
          setSelectedPersonaEmailIds([]);
          setErrors({});
          onClose();
          setloading(false);
          // if (userType == "new") {
          setisRenderer(!isRenderer);
          // }
          Swal.fire({
            icon: "success",
            title: res.message,
          });
        } else {
          setloading(false);
          Swal.fire({
            icon: "error",
            title: res.message,
          });
        }
      } catch (error) {
        setloading(false);
        console.log(
          "🚀 ~ file: UsersDataTable.js:262 ~ addCollaboratorHandler ~ error:",
          error
        );
      }
    }
  };

  const getPersonaEmailsHandler = async (email) => {
    const response = await getPersonaEmailsService(email);
    if (response.success) {
      setPersonaEmailsList(response.data);
    }
  };
  const getUsersDataHandler = async () => {
    const res = await getAllUsersService("All");
    const filteredUsers = res?.data?.filter(
      (user) => user.status !== "Pending"
    );
    const newOptions = filteredUsers?.map((user) => ({
      label: user.email,
      value: user.email,
    }));
    setOptions(newOptions);
  };

  const handleInputChange = (inputValue) => {
    const filtered = options.filter((option) =>
      option.label.includes(inputValue)
    );

    setFilteredOptions(filtered);
  };

  const handleChange = (selectedOption) => {
    setemail(selectedOption);
    getPersonaEmailsHandler(selectedOption?.value);
  };
  const formattedData = formatDataForReactSelect(personaEmailsList);
  useEffect(() => {
    getPersonaEmailsHandler();
    getUsersDataHandler();
  }, []);
  // table columns
  const columns = [
    {
      name: "NAME",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: "300px",
          }}
        >
          {row.image != null ? (
            <ChakraProvider>
              <Image
                borderRadius="full"
                boxSize="24px"
                marginRight={"10px"}
                src={`${BaseURL}/api/uploads/getUser/${
                  row.id
                }?random=${Math.random()}`}
                alt="user-avatar"
              />
            </ChakraProvider>
          ) : (
            <FaUserCircle
              style={{ height: "24px", width: "24px", marginRight: "10px" }}
              className="brook-img"
            />
          )}
          <div
            onClick={
              row.username && row.status !== "Pending"
                ? () => nav(`/user-profile`, { state: { userId: row.id } })
                : () => {}
            }
            style={{
              fontWeight: "600",
              cursor:
                row.username && row.status !== "Pending"
                  ? "pointer"
                  : "default",
            }}
          >
            {row.username ? (
              row.username
            ) : (
              <span style={{ color: "gray" }}>No username yet</span>
            )}
          </div>
        </div>
      ),
    },
    {
      name: "EMAIL",
      sortable: true,
      cell: (row) => (
        <div
          style={{
            fontWeight: "600",
            minWidth: "400px",
          }}
        >
          {row.email}
        </div>
      ),
    },
    {
      name: "ROLE",
      cell: (row) => (
        <div style={{ fontWeight: "600", minWidth: "300px" }}>
          <ChakraSelect
            isDisabled={
              (loggedInUser?.data?.role !== "SuperAdmin" &&
                row.role == "Admin") ||
              row.status == "Pending" ||
              row.role === "SuperAdmin"
                ? true
                : false
            }
            border={"1.7px solid black"}
            borderRadius={"md"}
            size="sm"
            cursor="pointer"
            fontWeight={"semibold"}
            onChange={(e) => {
              setUserRole(e.target.value);
              setTargetUserId(row.id);
              setShowModal3(true);
            }}
            defaultValue={row.role}
            w={"130px"}
          >
            <option style={{ width: "130px" }} value="Admin">
              Admin
            </option>
            <option style={{ width: "130px" }} value="User">
              Editor
            </option>
            <option style={{ width: "130px" }} value="Collaborator">
              Collaborator
            </option>
            {row.role === "SuperAdmin" && (
              <option style={{ width: "130px" }} value="SuperAdmin">
                SuperAdmin
              </option>
            )}
          </ChakraSelect>
        </div>
      ),
      // center:true
    },
    {
      name: "STATUS",
      cell: (row) => (
        <ChakraProvider>
          {row.role === "SuperAdmin" ? (
            <div style={{ fontWeight: "600" }}>{row.status}</div>
          ) : row.role == "Admin" && loggedInUser?.data?.role === "Admin" ? (
            <div style={{ fontWeight: "600" }}>{row.status}</div>
          ) : (
            <Menu isLazy>
              <MenuButton>
                <div
                  className="d-flex align-items-center"
                  style={{ fontWeight: "600" }}
                >
                  {row.status}
                  <BiChevronDown fontSize="1.2rem" />
                </div>
              </MenuButton>
              <MenuList fontSize={"md"}>
                {row.status !== "Pending" && (
                  <MenuItem
                    onClick={() => {
                      setTargetUserId(row.id);
                      setTargetUserStatus(row.status);
                      setShowModal4(true);
                    }}
                  >
                    {row.status == "Active"
                      ? row.role == "Admin"
                        ? "Deactivate Admin"
                        : "Deactivate User"
                      : row.role == "Admin"
                      ? "Activate Admin"
                      : "Activate User"}
                  </MenuItem>
                )}
                {row.status === "InActive" || row.status === "Pending" ? (
                  <MenuItem
                    isDisabled={
                      loggedInUser?.data?.role == "SuperAdmin"
                        ? false
                        : loggedInUser?.data?.role == "Admin" &&
                          row.role == "User"
                        ? false
                        : true
                    }
                    onClick={() => {
                      setTargetUserId(row.id);
                      openModal2();
                    }}
                  >
                    Delete user details
                  </MenuItem>
                ) : null}
                <MenuItem isDisabled={true}>
                  Reset Two Factor Authentification
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </ChakraProvider>
      ),
    },
    {
      name: "PERSONAS",
      cell: (row) => <div style={{ fontWeight: "600" }}>{row.personas}</div>,
    },
    {
      name: "INVITED BY",
      cell: (row) => (
        <div style={{ fontWeight: "600", minWidth: "300px" }}>
          {row.invitedBy}
        </div>
      ),
    },
    {
      name: "JOINED",
      cell: (row) => (
        <div style={{ fontWeight: "600" }}>
          {moment(row.joined).format("LL")}
        </div>
      ),
    },
    {
      name: "LAST ACTIVE",
      cell: (row) => (
        <div style={{ fontWeight: "600" }}>
          {row.lastActive
            ? moment(row.lastActive).format("LL")
            : moment(row.joined).format("LL")}
        </div>
      ),
    },
    // {
    //   name: "ACTIONS",
    //   center: true,
    //   style: {
    //     zIndex: "999",
    //   },
    //   cell: (row) => (
    //     <div style={{ fontWeight: "600", fontSize: "20px" }}>

    //     </div>
    //   ),
    // },
  ];
  const openModal2 = () => {
    setShowModal2(true);
  };

  const closeModal2 = () => {
    setShowModal2(false);
  };
  const getData = async () => {
    try {
      setloading(true);
      const res = await getAllUsersService(
        // "All"
        toView == "users" ? "User" : toView == "admins" ? "Admin" : "All"
      );
      if (res.success) {
        setUserData(
          res.data.map((apiEntry) => ({
            id: apiEntry._id,
            email: apiEntry.email,
            role: apiEntry.role,
            username: apiEntry.name,
            status: apiEntry.status,
            personas: "All Access",
            joined: apiEntry.timestamp,
            lastActive: apiEntry.lastActive,
            invitedBy: apiEntry.invitedBy
              ? apiEntry.invitedBy
              : "Brook Zimmatore",
            image: apiEntry.image,
          }))
        );
        setTempUserData(
          res.data.map((apiEntry) => ({
            id: apiEntry._id,
            email: apiEntry.email,
            role: apiEntry.role,
            username: apiEntry.name,
            status: apiEntry.status,
            personas: "All Access",
            joined: apiEntry.timestamp,
            lastActive: apiEntry.lastActive,
            invitedBy: apiEntry.invitedBy
              ? apiEntry.invitedBy
              : "Brook Zimmatore",
            image: apiEntry.image,
          }))
        );
        setloading(false);
      } else {
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      console.log("🚀 ~ file: UsersDataTable.js:219 ~ getData ~ error:", error);
    }
  };
  const deleteUserHandler = async () => {
    try {
      setloading(true);
      const res = await deleteUserService(TargetUserId);
      if (res.success) {
        setisRenderer(!isRenderer);
        setloading(false);
        closeModal2();
        Swal.fire({
          icon: "success",
          title: res.message,
        });
      } else {
        setloading(false);
        Swal.fire({
          icon: "error",
          title: res.message,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const changeUserRoleHandler = async () => {
    try {
      setloading(true);
      const res = await changeUserRoleService(TargetUserId, userRole);
      if (res.success) {
        onCloseChangeRole();
        setShowModal3(false);
        setUserRole();
        setTargetUserId();
        // setisRenderer(!isRenderer);
        const updatedUserData = await userData.map((user) => {
          if (user.id === TargetUserId) {
            // Update the user's role
            return { ...user, role: userRole };
          }
          return user;
        });

        // Update the state with the modified user data
        setUserData(updatedUserData);
        setloading(false);
        Swal.fire({
          icon: "success",
          title: res.message,
        });
      } else {
        setloading(false);
        Swal.fire({
          icon: "error",
          title: res.message,
        });
      }
    } catch (error) {
      setloading(false);
      console.log(
        "🚀 ~ file: UsersDataTable.js:262 ~ inviteUserHandler ~ error:",
        error
      );
    }
  };
  const changeUserStatusHandler = async () => {
    try {
      const currentUserStatus = targetuserStatus;
      setloading(true);
      const res = await changeUserStatusService(
        TargetUserId,
        targetuserStatus == "Active" ? false : true
      );
      if (res.success) {
        setShowModal4(false);
        setTargetUserStatus();
        setTargetUserId();
        // setisRenderer(!isRenderer);
        const updatedUserData = userData.map((user) => {
          if (user.id === TargetUserId) {
            // Update the user's role
            return {
              ...user,
              status: currentUserStatus == "Active" ? "InActive" : "Active",
            };
          }
          return user;
        });

        // Update the state with the modified user data
        setTempUserData(updatedUserData);
        setloading(false);
        Swal.fire({
          icon: "success",
          title: res.message,
        });
      } else {
        setloading(false);
        Swal.fire({
          icon: "error",
          title: res.message,
        });
      }
    } catch (error) {
      setloading(false);
      console.log(
        "🚀 ~ file: UsersDataTable.js:262 ~ inviteUserHandler ~ error:",
        error
      );
    }
  };
  useEffect(() => {
    getData();
  }, [isRenderer]);
  const formatDate = (timestamp) => {
    return moment(timestamp).format("LL"); // Adjust the format as needed
  };
  const searchUserTableHandler = (searchText) => {
    const updatedArray = userData.filter((user) => {
      if (
        user.email?.toLowerCase().includes(searchText?.toLowerCase()) ||
        user.role?.toLowerCase().includes(searchText?.toLowerCase()) ||
        user.username?.toLowerCase().includes(searchText?.toLowerCase()) ||
        user.status?.toLowerCase().includes(searchText?.toLowerCase()) ||
        user.personas?.toLowerCase().includes(searchText?.toLowerCase()) ||
        formatDate(user.joined)
          ?.toLowerCase()
          .includes(searchText?.toLowerCase()) ||
        user.lastActive?.toLowerCase().includes(searchText?.toLowerCase()) ||
        user.invitedBy?.toLowerCase().includes(searchText?.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });

    setTempUserData(updatedArray);
  };

  return (
    <div className="table-container">
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <ChakraProvider>
        <Stack mb={6} direction="row" justifyContent={"space-between"}>
          <div
            style={{ border: "none" }}
            className="align-items-center d-flex gap-3 search-input px-2"
          >
            <span>
              <img
                style={{ width: "17px", height: "17px" }}
                src={search}
                className="search-img"
                alt=""
              />
            </span>
            <input
              style={{ width: "250px" }}
              type="text"
              // className="border-0"
              placeholder="Search by user name or email"
              aria-label="search"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                if (e.target.value.length > 0) {
                  searchUserTableHandler(e.target.value);
                } else {
                  setTempUserData(userData);
                }
              }}
            />
          </div>
          <ChakraButton
            onClick={onOpen}
            borderRadius={"5px"}
            fontSize={"sm"}
            size="md"
            bg="#21DC82"
            color="white"
            _hover={{ bg: "black" }}
          >
            INVITE MEMBER
          </ChakraButton>
        </Stack>

        <DataTable
          responsive
          //   fixedHeader
          //   fixedHeaderScrollHeight="500px"
          columns={columns}
          data={tempuserData}
          customStyles={tableStyles}
          pagination
        />
        {/* invite user modal */}

        <ChakraProvider>
          <ChakraModal
            isCentered
            isOpen={isOpen}
            onClose={() => {
              setemail();
              setSelectedUserEmail("");
              setUserType("existing");
              setValue();
              setSelectedPersonaEmailIds([]);
              setErrors({});
              onClose();
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                alignItems={"center"}
                fontSize={"xl"}
                fontWeight={"bold"}
              >
                Invite user to Emissary
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack pb={4} spacing={6}>
                  {/* user type */}
                  <div>
                    <RadioGroup
                      size={"md"}
                      colorScheme="blackAlpha"
                      onChange={(user) => {
                        if (user == "new") {
                          if (value !== "Collaborator") {
                            setErrors({ ...error, personaEmailError: false });
                          }
                        } else {
                          setErrors({ ...error, roleError: false });
                        }
                        setUserType(user);
                      }}
                      value={userType}
                    >
                      <Stack spacing={6} direction="row">
                        <Radio value="existing">Existing User</Radio>
                        <Radio value="new">New User</Radio>
                      </Stack>
                    </RadioGroup>
                  </div>
                  {/* user email */}
                  <div>
                    <div
                      className="d-flex align-items-center mb-2"
                      style={{ color: "black" }}
                    >
                      <span>
                        <Img src={emailIcon} />
                      </span>{" "}
                      <span style={{ paddingLeft: "10px" }}>
                        Invite with email
                      </span>
                    </div>
                    {userType == "existing" ? (
                      <>
                        <Select
                          styles={{ border: "1.5px solid black" }}
                          options={options}
                          value={email}
                          onChange={handleChange}
                          onInputChange={handleInputChange}
                          isSearchable={true}
                          placeholder="Search and select an email..."
                        />
                        {error.userEmailError === true && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            This field is required
                          </div>
                        )}
                      </>
                    ) : (
                      <div>
                        <div>
                          <input
                            type="email"
                            className="form-control"
                            name="comapnyName"
                            style={{
                              height: "38px",
                              border: "1.5px solid #1D1E24",
                            }}
                            placeholder="Enter user email"
                            value={selectedUserEmail}
                            onChange={(e) =>
                              setSelectedUserEmail(e.target.value)
                            }
                          />
                          {error.userEmailError === true && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              This field is required
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* userrole */}
                  {userType == "new" && (
                    <>
                      <RadioGroup
                        size={"md"}
                        colorScheme="blackAlpha"
                        onChange={setValue}
                        value={value}
                      >
                        <Stack spacing={6} direction="row">
                          <Radio value="Admin">Admin</Radio>
                          <Radio value="User">Editor</Radio>
                          <Radio value="Collaborator">Collaborator</Radio>
                        </Stack>
                      </RadioGroup>
                      {error.roleError === true && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          This field is required
                        </div>
                      )}
                    </>
                  )}
                  {/* persona emails */}
                  <div className="">
                    <label className="form-label" style={{ color: "black" }}>
                      Invite specific personas{" "}
                      {userType == "existing"
                        ? null
                        : value !== "Collaborator"
                        ? "(optional)"
                        : null}
                    </label>
                    <Select
                      styles={{ cursor: "pointer" }}
                      isMulti={true}
                      value={formattedData.filter((option) =>
                        selectedPersonaEmailIds.includes(option.value)
                      )}
                      onChange={handleSelectChange}
                      onInputChange={handleInputChangePersonas}
                      options={formattedData}
                    />
                    {error.personaEmailError === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        This field is required
                      </div>
                    )}
                  </div>
                  <ChakraButton
                    onClick={addCollaboratorHandler}
                    alignSelf={"end"}
                    borderRadius={"5px"}
                    fontSize={"sm"}
                    size="md"
                    bg="#21DC82"
                    color="white"
                    // isDisabled={isDisabledButton}
                    _hover={{ bg: "black" }}
                  >
                    SEND INVITE
                  </ChakraButton>
                </Stack>
              </ModalBody>
            </ModalContent>
          </ChakraModal>
        </ChakraProvider>
        {/* confirm delete popup */}
        <div>
          <Modal show={showModal2} onHide={closeModal2} centered>
            <Modal.Header style={{ border: "none" }} closeButton>
              {/* <Modal.Title>Are you sure you want to delete?</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <h6 style={{ paddingLeft: "20px", fontSize: "1.3rem" }}>
                Are you sure you want to delete?
              </h6>
            </Modal.Body>
            <Modal.Footer style={{ border: "none" }}>
              <Button variant="secondary" onClick={closeModal2}>
                No
              </Button>
              <Button variant="danger" onClick={deleteUserHandler}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* confirm change role popup */}
        <div>
          <Modal show={showModal3} onHide={() => setShowModal3(false)} centered>
            <Modal.Header style={{ border: "none" }} closeButton>
              {/* <Modal.Title>Are you sure you want to delete?</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <h6 style={{ paddingLeft: "20px", fontSize: "1.3rem" }}>
                Are you sure you want to change user's role?
              </h6>
            </Modal.Body>
            <Modal.Footer style={{ border: "none" }}>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowModal3(false);
                }}
              >
                No
              </Button>
              <Button variant="danger" onClick={changeUserRoleHandler}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* confirm user status change popup */}
        <div>
          <Modal show={showModal4} onHide={() => setShowModal4(false)} centered>
            <Modal.Header style={{ border: "none" }} closeButton>
              {/* <Modal.Title>Are you sure you want to delete?</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <h6 style={{ paddingLeft: "20px", fontSize: "1.3rem" }}>
                Are you sure you want to change user's status?
              </h6>
            </Modal.Body>
            <Modal.Footer style={{ border: "none" }}>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowModal4(false);
                }}
              >
                No
              </Button>
              <Button variant="danger" onClick={changeUserStatusHandler}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </ChakraProvider>
    </div>
  );
};

export default UsersDataTable;
