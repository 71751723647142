import React, { useState, useRef, useEffect, useContext } from "react";
import AllPersonas from "../AllPersonas/AllPersonas";
import NavBar from "../NavBar/NavBar";
import "./Persona.css";
import modalUpload from "../../Images/modal-upload.png";
import upload from "../../Images/upload.svg";
import uploadPhoto from "../../Images/uploadPhoto.svg";
import Swal from "sweetalert2";
import Footer from "../Footer/Footer";
import { BaseURL } from "../../enviornment";
import LoadingSpinner from "../../Assests/loaderspinner.gif";
import PersonaMomentContext from "../../context/MomentsContext";
import { useLocation, useNavigate } from "react-router";
import { Button, Divider } from "@chakra-ui/react";
import { getSingleUserService } from "../../Service/service";
import UserProfileContext from "../../context/UserContext";
import { RotatingLines } from "react-loader-spinner";
import Feedback from "../feedback/Feedback";
const Persona = () => {
  const location = useLocation();
  const path = location?.pathname;
  const navigate = useNavigate();
  const { isDarkMode } = useContext(PersonaMomentContext);
  const { getUserDetailsFunction } = useContext(UserProfileContext);
  const [profilePic, setProfilePic] = useState(null);
  const [f_Name, setF_Name] = useState("");
  const [l_Name, setL_Name] = useState("");
  const [comapnyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [documentName, setDocumentName] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState({});
  const buttonRef = useRef(null);
  const [updatePersona, setUpdatePersona] = useState(false);
  const [showUser, setshowUser] = useState(true);
  const [loading, setLoading] = useState(false);
  const [switchMyPersonas, setSwitchMyPersonas] = useState(false);
  // console.log("🚀 ~ file: Persona.js:37 ~ switchMyPersonas:", switchMyPersonas)

  const active_user = JSON.parse(localStorage.getItem("active_user"));
  const handleInputChange = (e) => {
    if (e.target.name === "profilePic") {
      const selectedFile = e.target.files[0];
      if (selectedFile && !isFileFormatValid(selectedFile)) {
        Swal.fire(
          "Invalid file format!",
          "Please upload png, jpg and jpeg files only",
          "error"
        );
        return;
      }

      setProfilePic(selectedFile);
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        setPreviewImage(null);
      }
    } else if (e.target.name === "f_name") {
      const newValue = e.target.value;
      // Use a regular expression to check for spaces and remove them
      const sanitizedValue = newValue.replace(/\s/g, "");
      setF_Name(sanitizedValue);
    } else if (e.target.name === "l_name") {
      const newValue = e.target.value;
      // Use a regular expression to check for spaces and remove them
      const sanitizedValue = newValue.replace(/\s/g, "");
      setL_Name(sanitizedValue);
    } else if (e.target.name === "comapnyName") {
      setCompanyName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "documentName") {
      setDocumentName(e.target.files[0]);
      // console.log(e.target.files[0]);
    }
  };

  const handleCloseModal = () => {
    // Reset the file input when closing the modal
    setF_Name("");
    setL_Name("");
    setCompanyName("");
    setEmail("")
    setProfilePic(null);
    setPreviewImage(null)
  };

  const isFileFormatValid = (file) => {
    const validFormats = [".png", ".jpg", ".jpeg", ".svg"];
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    return validFormats.includes(fileExtension.toLowerCase());
  };
  const createNewPersona = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    setLoading(true);
    let errors = {};

    if (f_Name === "") {
      errors.firstName = true;
    }
    if (l_Name === "") {
      errors.lastName = true;
    }
    if (comapnyName === "") {
      errors.companyName = true;
    }
    // if (email === "") {
    //   errors.personaEmail = true;
    // }
    if (!emailRegex.test(email)) {
      errors.personaEmail = true;
    }
    if (Object.keys(errors).length !== 0) {
      setLoading(false);
      setError(errors);
      console.log(errors);
    } else {
      createPersona();
    }
  };

  const createPersona = async () => {
    setError({});
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    const formData = new FormData();
    formData.append("firstName", f_Name);
    formData.append("lastName", l_Name);
    formData.append("epClientEmail", email);
    formData.append("epClientCompany", comapnyName);
    formData.append("userId", active_user?._id);
    formData.append("image", profilePic);

    try {
      const response = await fetch(`${BaseURL}/api/personas/createpersona`, {
        method: "POST",
        headers: parsedToken,
        body: formData,
      });
      let json = await response.json();
      // console.log("🚀 ~ file: Persona.js:135 ~ createPersona ~ json:", json);

      if (json.success) {
        setError({});
        setF_Name("");
        setL_Name("");
        setCompanyName("");
        setEmail("");
        setLoading(false);
        buttonRef.current.click();
        setUpdatePersona(!updatePersona);
        setProfilePic(null);
        setPreviewImage(null);
        Swal.fire({
          icon: "success",
          title: json.message,
        });
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: json.message,
        });
      }
    } catch (error) {
      setLoading(false);
      // Handle fetch error
      Swal.fire({
        icon: "error",
        title: error,
      });
    }
  };
  const childRef = useRef();

  const handleButtonClick = () => {
    if (childRef.current) {
      childRef.current.fetchAdmins();
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    const parsedToken = JSON.parse(token);
    async function getUserData() {
      // setLoading(true);
      try {
        const res = await getUserDetailsFunction(parsedToken?.data?._id);
        setLoading(false)
      } catch (error) {
        setLoading(false)
      console.log("🚀 ~ file: MyProfile.js:157 ~ getUserData ~ error:", error)
      }
    }
    getUserData()
  }, []);
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  return (
    <>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width='50'
              visible={true}
            />
          </div>
        </div>
      )}
      <div
        className={
          isDarkMode ? "persona-container-dark" : "persona-container-light"
        }
      >
        <NavBar />
        <div className="persona-container">
          <Feedback/>
          <div className="pageHeader">
            <h3
              className={
                isDarkMode ? "persona-heading-dark" : "persona-heading-light"
              }
            >
              Personas
              {/* {parsedToken?.data?.role === "SuperAdmin" ||
              parsedToken?.data?.role === "Admin"
                ? path == "/mypersonas"
                  ? "My Personas"
                  : "All Personas"
                : "Personas"} */}
              {/* {
              (parsedToken?.data?.role === "SuperAdmin" ||
                parsedToken?.data?.role === "Admin") &&
              showUser === true
                ? path !== "/admins"
                  ? "Users"
                  : "Admins"
                : "Personas"
                } */}
            </h3>
            {/* {(parsedToken?.data?.role === "SuperAdmin" ||
              parsedToken?.data?.role === "Admin") &&
              switchMyPersonas == true ? (
              <Button
                className="d-flex justify-content-center"
                data-bs-toggle="modal"
                data-bs-target="#newPersonaModal"
                role="button"
                size="md"
                fontWeight={"500"}
                fontSize={"13px"}
                type="submit"
                borderRadius={"5px"}
                bg="#21DC82"
                w="fit-content"
                color="white"
                _hover={{ bg: "black" }}
              >
                CREATE PERSONA
              </Button>
            ) :  */}
            {parsedToken?.data?.role !== "Collaborator" ? (
              <Button
                className="d-flex justify-content-center"
                data-bs-toggle="modal"
                data-bs-target="#newPersonaModal"
                role="button"
                size="md"
                fontWeight={"500"}
                fontSize={"13px"}
                type="submit"
                borderRadius={"5px"}
                bg="#21DC82"
                w="fit-content"
                color="white"
                _hover={{ bg: "black" }}
              >
                CREATE PERSONA
              </Button>
            ) : null}

            {/* {(parsedToken?.data?.role === "SuperAdmin" ||
              parsedToken?.data?.role === "Admin") &&
            path !== "/admins" &&
            showUser === true ? (
              <div className="d-flex justify-content-center">
                <div
                  className={
                    isDarkMode
                      ? "create-btn-container-dark py-2"
                      : "create-btn-container-light py-2"
                  }
                >
                  <div className="btn btn-primary" onClick={handleButtonClick}>
                    View Admins
                  </div>
                </div>
              </div>
            ) : path == "/admins" ? (
              <div className="d-flex justify-content-center">
                <div
                  className={
                    isDarkMode
                      ? "create-btn-container-dark py-2"
                      : "create-btn-container-light py-2"
                  }
                >
                  <div
                    className="btn btn-primary"
                    onClick={() => navigate("/persona")}
                  >
                    View Users
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="d-flex justify-content-center"
                data-bs-toggle="modal"
                data-bs-target="#newPersonaModal"
                role="button"
              >
                <div
                  className={
                    isDarkMode
                      ? "create-btn-container-dark py-2"
                      : "create-btn-container-light py-2"
                  }
                >
                  <div
                    className="btn btn-primary"
                    role="button"
                    // onClick={modalOpen}
                  >
                    Add Persona
                  </div>
                </div>
              </div>
            )} */}
          </div>{" "}
          <AllPersonas
            showUser={(val) => setshowUser(val)}
            updatePersona={updatePersona}
            // showAdmins ={()}
            ref={childRef}
            handleSwitchMyPersonas = {(value)=>setSwitchMyPersonas(value)}
          />
        </div>
        <div
          className="modal fade"
          id="newPersonaModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="newPersonaModal"
        >
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: isDarkMode ? "black" : "#fff" }}
            >
              <div className="modal-header">
                {/* <h5
                  style={{ color: isDarkMode ? "white" : "black" }}
                  className="modal-title h4 modal-heading"
                  id="exampleModalFullscreenLabel"
                >
                  Create a Persona
                </h5> */}
                <button
                  onClick={handleCloseModal}
                  type="button"
                  ref={buttonRef}
                  className="btn-close modal-close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="align-items-center create-persona-container modal-body d-flex flex-column ">
                 <div className=" d-flex flex-column inner-wrapper">
                <h1 className="mt-5" style={{fontWeight:'bold'}}>Create a Persona</h1>
                <Divider/>
                <div className="d-flex  upload-image-container align-items-center">
                  <div>
                    <label
                      htmlFor="img-upload"
                      className="w-100 h-100 d-flex cursor-pointer"
                    >
                      <input
                        id="img-upload"
                        type="file"
                        name="profilePic"
                        className="fileUpload-Custom"
                        accept=".png,.jpg,.jpeg"
                        // value={profilePic}
                        onChange={handleInputChange}
                      />
                      {previewImage ? (
                        <img
                          src={previewImage}
                          alt="preview"
                          style={{
                            width: "65px",
                            height: "65px",
                            borderRadius: "100%",
                          }}
                        />
                      ) : (
                        <img
                          style={{
                            width: "65px",
                            height: "65px",
                            borderRadius: "100%",
                          }}
                          src={uploadPhoto}
                          alt=""
                        />
                      )}
                    </label>
                  </div>
                  <div className="ms-3">
                    <h1 className="upload-heading">Upload your photo</h1>
                    <div className="upload-desc">
                      Photos help your teammates recognize you in emissary
                    </div>
                  </div>
                </div>
                <div className="personaForm">
                  <div className="form-field">
                    <label className="form-label" style={{ color: "black",fontWeight:'400' }}>
                      First Name
                    </label>
                    <input
                      type="text"
                      className="create-persona-input"
                      name="f_name"
                      value={f_Name}
                      onChange={handleInputChange}
                    />
                    {error.firstName === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please enter first name
                      </div>
                    )}
                  </div>
                  <div className="form-field">
                    <label className="form-label" style={{ color: "black",fontWeight:'400' }}>
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="create-persona-input"
                      name="l_name"
                      value={l_Name}
                      onChange={handleInputChange}
                    />
                    {error.lastName === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please enter last name
                      </div>
                    )}
                  </div>
                  <div className="form-field">
                    <label className="form-label" style={{ color: "black",fontWeight:'400' }}>
                      Company Name
                    </label>
                    <input
                      type="text"
                      className="create-persona-input"
                      name="comapnyName"
                      value={comapnyName}
                      onChange={handleInputChange}
                    />
                    {error.companyName === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please enter company name
                      </div>
                    )}
                  </div>
                  <div className="form-field">
                    <label className="form-label" style={{ color: "black",fontWeight:'400' }}>
                      Email
                    </label>
                    <input
                      type="email"
                      className="create-persona-input"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                    />
                    {error.personaEmail === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please enter vaild email
                      </div>
                    )}
                  </div>
                </div>
                <Button
                    className="close-btn align-self-center mt-5"
                    size="md"
                    fontWeight={"500"}
                    fontSize={"13px"}
                    type="submit"
                    borderRadius={"5px"}
                    bg="#21DC82"
                    w='fit-content'
                    color="white"
                    _hover={{ bg: "black" }}
                    onClick={createNewPersona}
                    // isLoading={loading}
                  >
                    CREATE PERSONA
                  </Button>
               </div>

              </div>
              {/* 
              <div className="modal-header">
                <h5
                  style={{ color: isDarkMode ? "white" : "black" }}
                  className="modal-title h4 modal-heading"
                  id="exampleModalFullscreenLabel"
                >
                  Create a Persona
                </h5>
                <button
                  onClick={handleCloseModal}
                  type="button"
                  ref={buttonRef}
                  className="btn-close modal-close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="align-items-center d-flex flex-column modal-body">
                <div className="d-flex justify-content-center flex-column">
                  <div>
                    <label
                      htmlFor="img-upload"
                      className="w-100 h-100 d-flex cursor-pointer"
                    >
                      <input
                        id="img-upload"
                        type="file"
                        name="profilePic"
                        className="fileUpload-Custom"
                        accept=".png,.jpg,.jpeg"
                        // value={profilePic}
                        onChange={handleInputChange}
                      />
                      {previewImage ? (
                        <img
                          src={previewImage}
                          alt="preview"
                          style={{ width: "140px", height: "140px" }}
                        />
                      ) : (
                        <img src={modalUpload} alt="" />
                      )}
                    </label>
                  </div>
                </div>
                <div className="personaForm">
                  <div className="form-field">
                    <label
                      className="form-label"
                      style={{ color: isDarkMode ? "white" : "black" }}
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="f_name"
                      value={f_Name}
                      onChange={handleInputChange}
                    />
                    {error.firstName === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please enter first name
                      </div>
                    )}
                  </div>
                  <div className="form-field">
                    <label
                      className="form-label"
                      style={{ color: isDarkMode ? "white" : "black" }}
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="l_name"
                      value={l_Name}
                      onChange={handleInputChange}
                    />
                    {error.lastName === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please enter last name
                      </div>
                    )}
                  </div>
                  <div className="form-field">
                    <label
                      className="form-label"
                      style={{ color: isDarkMode ? "white" : "black" }}
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="comapnyName"
                      value={comapnyName}
                      onChange={handleInputChange}
                    />
                    {error.companyName === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please enter company name
                      </div>
                    )}
                  </div>
                  <div className="form-field">
                    <label
                      className="form-label"
                      style={{ color: isDarkMode ? "white" : "black" }}
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                    />
                    {error.personaEmail === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please enter vaild email
                      </div>
                    )}
                  </div>
                  {/* <div className="form-field full-width">
                    <label
                      className="form-label"
                      style={{ color: isDarkMode ? "white" : "black" }}
                    >
                      Upload Document
                    </label>
                    <div
                      className="personaUpload-document"
                      style={{ pointerEvents: "none" }}
                    >
                      <label
                        htmlFor="file-upload"
                        className="w-100 h-100 d-flex flex-column justify-content-center align-items-center cursor-pointer"
                      >
                        <img src={upload} alt="" />
                        <input
                          id="file-upload"
                          type="file"
                          name="documentName"
                          // value={documentName}
                          onChange={handleInputChange}
                        />
                        <div className="modal-bottom-text">
                          Drop Files here or <u>Select File</u>
                        </div>
                      </label>
                    </div>
                  </div> */}
                  {/* <Button
                    className="close-btn full-width mt-5"
                    size="md"
                    fontWeight={"500"}
                    fontSize={"13px"}
                    type="submit"
                    borderRadius={"5px"}
                    bg="#21DC82"
                    w="100%"
                    color="white"
                    _hover={{ bg: "black" }}
                    onClick={createNewPersona}
                  >
                    CREATE PERSONA
                  </Button> */}
                {/* </div> */}
              {/* </div>  */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Persona;
