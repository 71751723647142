import {
  Button,
  ChakraProvider,
  Divider,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import "../Persona/Persona.css";
import uploadPhoto from "../../Images/uploadPhoto.svg";
import LoadingSpinner from "../../Assests/loaderspinner.gif";
import Swal from "sweetalert2";
import pluscircle from "../../Images/pluscircle.png";
import { BaseURL } from "../../enviornment";
import { useNavigate } from "react-router-dom";
const CreatePersonaModal = () => {
  const nav = useNavigate()
  const [profilePic, setProfilePic] = useState(null);
  const [f_Name, setF_Name] = useState("");
  const [l_Name, setL_Name] = useState("");
  const [comapnyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [documentName, setDocumentName] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const active_user = JSON.parse(localStorage.getItem("active_user"));
  const handleInputChange = (e) => {
    if (e.target.name === "profilePic") {
      const selectedFile = e.target.files[0];
      if (selectedFile && !isFileFormatValid(selectedFile)) {
        Swal.fire(
          "Invalid file format!",
          "Please upload png, jpg and jpeg files only",
          "error"
        );
        return;
      }

      setProfilePic(selectedFile);
      if (selectedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        setPreviewImage(null);
      }
    } else if (e.target.name === "f_name") {
      const newValue = e.target.value;
      // Use a regular expression to check for spaces and remove them
      const sanitizedValue = newValue.replace(/\s/g, "");
      setF_Name(sanitizedValue);
    } else if (e.target.name === "l_name") {
      const newValue = e.target.value;
      // Use a regular expression to check for spaces and remove them
      const sanitizedValue = newValue.replace(/\s/g, "");
      setL_Name(sanitizedValue);
    } else if (e.target.name === "comapnyName") {
      setCompanyName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "documentName") {
      setDocumentName(e.target.files[0]);
      // console.log(e.target.files[0]);
    }
  };

  const handleCloseModal = () => {
    // Reset the file input when closing the modal
    setProfilePic(null);
    setPreviewImage(null);
  };

  const isFileFormatValid = (file) => {
    const validFormats = [".png", ".jpg", ".jpeg", ".svg"];
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    return validFormats.includes(fileExtension.toLowerCase());
  };
  const createNewPersona = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    setLoading(true);
    let errors = {};

    if (f_Name === "") {
      errors.firstName = true;
    }
    if (l_Name === "") {
      errors.lastName = true;
    }
    if (comapnyName === "") {
      errors.companyName = true;
    }
    // if (email === "") {
    //   errors.personaEmail = true;
    // }
    if (!emailRegex.test(email)) {
      errors.personaEmail = true;
    }
    if (Object.keys(errors).length !== 0) {
      setLoading(false);
      setError(errors);
      // console.log(errors);
    } else {
      createPersona();
    }
  };

  const createPersona = async () => {
    setError({});
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    const formData = new FormData();
    formData.append("firstName", f_Name);
    formData.append("lastName", l_Name);
    formData.append("epClientEmail", email);
    formData.append("epClientCompany", comapnyName);
    formData.append("userId", parsedToken?.data?._id);
    formData.append("image", profilePic);

    try {
      const response = await fetch(`${BaseURL}/api/personas/createpersona`, {
        method: "POST",
        headers: parsedToken,
        body: formData,
      });
      let json = await response.json();
      // console.log("🚀 ~ file: Persona.js:135 ~ createPersona ~ json:", json);

      if (json.success) {
        setError({});
        setF_Name("");
        setL_Name("");
        setCompanyName("");
        setEmail("");
        setLoading(false);
        setProfilePic(null);
        setPreviewImage(null);
        onClose()
        nav('/persona')
        Swal.fire({
          icon: "success",
          title: json.message,
        });
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: json.message,
        });
      }
    } catch (error) {
      setLoading(false);
      // Handle fetch error
      Swal.fire({
        icon: "error",
        title: error,
      });
    }
  };
  return (
    <>
      {/* {loading && (
        <div className="overlay" style={{zIndex:'9999999'}}>
          <div className="loaderImage-container">
            <img width={80} height={80} src={LoadingSpinner} alt="" />
          </div>
        </div>
      )} */}
      <ChakraProvider>
        <Stack onClick={onOpen} direction={"row"}>
          <Img src={pluscircle} />
          <Text fontWeight="600">Create a persona</Text>
        </Stack>

        <Modal
          onClose={() => {
            setF_Name("");
            setL_Name("");
            setCompanyName("");
            setEmail("");
            setProfilePic(null);
            setPreviewImage(null);
            onClose();
          }}
          size={"full"}
          isOpen={isOpen}
        >
          <ModalOverlay />
          <ModalContent>
            {/* <ModalHeader>Create a Persona</ModalHeader> */}
            <ModalCloseButton />
            <ModalBody>
              <div className="align-items-center create-persona-container d-flex flex-column ">
                 <div className=" d-flex flex-column inner-wrapper">
                <h1 className="mt-5">Create a Persona</h1>
                <Divider/>
                <div className="d-flex  upload-image-container align-items-center">
                  <div>
                    <label
                      htmlFor="img-upload"
                      className="w-100 h-100 d-flex cursor-pointer"
                    >
                      <input
                        id="img-upload"
                        type="file"
                        name="profilePic"
                        className="fileUpload-Custom"
                        accept=".png,.jpg,.jpeg"
                        // value={profilePic}
                        onChange={handleInputChange}
                      />
                      {previewImage ? (
                        <img
                          src={previewImage}
                          alt="preview"
                          style={{
                            width: "65px",
                            height: "65px",
                            borderRadius: "100%",
                          }}
                        />
                      ) : (
                        <img
                          style={{
                            width: "65px",
                            height: "65px",
                            borderRadius: "100%",
                          }}
                          src={uploadPhoto}
                          alt=""
                        />
                      )}
                    </label>
                  </div>
                  <div className="ms-3">
                    <h1 className="upload-heading">Upload your photo</h1>
                    <div className="upload-desc">
                      Photos help your teammates recognize you in emissary
                    </div>
                  </div>
                </div>
                <div className="personaForm">
                  <div className="form-field">
                    <label className="form-label" style={{ color: "black",fontWeight:'400' }}>
                      First Name
                    </label>
                    <input
                      type="text"
                      className="create-persona-input"
                      name="f_name"
                      value={f_Name}
                      onChange={handleInputChange}
                    />
                    {error.firstName === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please enter first name
                      </div>
                    )}
                  </div>
                  <div className="form-field">
                    <label className="form-label" style={{ color: "black",fontWeight:'400' }}>
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="create-persona-input"
                      name="l_name"
                      value={l_Name}
                      onChange={handleInputChange}
                    />
                    {error.lastName === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please enter last name
                      </div>
                    )}
                  </div>
                  <div className="form-field">
                    <label className="form-label" style={{ color: "black",fontWeight:'400' }}>
                      Company Name
                    </label>
                    <input
                      type="text"
                      className="create-persona-input"
                      name="comapnyName"
                      value={comapnyName}
                      onChange={handleInputChange}
                    />
                    {error.companyName === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please enter company name
                      </div>
                    )}
                  </div>
                  <div className="form-field">
                    <label className="form-label" style={{ color: "black",fontWeight:'400' }}>
                      Email
                    </label>
                    <input
                      type="email"
                      className="create-persona-input"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                    />
                    {error.personaEmail === true && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please enter vaild email
                      </div>
                    )}
                  </div>
                </div>
                <Button
                    className="close-btn align-self-center mt-5"
                    size="md"
                    fontWeight={"500"}
                    fontSize={"13px"}
                    type="submit"
                    borderRadius={"5px"}
                    bg="#21DC82"
                    w='fit-content'
                    color="white"
                    _hover={{ bg: "black" }}
                    onClick={createNewPersona}
                    isLoading={loading}
                  >
                    CREATE PERSONA
                  </Button>
               </div>

              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </>
  );
};

export default CreatePersonaModal;
