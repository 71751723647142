import React, { useState } from "react";
import {
  Flex,
  Text,
  Input,
  Button,
  Link,
  Stack,
  Img,
  useToast,
  ChakraProvider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import logo from "../../Images/logo-full.svg";
import emissaryHaeding from "../../Images/emissaryLogo.svg";
import "../SignIn/Login.css";
import { forgotPasswordService } from "../../Service/service";
import Swal from "sweetalert2";
const ForgotPassword = () => {
  const toast = useToast();
  const nav = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  const validateEmail = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address.");
    } else {
      setEmailError("");
    }
  };

    const handleSubmit = async event => {
      event.preventDefault();
      setSubmitClicked(true);
      validateEmail();

      if (!emailError) {
        // Handle successful form submission
        setloading(true);
        // Handle successful form submission
        const res = await forgotPasswordService(email);
        if (res.success) {
          setloading(false);
          Swal.fire({
            title: res.message,
            icon: 'success',
          });
          setEmail('');
        } else {
          setloading(false);
          Swal.fire({
            title: res.message,
            icon: 'error',
          });
        }
      } else {
        console.log('Form has errors. Please correct them.');
      }
    };

  return (
    <>
      <div className="signupContainer">
        <div className="signupContainer-inner">
        <div className="signupContainer-header">
            <img src={emissaryHaeding} alt="Emissary" />
          </div>
          <Stack spacing={1} pt={16} textAlign={'center'}>
              <Text fontWeight={'600'} fontSize={'xl'} color={'#0D1821'}>
                Forgot Password?
              </Text>
              {/* <Text>
                Enter the Email address associated with your account and we’ll
                send you a link to reset your password
              </Text> */}
            </Stack>
          <div>
            <div className="signupContainer-form">
              <form className="loginForm" id="SignInFrom">
                {/* <ChakraProvider>
                  <Stack alignItems={"center"}> */}
                <div>
                  {/* email */}
                  <div className="form-field">
                    <label className="form-label" htmlFor="form2Example11">
                      Email
                    </label>
                    <input
                      type="text"
                      id="form2Example11"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Enter email"
                      required
                    />
                    {emailError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {emailError}
                      </div>
                    )}
                  </div>
                  {/*login button  */}
                  <div className="form-field">
                    <ChakraProvider>
                      <Button
                        onClick={handleSubmit}
                        isLoading={loading}
                        size="md"
                        fontWeight={'500'}
                        fontSize={'13px'}
                        type="submit"
                        borderRadius={"5px"}
                        bg="#21DC82"
                        w='full'
                        color="white"
                        _hover={{ bg: "black" }}
                        isDisabled={!email || loading}
                      >
                        SUBMIT
                      </Button>
                    </ChakraProvider>
                  </div>
                  <p>
                    <span onClick={() => nav("/")} className="signup-link">
                      Back to Login
                    </span>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
