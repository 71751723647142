import React, { useContext } from "react";
import NavBar from "../NavBar/NavBar";
import SideNav from "../sideNav/sideNav";
import UserHeader from "../UserProfile/userHeader";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import GlobalRules from "./ClientProfileTabs/GlobalRules";
import PersonalDetail from "./ClientProfileTabs/PersonalDetail";
import CompanyDetails from "./ClientProfileTabs/CompanyDetails";
import PersonaTab from "./ClientProfileTabs/PersonaTab";
import Footer from "../Footer/Footer";
import "./ClientProfile.css";
import PersonaMomentContext from "../../context/MomentsContext";
import { Link } from "react-router-dom";
function ClientProfile() {
  const { isDarkMode } = useContext(PersonaMomentContext);
  return (
    <>
      <NavBar />
      <div
        className={
          isDarkMode ? "persona-container-dark" : "persona-container-light"
        }
      >
        <div className="editProfile">
          <div className="userHeader hide-desktop">
            {/* user header */}
            {/* <UserHeader /> */}
            <div>
              {/* <Link className="btn btn-primary" to="/moments">
                Create a moment
              </Link>{" "} */}
              <SideNav />
            </div>
          </div>
          <div className="mainContainer">
            <div className="mainContainer-left">
              {" "}
              {/* chat sidebar */}
              <SideNav />
            </div>
            <div className="mainContainer-right">
              <div className="client-profile">
                <h1>Profile</h1>
                <Tabs
                  orientation="horizontal"
                  defaultIndex={1}
                  className="tab-names"
                >
                  <TabList borderBottom={"none"}>
                    <Tab
                      color={
                        isDarkMode ? "white !important" : "black !important"
                      }
                      isDisabled={true}
                    >
                      Global Rules
                    </Tab>
                    <Tab
                      color={
                        isDarkMode ? "white !important" : "black !important"
                      }
                    >
                      Personal Details
                    </Tab>
                    <Tab
                      color={
                        isDarkMode ? "white !important" : "black !important"
                      }
                    >
                      Company Details
                    </Tab>
                    <Tab
                    // isDisabled={true}
                      color={
                        isDarkMode ? "white !important" : "black !important"
                      }
                    >
                      Persona
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel className="px-0">
                      <GlobalRules />
                    </TabPanel>
                    <TabPanel className="px-0">
                      <PersonalDetail />
                    </TabPanel>
                    <TabPanel className="px-0">
                      <CompanyDetails />
                    </TabPanel>
                    <TabPanel className="px-0">
                      <PersonaTab />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default ClientProfile;
