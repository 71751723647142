import { React, useContext, useEffect, useState } from "react";
import HeaderLogo from "../../Images/emissaryIcon.svg";
import pluscircle from "../../Images/pluscircle.png";
import brook from "../../Images/brook.png";
import Swal from "sweetalert2";
import {
  Button as ChakraButton,
  ChakraProvider,
  Img,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure,
  Divider,
  Text,
  Image,
} from "@chakra-ui/react";
import emailIcon from "../../Images/email.png";
import "./NavBar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/button";
import { FiSun, FiSettings, FiUsers, FiLogOut } from "react-icons/fi";
import { RiMoonFill } from "react-icons/ri";
import PersonaMomentContext, {
  MomentsContext,
} from "../../context/MomentsContext";
import { FaUserCircle } from "react-icons/fa";
import LoadingSpinner from "../../Assests/loaderspinner.gif";
import { BsChevronDown } from "react-icons/bs";
import { AiOutlineEye, AiOutlineUser } from "react-icons/ai";
import {
  addCollaboratorService,
  getAllUsersService,
  getPersonaEmailsService,
  getSingleUserService,
} from "../../Service/service";
import { BaseURL } from "../../enviornment";
import CreatePersonaModal from "../Persona/CreatePersonaModal";
import UserProfileContext from "../../context/UserContext";
import PersonaChatContext from "../../context/ChatContext";
import Select from "react-select";
import { RotatingLines } from "react-loader-spinner";
const NavBar = () => {
  const { getUserDetailsFunction, activeUserData } =
    useContext(UserProfileContext);
  const location = useLocation();
  const path = location?.pathname;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setemail] = useState();
  const [loading, setloading] = useState(false);
  const [error, setErrors] = useState({});
  const [userType, setUserType] = useState("existing");
  const [value, setValue] = useState();
  const [activePersonaName, setActivePersonaName] = useState();
  const [selectedUserEmail, setSelectedUserEmail] = useState();
  const [personaEmailsList, setPersonaEmailsList] = useState({});
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [personaList, setPersonaList] = useState();
  const [selectedPersonaEmailIds, setSelectedPersonaEmailIds] = useState([]);
  // const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(localStorage.getItem("auth_token"));
  const { getMoments, themeToggler, isDarkMode } =
    useContext(PersonaMomentContext);
  const { getChatSessions, emptyChatArray, updateChatArray } =
    useContext(PersonaChatContext);
  const navigate = useNavigate();
  const dropdownPaths = [
    "/persona",
    "/mypersonas",
    "/user-profile",
    "/updatemoment",
    "/analyze-moment",
  ];

  const handleSelectChange = (selectedOptions) => {
    // Extract the IDs from the selected options
    const selectedIds = selectedOptions.map((option) => option.value);
    setSelectedPersonaEmailIds(selectedIds);
  };

  const handleInputChangePersonas = (inputValue, actionMeta) => {
    // Check if the action type is "remove-value" (when the cross icon is clicked)
    if (actionMeta.action === "remove-value") {
      const removedId = actionMeta.removedValue.value;
      setSelectedPersonaEmailIds((prevSelectedPersonaEmailIds) =>
        prevSelectedPersonaEmailIds.filter((id) => id !== removedId)
      );
    }
  };

  const formatDataForReactSelect = (data) => {
    const options = [];

    for (const userEmail in data) {
      const personas = data[userEmail];
      const userOption = {
        label: userEmail,
        value: userEmail,
        isDisabled: true, // Disable the user email as an option
      };

      const personaOptions = personas.map((persona) => ({
        label: persona.PersonaEmail,
        value: persona.PersonaId,
      }));

      options.push(userOption, ...personaOptions);
    }

    return options;
  };

  const addCollaboratorHandler = async () => {
    let errors = {};
    if (userType === "existing" && email === undefined) {
      errors.userEmailError = true;
    }
    if (userType === "new" && !selectedUserEmail) {
      errors.userEmailError = true;
    }
    if (userType === "new" && value === undefined) {
      errors.roleError = true;
    }
    if (
      userType === "new" &&
      value === "Collaborator" &&
      selectedPersonaEmailIds.length == 0
    ) {
      errors.personaEmailError = true;
    }
    if (userType === "existing" && selectedPersonaEmailIds.length == 0) {
      errors.personaEmailError = true;
    }

    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      console.log(errors);
    } else {
      setErrors({});
      try {
        setloading(true);
        const res = await addCollaboratorService(
          userType == "existing" ? email?.value : selectedUserEmail,
          selectedPersonaEmailIds,
          userType == "new" ? true : false,
          userType == "new" ? value : null
        );
        if (res.success) {
          setemail();
          setSelectedUserEmail("");
          setUserType("existing");
          setValue();
          setSelectedPersonaEmailIds([]);
          setErrors({});
          onClose();
          setloading(false);
          Swal.fire({
            icon: "success",
            title: res.message,
          });
        } else {
          setloading(false);
          Swal.fire({
            icon: "error",
            title: res.message,
          });
        }
      } catch (error) {
        setloading(false);
        console.log(
          "🚀 ~ file: UsersDataTable.js:262 ~ addCollaboratorHandler ~ error:",
          error
        );
      }
    }
  };

  const getPersonaEmailsHandler = async (email) => {
    const response = await getPersonaEmailsService(email);
    if (response.success) {
      setPersonaEmailsList(response.data);
    }
  };
  const getUsersDataHandler = async () => {
    const res = await getAllUsersService("All");
    setUsersData(res.data);
    const newOptions = res?.data?.map((user) => ({
      label: user.email,
      value: user.email,
    }));
    setOptions(newOptions);
  };

  const handleInputChange = (inputValue) => {
    const filtered = options.filter((option) =>
      option.label.includes(inputValue)
    );

    setFilteredOptions(filtered);
  };

  const handleChange = (selectedOption) => {
    setemail(selectedOption);
    getPersonaEmailsHandler(selectedOption?.value);
  };
  const formattedData = formatDataForReactSelect(personaEmailsList);

  useEffect(() => {
    const activePersona = JSON.parse(
      localStorage.getItem("active_persona_data")
    );
    setActivePersonaName(
      `${activePersona?.firstName} ${activePersona?.lastName}`
    );
    getPersonaEmailsHandler();
    getUsersDataHandler();
  }, []);
  useEffect(() => {
    const activePersona = JSON.parse(
      localStorage.getItem("active_persona_data")
    );
    setActivePersonaName(
      `${activePersona?.firstName} ${activePersona?.lastName}`
    );
  }, [updateChatArray]);

  // Check if the current pathname matches any of the paths in the array
  const shouldShowDropdown = dropdownPaths.includes(path);
  const showPersona = () => {
    navigate("/persona");
  };
  const signOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  const toggleTheme = () => {
    themeToggler();
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("auth_token");
      const parsedToken = await JSON.parse(token);
      const personaData = JSON.parse(localStorage.getItem("my_persona_list"));
      const userID = parsedToken?.data?._id;
      try {
        setloading(true);
        const response = await fetch(`${BaseURL}/api/personas/getAllPersonas`, {
          method: "POST",
          body: JSON.stringify({
            access: "Self",
            userId: userID,
          }),
          headers: {
            auth_token: parsedToken?.auth_token,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          // console.log(
          //   "🚀 ~ file: AllPersonas.js:242 ~ fetchData ~ data:",
          //   data
          // );
          // Process the received data
          setPersonaList(data.data);
          if (data.message == "My Personas") {
            localStorage.setItem(
              "my_persona_list",
              JSON.stringify(data.data.yourPersonas)
            );
          }
          setloading(false);
        } else {
          setloading(false);
          const errorData = await response.json();
          console.log(errorData.errors);
        }
      } catch (error) {
        console.error(error);
        setloading(false);
      }
    };
    // if(activeUserData) {
    fetchData();

    // }
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    const parsedToken = JSON.parse(token);
    async function getUserData() {
      setloading(true);
      try {
        const res = await getUserDetailsFunction(parsedToken?.data?._id);
        setloading(false);
      } catch (error) {
        setloading(false);
        console.log(
          "🚀 ~ file: MyProfile.js:157 ~ getUserData ~ error:",
          error
        );
      }
    }
    getUserData();
  }, []);
  const updateActivePersonaHandler = async (personaData, isMyPersona) => {
    if (
      !isMyPersona &&
      path === "/edit-persona" &&
      (parsedToken?.data?.role == "User" ||
        parsedToken?.data?.role == "Collaborator")
    ) {
      navigate("/mind");
    }
    setloading(true);
    localStorage.setItem("active_persona_data", JSON.stringify(personaData));
    setActivePersonaName(`${personaData?.firstName} ${personaData?.lastName}`);
    getChatSessions(personaData?._id);

    const res = await getMoments();
    if (res.success) {
      try {
        const res = await getSingleUserService(personaData?.userId);
        if (res.success) {
          const activeUserData = JSON.stringify(res.data);
          localStorage.setItem("active_user", activeUserData);
          emptyChatArray();
          setloading(false);
        }
      } catch (error) {
        setloading(false);

        console.log(
          "🚀 ~ file: AllPersonas.js:107 ~ showMoment ~ error:",
          error
        );
      }
    } else {
      setloading(false);
    }
  };
  return (
    <div className="mainHeader">
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      <div className="d-flex align-items-center">
        <Link to="/persona" className="me-4">
          <img
            src={HeaderLogo}
            alt="Logo"
            className="emissaryLogo"
            onClick={showPersona}
          ></img>
        </Link>
        {!shouldShowDropdown &&
          !path.startsWith("/persona") &&
          !path.startsWith("/admin") && (
            <ChakraProvider>
              <Menu size={"sm"}>
                <MenuButton
                  _active={{}}
                  color="white"
                  bg="none"
                  _hover={{}}
                  as={Button}
                  rightIcon={<BsChevronDown />}
                  fontWeight="400"
                >
                  {activePersonaName}
                  {/* {activePersona?.firstName} {activePersona?.lastName} */}
                </MenuButton>
                <MenuList border={"1.5px solid black"} borderRadius={"none"}>
                  <MenuItem fontSize={"sm"} isDisabled={true}>
                    Your Personas
                  </MenuItem>
                  <Stack spacing={0} maxH={"30vh"} overflowY={"auto"}>
                    {personaList?.yourPersonas?.map((persona) => (
                      <MenuItem
                        fontWeight={"400"}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          updateActivePersonaHandler(persona, true)
                        }
                      >
                        {persona?.firstName} {persona?.lastName}
                      </MenuItem>
                    ))}
                  </Stack>
                  <MenuItem fontSize={"sm"} isDisabled={true}>
                    Collaboration Personas
                  </MenuItem>
                  <Stack spacing={0} maxH={"30vh"} overflowY={"auto"}>
                    {personaList?.allowedPersonas?.map((persona) => (
                      <MenuItem
                        fontWeight={"400"}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          updateActivePersonaHandler(persona, false)
                        }
                      >
                        {persona?.firstName} {persona?.lastName}
                      </MenuItem>
                    ))}
                  </Stack>
                  {parsedToken?.data.role !== "Collaborator" && (
                    <MenuItem>
                      <Divider />
                    </MenuItem>
                  )}
                  {parsedToken?.data.role !== "Collaborator" && (
                    <MenuItem>
                      <CreatePersonaModal />
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </ChakraProvider>
          )}

        {/* {path !== "/client-profile" &&
          path !== "/persona" &&
          path !== "/user-profile" &&
          path !== "/mypersonas" &&
          !path.startsWith("/persona") &&
          !path.startsWith("/admin") && (
            <ChakraProvider>
              <span className="cursor-pointer">
                <AiOutlineEye
                  color="white"
                  onClick={() => navigate("/client-profile")}
                  fontSize={"20px"}
                />
              </span>
            </ChakraProvider>
          )} */}
      </div>

      <div className="d-flex align-items-center">
        <div className="hideToggle">
          {/* toggler button */}
          <div className="toggler ms-4">
            <input
              type="checkbox"
              class="checkbox"
              id="checkbox"
              onChange={toggleTheme}
            />
            <label for="checkbox" className="label">
              <FiSun className="fa-sun" />
              <RiMoonFill className="fa-moon" />
              <div className="ball" />
            </label>
          </div>
        </div>
        <div className="d-flex align-items-center">
          {/* <ul className="list-unstyled"> */}
          <div className="nav-item dropdown">
            <a
              className={
                isDarkMode
                  ? "header-username-light  dropdown-toggle right-name nav-dropdown"
                  : "header-username-dark dropdown-toggle right-name nav-dropdown"
              }
              href="/"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {/* <img
                src={activeUserData?.role === "SuperAdmin" ? brook : Unknown}
                className="brook-img"
                alt="Base"
              /> */}
              {activeUserData?.image != null ? (
                <ChakraProvider>
                  <Image
                    borderRadius="full"
                    boxSize="36px"
                    src={`${BaseURL}/api/uploads/getUser/${
                      parsedToken?.data?._id
                    }?random=${Math.random()}`}
                    alt="user-avatar"
                  />
                </ChakraProvider>
              ) : (
                <FaUserCircle
                  style={{ height: "36px", width: "36px" }}
                  className="brook-img"
                />
              )}
            </a>
            <ul
              className="dropdown-menu"
              style={{ width: "250px" }}
              aria-labelledby="navbarDropdown"
            >
              <li className="dropdown-item py-3 cursor-pointer">
                <div className="d-flex align-items-center">
                  {activeUserData?.image != null ? (
                    <Image
                      borderRadius="full"
                      boxSize="35px"
                      src={`${BaseURL}/api/uploads/getUser/${
                        parsedToken?.data?._id
                      }?random=${Math.random()}`}
                      alt="user-avatar"
                    />
                  ) : (
                    <FaUserCircle
                      style={{ height: "36px", width: "36px" }}
                      className="brook-img"
                    />
                  )}
                  <div className="user-name ps-2">{activeUserData?.name}</div>
                </div>
              </li>

              {(activeUserData?.role === "SuperAdmin" ||
                activeUserData?.role === "Admin") && (
                <li
                  className="dropdown-item  cursor-pointer"
                  onClick={() => navigate("/admin/general")}
                >
                  <div className="d-flex ps-2 align-items-center">
                    <FiSettings
                      style={{ width: "1.2rem", height: "1.2rem" }}
                      className="me-3"
                    />
                    <div style={{ fontWeight: "500" }}>Administration</div>
                  </div>
                </li>
              )}
              {(activeUserData?.role === "SuperAdmin" ||
                activeUserData?.role === "Admin") && (
                <li onClick={onOpen} className="dropdown-item  cursor-pointer ">
                  <div className="d-flex ps-2 align-items-center">
                    <FiUsers
                      style={{ width: "1.2rem", height: "1.2rem" }}
                      className="me-3"
                    />
                    <div style={{ fontWeight: "500" }}>Invite Members</div>
                  </div>
                </li>
              )}
              <li
                onClick={() => navigate("/user-profile")}
                className="dropdown-item"
              >
                <div className="d-flex ps-2 align-items-center">
                  <AiOutlineUser
                    style={{ width: "1.2rem", height: "1.2rem" }}
                    className="me-3"
                  />
                  <div style={{ fontWeight: "500" }}>My Profile</div>
                </div>
              </li>

              <li
                className="dropdown-item cursor-pointer mt-2 "
                onClick={signOut}
              >
                <div className="ps-2 d-flex align-items-center">
                  <FiLogOut
                    style={{ width: "1.2rem", height: "1.2rem" }}
                    className="me-3"
                  />
                  <div style={{ fontWeight: "500" }}>Sign out</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* invite user modal */}
      <ChakraProvider>
        <ChakraModal
          isCentered
          isOpen={isOpen}
          onClose={() => {
            setemail();
            setSelectedUserEmail("");
            setUserType("existing");
            setValue();
            setSelectedPersonaEmailIds([]);
            setErrors({});
            onClose();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              alignItems={"center"}
              fontSize={"xl"}
              fontWeight={"bold"}
            >
              Invite user to Emissary
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack pb={4} spacing={6}>
                {/* user type */}
                <div>
                  <RadioGroup
                    size={"md"}
                    colorScheme="blackAlpha"
                    onChange={(user) => {
                      if (user == "new") {
                        if (value !== "Collaborator") {
                          setErrors({ ...error, personaEmailError: false });
                        }
                      } else {
                        setErrors({ ...error, roleError: false });
                      }
                      // console.log(
                      //   "🚀 ~ file: NavBar.js:647 ~ NavBar ~ value:",
                      //   user
                      // );
                      setUserType(user);
                    }}
                    value={userType}
                  >
                    <Stack spacing={6} direction="row">
                      <Radio value="existing">Existing User</Radio>
                      <Radio value="new">New User</Radio>
                    </Stack>
                  </RadioGroup>
                </div>
                {/* user email */}
                <div>
                  <div
                    className="d-flex align-items-center mb-2"
                    style={{ color: "black" }}
                  >
                    <span>
                      <Img src={emailIcon} />
                    </span>{" "}
                    <span style={{ paddingLeft: "10px" }}>
                      Invite with email
                    </span>
                  </div>
                  {userType == "existing" ? (
                    <>
                      <Select
                        styles={{ border: "1.5px solid black" }}
                        options={options}
                        value={email}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        isSearchable={true}
                        placeholder="Search and select an email..."
                      />
                      {error.userEmailError === true && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          This field is required
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      <div>
                        <input
                          type="email"
                          className="form-control"
                          name="comapnyName"
                          style={{
                            height: "38px",
                            border: "1.5px solid #1D1E24",
                          }}
                          placeholder="Enter user email"
                          value={selectedUserEmail}
                          onChange={(e) => setSelectedUserEmail(e.target.value)}
                        />
                        {error.userEmailError === true && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            This field is required
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {/* userrole */}
                <div>
                  {userType == "new" && (
                    <>
                      <RadioGroup
                        size={"md"}
                        colorScheme="blackAlpha"
                        onChange={setValue}
                        value={value}
                      >
                        <Stack spacing={6} direction="row">
                          <Radio value="Admin">Admin</Radio>
                          <Radio value="User">Editor</Radio>
                          <Radio value="Collaborator">Collaborator</Radio>
                        </Stack>
                      </RadioGroup>
                      {error.roleError === true && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          This field is required
                        </div>
                      )}
                    </>
                  )}
                </div>
                {/* persona emails */}
                <div className="">
                  <label className="form-label" style={{ color: "black" }}>
                    Invite specific personas{" "}
                    {userType == "existing"
                      ? null
                      : value !== "Collaborator"
                      ? "(optional)"
                      : null}
                  </label>
                  <Select
                    styles={{ cursor: "pointer" }}
                    isMulti={true}
                    value={formattedData.filter((option) =>
                      selectedPersonaEmailIds.includes(option.value)
                    )}
                    onChange={handleSelectChange}
                    onInputChange={handleInputChangePersonas}
                    options={formattedData}
                  />
                  {error.personaEmailError === true && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      This field is required
                    </div>
                  )}
                </div>
                <ChakraButton
                  onClick={addCollaboratorHandler}
                  alignSelf={"end"}
                  borderRadius={"5px"}
                  fontSize={"sm"}
                  size="md"
                  bg="#21DC82"
                  color="white"
                  // isDisabled={isDisabledButton}
                  _hover={{ bg: "black" }}
                >
                  SEND INVITE
                </ChakraButton>
              </Stack>
            </ModalBody>
          </ModalContent>
        </ChakraModal>
      </ChakraProvider>
    </div>
  );
};

export default NavBar;
