import React, { useContext } from "react";
import NavBar from "../NavBar/NavBar";
import SideNav from "../sideNav/sideNav";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import "../ClientProfile/ClientProfile.css";
import "./UserProfile.css";
import PersonaMomentContext from "../../context/MomentsContext";
import MyProfile from "./MyProfile";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import Subscriptions from "./Subscriptions";
import Feedback from "../feedback/Feedback";
function UserProfile() {
  const location = useLocation();

  const { isDarkMode } = useContext(PersonaMomentContext);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous page
  };
  return (
    <>
      <Feedback/>

      <NavBar />
      <div
        className={
          isDarkMode ? "persona-container-dark" : "persona-container-light"
        }
      >
        <div className="editProfile">
          {/* <div className="userHeader hide-desktop">
            <div>
              <SideNav />
            </div>
          </div> */}
          <div className="mainContainer">
            {/* <div className="mainContainer-left">
              {" "}
              <SideNav />
            </div> */}
            <div className="userprofile-container">
              <div className="userprofile">
                <div className="d-flex justify-content-between align-items-center">
                  <h1>
                    {location?.state?.userId
                      ? "Update user profile"
                      : "Update your profile"}
                  </h1>
                  <AiOutlineClose
                    className="close-icon"
                    onClick={handleGoBack}
                  />
                </div>
                <Tabs
                  orientation="horizontal"
                  defaultIndex={0}
                  className="tab-names"
                >
                  <TabList borderBottom={"none"}>
                    <Tab
                      color={
                        isDarkMode ? "white !important" : "black !important"
                      }
                      fontWeight={"500"}
                    >
                      Profile
                    </Tab>
                    <Tab
                      fontWeight={"500"}
                      color={
                        isDarkMode ? "white !important" : "black !important"
                      }
                      isDisabled={true}
                    >
                      Notifications
                    </Tab>
                    <Tab
                      fontWeight={"500"}
                      // isDisabled={true}
                      color={
                        isDarkMode ? "white !important" : "black !important"
                      }
                    >
                      Subscriptions
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel className="px-0">
                      <MyProfile />
                    </TabPanel>
                    <TabPanel className="px-0"></TabPanel>
                    <TabPanel className="px-0"><Subscriptions/></TabPanel>
                  </TabPanels>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default UserProfile;
