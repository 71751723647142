import React from "react";
import SideNav from "../../sideNav/sideNav";
import NavBar from "../../NavBar/NavBar";
import UsersDataTable from "./UsersDataTable";
import { Text } from "@chakra-ui/react";
import AdminNav from "../../sideNav/AdminNav";
import { Link, useLocation } from "react-router-dom";
import MyTable from "./MyTable";
import Feedback from "../../feedback/Feedback";
const data = [
  {
    id: 1,
    name: "John Doe",
    avatar: "https://example.com/avatar1.png",
    email: "john@example.com",
    userRole: "Admin",
    status: "Active",
    personas: "Personas A, B",
    invitedBy: "Jane Smith",
    joinedDate: "2023-08-01",
    lastEdited: "2023-08-28",
  },
  {
    id: 2,
    name: "Jane Smith",
    avatar: "https://example.com/avatar2.png",
    email: "jane@example.com",
    userRole: "User",
    status: "Inactive",
    personas: "Personas B, C",
    invitedBy: "James Brown",
    joinedDate: "2023-07-15",
    lastEdited: "2023-08-25",
  },
  {
    id: 3,
    name: "Jane Smith",
    avatar: "https://example.com/avatar2.png",
    email: "jane@example.com",
    userRole: "User",
    status: "Inactive",
    personas: "Personas B, C",
    invitedBy: "James Brown",
    joinedDate: "2023-07-15",
    lastEdited: "2023-08-25",
  },
  {
    id: 4,
    name: "Jane Smith",
    avatar: "https://example.com/avatar2.png",
    email: "jane@example.com",
    userRole: "User",
    status: "Inactive",
    personas: "Personas B, C",
    invitedBy: "James Brown",
    joinedDate: "2023-07-15",
    lastEdited: "2023-08-25",
  },
  // Add more sample data...
];

const UsersTable = () => {
  const location = useLocation();
  return (
    <>
    <Feedback/>
      <NavBar />
      <div className={"persona-container-light"}>
        <div className="mainContainer">
          <div className="mainContainer-left">
            {" "}
            <AdminNav />
          </div>
          <div className="mainContainer-right">
            <div className="d-flex justify-content-between">
            <Text fontWeight={"bold"} fontSize={"1.4rem"}>
              User Management
            </Text>
            <div class="dropdown hide-desktop">
              <button
                className="togglebtn1 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="icon-more-vertical"></i>
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  {" "}
                  <Link
                    className="w-100 dropdown-item"
                    to={'/admin/general'}
                  >
                    General
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to={"/admin/customization"}>
                  Customization
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to="/admin/users">
                    Users
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to="/admin/security">
                    Security
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to="/admin/billing">
                    Billing
                  </Link>
                </li>
                <li>
                  <Link className="w-100 dropdown-item" to="/admin/usage-stats">
                    Usage Stats
                  </Link>
                </li>
              </ul>
            </div>
            </div>
            <div style={{ width: "100%" }}>
              <UsersDataTable toView={location?.state} />
              {/* <MyTable toView={location?.state}/> */}
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default UsersTable;
