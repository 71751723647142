import { Center, ChakraProvider, Divider, Stack, Text } from "@chakra-ui/react";
import React from "react";

const Subscriptions = () => {
  return (
    <ChakraProvider>
      <Stack
        className="subscription-container"
        border={"1px solid black"}
        rounded={"lg"}
        p={6}
      >
        <h1>Plan Details</h1>
        <Stack direction="row" justifyContent={"space-between"}>
          <Stack
            justifyContent={"space-between"}
            w="49%"
            spacing={8}
            direction="row"
            pr={10}
          >
            <Stack spacing={3}>
              <Text fontSize={"14px"} fontWeight={"medium"} mb='0'>Current Plan</Text>
              <h1>Executive</h1>
            </Stack>
            <Stack spacing={3}>
              <Text fontSize={"14px"} fontWeight={"medium"} mb='0'>Period</Text>
              <h1>Monthly</h1>
            </Stack>
          </Stack>
          <Stack borderRight={"1px solid black"}></Stack>
          <Stack px={10} w="49%">
            <Stack spacing={3}>
              <Text fontSize={"14px"} fontWeight={"medium"} mb='0'>Renewal amount</Text>
              <h1 className="renewal-amount">
                <span>$499.99</span> $0.00
              </h1>
            </Stack>
            <Text pt={3} fontSize={"14px"} fontWeight={"medium"} mb='0'>
              Included In the Executive Leadership Branding Subscription
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </ChakraProvider>
  );
};

export default Subscriptions;
