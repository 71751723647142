import {
  React,
  useState,
  useEffect,
  useRef,
  useContext,
  useImperativeHandle,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Avatar from "../../Images/man.svg";
import Plus from "../../Images/plus-circle.svg";
import search from "../../Images/search.svg";
import filter from "../../Images/filter_list.png";
import brook from "../../Images/brook.png";
import LoadingSpinner from "../../Assests/loaderspinner.gif";
import "./AllPersonas.css";
import {
  ChakraProvider,
  Modal as ChakraModal,
  Button as ChakraButton,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  ModalHeader,
  Stack,
  Switch,
  extendTheme,
} from "@chakra-ui/react";
import { BaseURL } from "../../enviornment";
import Unknown from "../../Images/Unknown.jpg";
import Swal from "sweetalert2";
import { Button, Modal, Spinner } from "react-bootstrap";
import modalUpload from "../../Images/modal-upload.png";
import {
  UpdatePersonaProfilePicService,
  deleteUserService,
  getAllUsersService,
  getSingleUserService,
  makeAdminService,
  searchPersonasService,
} from "../../Service/service";
import PersonaMomentContext from "../../context/MomentsContext";
import { forwardRef } from "react";
import { RotatingLines } from "react-loader-spinner";
const AllPersonas = (
  { updatePersona, showUser, handleSwitchMyPersonas },
  ref
) => {
  const theme = extendTheme({
    // ...
    // Add or customize color schemes
    colors: {
      customSwitchColor: {
        500: "#ff5733", // Define your desired color here
      },
    },
    // ...
  });
  const location = useLocation();
  const path = location?.pathname;
  const { id } = useParams(); // Fetch the "id" from the URL path
  const { updateActiveUserData } = useContext(PersonaMomentContext);
  const {
    isOpen: isEditModalOpen,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = localStorage.getItem("auth_token");
  const parsedToken = JSON.parse(token);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showUsers, setShowUsers] = useState(true);
  const [isRerender, setIsRerender] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [searchPersonaText, setSearchPersonaText] = useState();
  const [userId, setUserId] = useState(parsedToken?.data?._id);
  const [TargetUserId, setTargetUserId] = useState();
  const [TargetUserRole, setTargetUserRole] = useState();
  const [previewImage, setPreviewImage] = useState(null);
  const [activePersona, setActivePersona] = useState();
  const [isPersona, setIspersona] = useState(false);
  const [personaList, setPersonaList] = useState([]);
  const [tempPersonaList, settempPersonaList] = useState([]);

  const [usersList, setUsersList] = useState([]);
  const [yourPersonasList, setYourPersonasList] = useState([]);
  const [personaId, setPersonaId] = useState();
  const [accessPersonas, setAccessPersonas] = useState("All");
  const [personaIndex, setPersonaIndex] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [switchValue, setSwitchValue] = useState(false);

  const fileInputRef = useRef(null);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const openModal2 = () => {
    setShowModal2(true);
  };

  const closeModal2 = () => {
    setShowModal2(false);
  };
  const showMoment = async (personaData) => {
    // localStorage.setItem("switchValue", accessPersonas);

    localStorage.setItem("active_persona", personaData._id);
    const dataString = JSON.stringify(personaData);
    localStorage.setItem("active_persona_data", dataString);
    try {
      const res = await getSingleUserService(personaData?.userId);
      if (res.success) {
        const activeUserData = JSON.stringify(res.data);
        localStorage.setItem("active_user", activeUserData);
        if (personaData?.moments !== 0) {
          navigate("/mind");
        } else {
          navigate("/moments");
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: AllPersonas.js:107 ~ showMoment ~ error:", error);
    }
  };
  const isFileFormatValid = (file) => {
    const validFormats = [".png", ".jpg", ".jpeg", ".svg"];
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    return validFormats.includes(fileExtension.toLowerCase());
  };
  const handleInputChange = (e) => {
    if (e.target.name === "profilePic") {
      const selectedFile = e.target.files[0];

      if (selectedFile && !isFileFormatValid(selectedFile)) {
        Swal.fire({
          icon: "error",
          title: "Invalid file format!",
          text: "Please upload png, jpg,svg and jpeg files only",
          customClass: {
            popup: "swal-custom-class", // Add the custom class to the modal
          },
        });
        return;
      } else {
        setProfilePic(selectedFile);
        if (selectedFile) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviewImage(reader.result);
          };
          reader.readAsDataURL(selectedFile);
        } else {
          setPreviewImage(null);
        }
      }
    }
  };

  const handleCustomUploadClick = () => {
    fileInputRef.current.click();
  };

  const updateProfileImageHandler = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("personaId", activePersona._id);
      formData.append("image", profilePic);
      const res = await UpdatePersonaProfilePicService(formData);
      if (res.success) {
        setPreviewImage(null);
        setProfilePic(null);
        setIsLoading(false);
        onCloseEditModal();
        setIsRerender(!isRerender);
        Swal.fire({
          icon: "success",
          title: res.message,
        });
      } else {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: res.message,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };
  const fetchAdmins = async () => {
    try {
      setIsLoading(true);
      const response = await getAllUsersService("Admin");

      if (response.success) {
        // Process the received data
        setIspersona(true);
        setUsersList(response.data);
        localStorage.setItem("user_list", JSON.stringify(response.data));
        navigate("/admins");
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    fetchAdmins,
  }));
  // getting all personas
  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    const parsedToken = JSON.parse(token);
    localStorage.setItem("active_user", JSON.stringify(parsedToken?.data));
    const storedSwitchValue = localStorage.getItem("switchValue");
    const initialSwitchValue =
      storedSwitchValue === null
        ? switchValue
        : storedSwitchValue == "All"
        ? false
        : true; // Convert to boolean
    // console.log(
    //   "🚀 ~ file: AllPersonas.js:238 ~ useEffect ~ initialSwitchValue:",
    //   initialSwitchValue
    // );

    const fetchData = async () => {
      const userID = id || userId;
      try {
        setIsLoading(true);
        const response = await fetch(`${BaseURL}/api/personas/getAllPersonas`, {
          method: "POST",
          body: JSON.stringify({
            access:
              parsedToken?.data?.role === "User" ||
              parsedToken?.data?.role === "Collaborator"
                ? "Self"
                : initialSwitchValue == true
                ? "Self"
                : "All",
            userId: userID,
          }),
          headers: {
            auth_token: parsedToken?.auth_token,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          // Process the received data
          setYourPersonasList(data.data.yourPersonas);
          if (data.message == "My Personas") {
            const concatenatedData = [
              ...data.data.yourPersonas,
              ...data.data.allowedPersonas,
            ];
            setPersonaList(concatenatedData);
            settempPersonaList(concatenatedData);
            localStorage.setItem(
              "persona_list",
              JSON.stringify(data.data.yourPersonas)
            );
            localStorage.setItem(
              "my_persona_list",
              JSON.stringify(data.data.yourPersonas)
            );
            if (concatenatedData?.length > 0) {
              setIspersona(true);
            } else {
              setIspersona(false);
            }
          } else {
            setPersonaList(data.data);
            settempPersonaList(data.data);
            localStorage.setItem("persona_list", JSON.stringify(data.data));
            if (data.data.length > 0) {
              setIspersona(true);
            } else {
              setIspersona(false);
            }
          }

          setIsLoading(false);
        } else {
          setIsLoading(false);
          const errorData = await response.json();
          console.log(errorData.errors);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [updatePersona, isRerender, showUsers, location]);
  useEffect(() => {
    const storedSwitchValue = localStorage.getItem("switchValue");
    if (storedSwitchValue !== null) {
      setSwitchValue(storedSwitchValue == "Self" ? true : false);
    }
  }, [location]);

  // delete persona handler
  async function deletePersona() {
    const token = localStorage.getItem("auth_token");
    const parsedToken = await JSON.parse(token);
    try {
      var requestOptions = {
        method: "DELETE",
        headers: parsedToken,
        redirect: "follow",
      };
      setIsLoading(true);
      const response = await fetch(
        `${BaseURL}/api/personas/deletePersona/${personaId}`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        setIsLoading(false);
        closeModal();
        const updatedTempObjects = [...tempPersonaList];
        const updatedObjects = [...personaList];
        updatedObjects.splice(personaIndex, 1);
        updatedTempObjects.splice(personaIndex, 1);
        setPersonaList(updatedObjects);
        settempPersonaList(updatedTempObjects);
        Swal.fire({
          icon: "success",
          title: result.message,
        });
      } else {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: result.message,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  // search persona handler
  const searchPersonaHandler = (text) => {
    const splitText = text && text.split(" ");
    let username;
    const updatedArray = personaList.filter((persona) => {
      if (splitText.length >= 1) {
        username = `${persona.firstName} ${persona.lastName}`;
      } else {
        username = persona.firstName;
      }
      // const username = `${persona.firstName} ${persona.lastName}`;
      if (
        username.toLowerCase().includes(text.toLowerCase())
        // ||
        // persona.firstName.toLowerCase().includes(text.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    settempPersonaList(updatedArray);
  };
  const deleteUserHandler = async () => {
    try {
      setIsLoading(true);
      const res = await deleteUserService(TargetUserId);
      if (res.success) {
        setIsLoading(false);
        closeModal2();
        Swal.fire({
          icon: "success",
          title: res.message,
        });
        setIsRerender(!isRerender);
      } else {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: res.message,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const deleteAdminHandler = async () => {
    try {
      setIsLoading(true);
      const res = await deleteUserService(TargetUserId);
      if (res.success) {
        fetchAdmins();
        setIsLoading(false);
        closeModal2();
        Swal.fire({
          icon: "success",
          title: res.message,
        });
        // setIsRerender(!isRerender);
      } else {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: res.message,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const viewMyPersonas = () => {
    setUserId(parsedToken?.data?._id);
    setAccessPersonas("Self");
    setIsRerender(!isRerender);
  };
  const viewAllPersonas = () => {
    setUserId(parsedToken?.data?._id);
    setAccessPersonas("All");
    setIsRerender(!isRerender);
  };
  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            {/* <img width={80} height={80} src={LoadingSpinner} alt="" /> */}
            <RotatingLines
              strokeColor="black"
              strokeWidth="4"
              animationDuration="0.85"
              width="50"
              visible={true}
            />
          </div>
        </div>
      )}
      {/* {isPersona ? ( */}
      <div className="">
        <div className="pageHeader">
          <div className="d-flex align-items-center">
            {(parsedToken?.data?.role === "SuperAdmin" ||
              parsedToken?.data?.role === "Admin") && (
              <div className="d-flex align-items-center me-4">
                <Switch
                  isChecked={switchValue}
                  onChange={(e) => {
                    setSearchPersonaText("");
                    setSwitchValue(e.target.checked);
                    handleSwitchMyPersonas(e.target.checked);
                    localStorage.setItem(
                      "switchValue",
                      e.target.checked == true ? "Self" : "All"
                    );

                    if (e.target.checked == true) {
                      viewMyPersonas();
                    } else {
                      viewAllPersonas();
                    }
                  }}
                  className="me-2"
                  colorScheme="green"
                  size="md"
                />
                <h1
                  style={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "0",
                    color: "#111",
                  }}
                >
                  My Personas
                </h1>
              </div>
            )}

            <div className="align-items-center d-flex gap-2 search-input px-2">
              <span>
                <img src={search} className="search-img" alt="" />
              </span>
              <input
                type="text"
                className="border-0"
                placeholder="Search by name"
                aria-label="search"
                value={searchPersonaText}
                onChange={(e) => {
                  setSearchPersonaText(e.target.value);
                  if (e.target.value.length > 0) {
                    searchPersonaHandler(e.target.value);
                  } else {
                    settempPersonaList(personaList);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="emissary-grid">
          {tempPersonaList.map((item, index) => (
            <div className="emissary-card">
              <div class="emissary-cardHeader">
                <div
                  onClick={() => {
                    setActivePersona(item);
                    onOpen();
                  }}
                  class="avatar"
                >
                  <img
                    src={
                      item.image === null
                        ? Unknown
                        : `${BaseURL}/api/uploads/${
                            item._id
                          }?random=${Math.random()}`
                    }
                    className="brendan cursor-pointer"
                    alt="person"
                  />
                </div>
                <div class="dropdown">
                  <button
                    class="togglebtn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {" "}
                    <i class="icon-more-vertical"></i>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li className="dropdown-item disabled">
                      Ask {item.firstName}
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        showMoment(item);
                      }}
                    >
                      Create a moment
                    </li>
                    {/* <li
                        className="dropdown-item cursor-pointer"
                        onClick={() => {
                          setActivePersona(item);
                          onOpenEditModal();
                        }}
                      >
                        Edit Profile
                      </li> */}
                    {/* {(parsedToken?.data?.role !== "Collaborator" &&
                        parsedToken?.data?.role !== "User") ||
                      yourPersonasList?.includes(item) ? (
                        <li
                          className="dropdown-item cursor-pointer"
                          onClick={() => {
                            setPersonaId(item._id);
                            setPersonaIndex(index);
                            openModal();
                          }}
                        >
                          Delete
                        </li>
                      ) : null} */}
                  </ul>
                </div>
              </div>
              <div class="emissary-cardBody">
                <h2 onClick={() => showMoment(item)}>
                  {`${item.firstName} ${item.lastName}`}
                  {/* {item.firstName} {item.lastName} */}
                </h2>
                <p>
                  {item.moments} {item.moments == 1 ? "moment" : "moments"}
                </p>
                {/* <p className="user-email">{item.epClientEmail}</p> */}
              </div>
              {/* <div class="emissary-cardFooter">
                      <div class="uploadDate">{item?.personaDate}</div>
                      <img src={brook} className="female" alt="person" />
                    </div> */}
            </div>
          ))}

          <ChakraProvider>
            {/* <ChakraButton onClick={onOpen}>Open Modal</ChakraButton> */}
            {/* preview image modal */}
            <ChakraModal isCentered isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader textAlign={"center"}></ModalHeader>
                <ModalCloseButton />
                <ModalBody py={6}>
                  <Stack py={4} justifyContent={"center"} alignItems={"center"}>
                    <img
                      src={
                        activePersona && activePersona.image === null
                          ? Unknown
                          : `${BaseURL}/api/uploads/${
                              activePersona && activePersona._id
                            }`
                      }
                    />
                  </Stack>
                </ModalBody>
              </ModalContent>
            </ChakraModal>
            {/* edit image modal */}
            <ChakraModal
              isCentered
              isOpen={isEditModalOpen}
              onClose={() => {
                setPreviewImage(null);
                setProfilePic(null);
                onCloseEditModal();
              }}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader textAlign={"center"}>
                  Update Profile Image
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {/* <Lorem count={2} /> */}
                  <Stack py={4} justifyContent={"center"} alignItems={"center"}>
                    <div>
                      {/* Hidden file input */}
                      <input
                        type="file"
                        name="profilePic"
                        accept=".png,.jpg,.jpeg,.svg"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleInputChange}
                      />

                      {/* Custom upload image */}
                      {previewImage ? (
                        <img
                          onClick={handleCustomUploadClick}
                          src={previewImage}
                          alt="preview"
                          style={{
                            width: "140px",
                            height: "140px",
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <img
                          onClick={handleCustomUploadClick}
                          src={modalUpload}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </Stack>
                </ModalBody>

                <ModalFooter justifyContent={"center"}>
                  <ChakraButton
                    bg="black"
                    color="white"
                    _hover={{}}
                    mr={3}
                    onClick={updateProfileImageHandler}
                  >
                    Update
                  </ChakraButton>
                </ModalFooter>
              </ModalContent>
            </ChakraModal>
          </ChakraProvider>
        </div>
      </div>
      {/* ) : (
        <div className="all-personna-div text-center py-4">
          <div>
            <h2>Create Persona</h2>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center persona-text-container py-4">
            <p className="py-3">
              Start by giving Emissary data to work with, like personas.After,
              you can sort search,and filter to find what you need and hide what
              you don't.
            </p>
            <div>
              <img src={Avatar} alt="Person" />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className=" create-btn-container px-2 py-2">
              <div
                role="button"
                className="align-items-center d-flex gap-2"
                data-bs-toggle="modal"
                data-bs-target="#newPersonaModal"
              >
                <span>
                  <img src={Plus} className="plus-img" alt="Plus Logo"></img>
                </span>
                Create Persona
              </div>
            </div>
          </div>
        </div>
      )} */}

      {/* confirm delete popup for persona */}
      <div>
        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Header style={{ border: "none" }} closeButton>
            {/* <Modal.Title>Are you sure you want to delete?</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <h6 style={{ paddingLeft: "20px", fontSize: "1.3rem" }}>
              Are you sure you want to delete?
            </h6>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button variant="secondary" onClick={closeModal}>
              No
            </Button>
            <Button variant="danger" onClick={deletePersona}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* confirm delete popup for user */}
      {/* confirm delete popup */}
      <div>
        <Modal show={showModal2} onHide={closeModal2} centered>
          <Modal.Header style={{ border: "none" }} closeButton>
            {/* <Modal.Title>Are you sure you want to delete?</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <h6 style={{ paddingLeft: "20px", fontSize: "1.3rem" }}>
              Are you sure you want to delete?
            </h6>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button variant="secondary" onClick={closeModal2}>
              No
            </Button>
            <Button
              variant="danger"
              onClick={
                TargetUserRole == "User"
                  ? deleteUserHandler
                  : deleteAdminHandler
              }
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default forwardRef(AllPersonas);
