import { FaBold } from "react-icons/fa";
import { Position, MarkerType } from "reactflow";
import { sendGraphData } from "../analyzemoment/AnalizeMoment";
// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    positionAbsolute: intersectionNodePosition,
  } = intersectionNode;
  const targetPosition = targetNode.positionAbsolute;

  const w = intersectionNodeWidth / 2;
  const h = intersectionNodeHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
  const n = { ...node.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}
let graph = {
  edges: [
  
  ],
  nodes: [
   
  ],
};
// utils.js
function consolidateEdgesWithSameToAndFrom(edges) {
  const consolidatedEdges = {};

  for (const edge of edges) {
    const key = `${edge.from}-${edge.to}`;
    if (!consolidatedEdges[key]) {
      consolidatedEdges[key] = {
        from: edge.from,
        to: edge.to,
        labels: [edge.label],
      };
    } else {
      consolidatedEdges[key].labels.push(edge.label);
    }
  }

  return Object.values(consolidatedEdges).map((consolidatedEdge) => {
    return {
      from: consolidatedEdge.from,
      to: consolidatedEdge.to,
      label: consolidatedEdge.labels.join(', '), // Comma-separated labels
    };
  });
}
let consolidatedEdges = []
let selectedKeyword = '' 
export const processData = (data,keyword) => {
  // Process the data in some way
  const consolidatedEdges = consolidateEdgesWithSameToAndFrom(data.edges);
  graph.nodes = data.nodes;
  graph.edges = consolidatedEdges
  selectedKeyword = keyword
  // return data;
};




let highestOccurrence = 0;
let numberWithHighestOccurrence = null;

// if (graph) {
  const extractedFromValues = [];

  for (const edge of graph.edges) {
    extractedFromValues.push(edge.from);
  }

  // console.log(extractedFromValues);

  // Create an object to store the frequency of each number
  const frequencyMap = {};

  // Count the frequency of each number
  for (const num of extractedFromValues) {
    if (frequencyMap[num]) {
      frequencyMap[num]++;
    } else {
      frequencyMap[num] = 1;
    }
  }

  // Find the number with the highest occurrence

  for (const num in frequencyMap) {
    if (frequencyMap[num] > highestOccurrence) {
      highestOccurrence = frequencyMap[num];
      numberWithHighestOccurrence = num;
    }
  }
// }



export function createNodesAndEdges() {
  //   if (!Object.keys(graph).length) {
  //    console.error("Graph data is not available yet.");
  //    return { nodes: [], edges: [] };
  //  }
  const nodes = [];
  const edges = [];
  const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };



  // Populate nodes array from graph.nodes
  // if (graph) {
    for (const node of graph.nodes) {
      const degrees = node.id * (360 / graph.nodes.length);
      const radians = degrees * (Math.PI / 180);
      const x = 250 * Math.cos(radians) + center.x;
      const y = 250 * Math.sin(radians) + center.y;
      // selectedKeyword == node.label ? center :
      nodes.push({
        id: `${node.id}`,
        data: { label: node.label },
        position: { x, y },
        //   draggable: false,
        style: {
          color: selectedKeyword == node.label ? " white" : "black",
          fontSize: '12px',
          fontWeight: 500,
          minWidth: "fit-content",
          paddingInline: "5px",
          backgroundColor:
          selectedKeyword == node.label ? " green" : "#B2D7EF",
          border: "2px solid black",
        },
      });
    }

    // Populate edges array from graph.edges
    for (const edge of graph.edges) {
      edges.push({
        // id: `edge-${edge.from}-${edge.to}`,
        target: `${edge.to}`,
        source: `${edge.from}`,
        label: edge.label, // Set the label property for the edge
        // type: 'floating',
        animated: "true",
        markerEnd: {
          type: MarkerType.ArrowClosed, // Make sure you have defined MarkerType somewhere
        },
        // type:'custom',
        // defaultMarkerColor:'red',
        style: {
          stroke:
            edge.sentiment === "Positive"
              ? "green"
              : edge.sentiment === "Negative"
              ? "red"
              : "blue",
          strokeDasharray: "6,5",
          width: 2,
          label: {
            color: "blue", // Change this to your desired label color
            fontSize: 20, // Change this to your desired font size
          },
        },
        // type:'straight'
      });
    }
  // }
  return { nodes, edges };
}
